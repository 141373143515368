import * as THREE from 'three'
import visualize from '../../common/visualize';
import { DCShape } from '../DCShape';
import { environmentVariable } from '../../variables';
import { addShapeToSceneNew } from '../../utils/customUtils';
import { DCEntityData } from '../cuttingEntities/DCEntityCommon/DCEntityData';

export class DCAssembly extends THREE.Group {
    constructor(params) {
        super();

        this.assemblyTree = params.assemblyTree
        this.oc = params.openCascade
        this.threeViewer = params.threeViewer
        this.shapeLayer = params.shapeLayer
        this.customType = "DCAssembly";
        this.name = params.originalName;
        this.layers.set(params.DCShapeLayer ? params.DCShapeLayer : 7); // Entity Layer
        this.shapeName = params.shapeName
        this.sequenceName = "main_" + params.shapeName
        this.entityData = DCEntityData()
        this.generate()
        if (this.threeViewer["DCShapesPickingObjects"]){
            this.threeViewer["DCShapesPickingObjects"].push(this)
        }else{
            this.threeViewer["DCShapesPickingObjects"] = [this]
        }
    }
    setParentFace(inFace) {
        this.parentFace = inFace
    }
    getParentFace() {
        return this.parentFace
    }
    generate() {
        let scope = this

        scope.stepName = this.assemblyTree.name
        // For the case of a single shape
        if(!this.assemblyTree.children) {
            
            let shape = this.assemblyTree.shape
            let color = this.assemblyTree.color
            let facesColor = this.assemblyTree.facesColor
            let mat = this.assemblyTree.matrix ? this.assemblyTree.matrix : new THREE.Matrix4()
            addShapeToSceneNew(this.oc, shape, color, facesColor, scope, this.assemblyTree.name, mat,`${0}_${scope.shapeName}`, scope.shapeLayer);

            return
        }

        // For the case of an assembly
        for (let index = 0; index < this.assemblyTree.children.length; index++) {
            const child = this.assemblyTree.children[index];
            
            if (child.shape) {
                let mat = child.matrix ? child.matrix : new THREE.Matrix4()
                addShapeToSceneNew(this.oc, child.shape, child.color, child.facesColor, scope, child.name, mat, `${index}_${scope.shapeName}`, scope.shapeLayer);
            }
            if (child.children) {
                let params = {
                    assemblyTree: child,
                    openCascade: this.oc,
                    threeViewer: this.threeViewer,
                    shapeLayer: this.shapeLayer,
                    shapeName: `${index}_${scope.shapeName}`,
                    originalName : child.name,
                }
                let subAsm = new DCAssembly(params);
                subAsm.applyMatrix4(params.assemblyTree.matrix)
                scope.add(subAsm)
            }
        }
    }
    getGlobalPosition(){
        return this.localToWorld(this.position)
    }
    getDCShapePoint(position){
        let DCShape = this.getDCShapeParent()
        if (DCShape){
            let localP = DCShape.worldToLocal(position)
            return localP
        }
    }
    getDCShapeParent(){
        let parent = this
        while (1) {
            if (parent.customType == "DCShape" && parent.name !== "libraryShape") return parent
            else parent = parent.parent
        }
    }
    getCuttingData() {
        /**
         * This function will return cutting data for cutting face
         * @returns {Array<THREE.Vector3>} points
         * We are adding z value as 0.1 for proper cutting
         */
        let scope = this;
        let p;

        let scopeP = scope.parent
        let dcShapeParent = scope.getDCShapeParent()
        dcShapeParent.attach(scope)
        p = scope.position.clone()
        
        // for cut make it upper 0.1
        let face = scope.getParentFace();
        let faceNormal = face.normal;
        const displacement = faceNormal.clone().multiplyScalar(0.1);
        p.add(displacement);

        let rotation = scope.quaternion.clone()
        scopeP.attach(scope)

        let data = {
            point : p,
            rotation : rotation,
            dcShape : dcShapeParent,
        };
        
        return data
    }
}

