import {
    PerspectiveCamera,
    Scene,
    WebGLRenderer,
    Color,
} from 'three';
import * as THREE from 'three';
import $ from 'jquery';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { ArcballControls } from 'three/examples/jsm/controls/ArcballControls.js'
import { getAllVertices, degreeToRadian, generateGeometryBorder, getMidPoint, getWorldPointData, goToView, logMessage, moveObjectToCenter, radianToDegree, removeObjWithChildren, roundDigit, calculateCircleCenter, checkTwoShapeIntersect, alterShowOriginalShape, addShapeToSceneNew, getDimensionOfObject, blobToLInk, updateSliderZoom } from './utils/customUtils';
import { stepData } from '.';
import { attachTransformControl, checkInputTextModalIsOn, createLinearPattern, deleteObj, detachTransControlAndClearXYZValueInHtml, getCutExtrudeDataOfScene, getFaceNameFromReferenceFaceName, getShapeNameFromReferenceShapeName, progressBar, removeDrawingObjects, removeObjWithAllSameName, replaceSTepData, resetSketchMode, visibleDCShapeAsPreviousVisible, getShapeStepFileBlob, getShapeJsonFileBlob } from './utils/commonFunction';
import { environmentVariable } from './variables.js';
import exportStep, { cylinderBody, rectangleBody2, makeCut, ellipseBody, allEntitiesLoop, makeCustomProfile, checkTwoEntityIntersection, findVolumeFromShape, findSurfaceAreaFromShape } from './openCascadeShapes.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { CustomSketch, Dimension, Pattern, makeLine, measureDistance } from './utils/customSketch';
import { addLights } from './threeCustom/threeViewer/lightSetup';
import { checkIntersectionHoverOnScene, checkIsCursorOnStartPoint, handleClickFaceSelection, handleHoverFaceSelection, handleHoverWhileSketching, intersectToReferencePoint, intersectToScene, selectAndAttachTransformControl, selectAndRemoveLine, updateFaceColorOnClick, updateFaceColorOnHover } from './utils/interactionUtils';
import { DCCircle, DCEllipse, DCPolyline, DCRect } from './threeCustom/cuttingEntities/DCEntity';
import { DCFace } from './threeCustom/DCFace';
import { DCSketcher, createSketchPlane } from './threeCustom/sketch/DCSketcher';
import { DCCircularPattern, DCRectangularPattern } from './threeCustom/cuttingEntities/DCEntityGroups';
import { compactFilteredPowerEntryModule, customShapeTest, keyHole, shapeMounting, shapePlus } from './threeCustom/library/cutouts/cutoutShapeJson';
import { CutoutShape } from './threeCustom/library/cutouts/cutoutShape';
import { getEntity2DDrive } from './utils/Entity2DDrive';
import { ClipboardActions } from './threeCustom/clipBoardActions/clipboardActions';
import { EntityNotes } from './threeCustom/notes/entityNotes';
import { PartTree } from './threeCustom/partTree/partTree';
import { objToStl } from './threeCustom/stlExporter/stlExporter';
import axios from 'axios';
import { getSubmitFormData } from './utils/submitData';
import { DCShape } from './threeCustom/DCShape';
import { applyMatrix4TShape, getShapeMatrix, loadSTEPorIGESInScene } from './library';
import addToCartEvents, { CreateSalesOrderForPDF } from './views/addToCartHandlers';
import Cookies from 'universal-cookie';
import { closeHtmlModal, customAlert, openHtmlModal } from './utils/utils';
import { readFileText } from './utils/fileReader/readFile';
import { initJsHtml } from './views/setupInitHtml';
import { CreateEmbossText } from './threeCustom/embossing/textEmboss/emboss.js';
import { replaceOpenCascadeShape } from './utils/ocUtils.js';
import { addRegistrationComponent } from './pages/components/registration.jsx';
import { HandleImgUpload, swapAllGraphicsToScene } from './views/imgUpload.js';
import { checkAndShowExlusionError } from './views/exclusion.js';
import { HandleObjSequence } from './views/handleState.js';
import { HtmlGuideForProceedToProcurement } from './views/guide/HtmlGuideForProceedToProcurement.js';
import { FeedBackInit } from './views/feedback.js';
const cookies = new Cookies();

window.THREE = THREE
let container, camera, scene
let controls
let openCascade
let pickingObjects = []
let pickingObjectsCircle = []
let pickingObjectsForMove = []

window.pickingObjectsForMove = pickingObjectsForMove
window.pickingObjectsCircle = pickingObjectsCircle
let entityModes = ["circle", "line", "rectangle", "ellipse", "measure", "dimension"]
let mode = {
    sketchMode: false,
}
let lastEntityMode = null
let isSketching = false


let labelRenderer

let currentObject
let transformControl

let currentHoveredLine = []
let threeViewer = {
    mouse: new THREE.Vector2(),
    clipboardActions: null,
    filename: "",
    mouseClickStart: null,
    mouseClickEnd: null,
    loaders : {
        incrementalLoader : null
    }
}
threeViewer.clipboardActions = new ClipboardActions(threeViewer)
threeViewer.entityNotes = new EntityNotes(threeViewer)
threeViewer.sketcher = new DCSketcher(threeViewer)
threeViewer.customSketch = new CustomSketch(threeViewer)
threeViewer.state = {
    objSequence : new HandleObjSequence(threeViewer)
}

new FeedBackInit()

window.threeViewer = threeViewer
let currentDimensionData = {
    point1Circle: undefined,
    point2Circle: undefined,
    dimensionLine: undefined,
}
let dimension
var renderer = new WebGLRenderer({ antialias: true });

const material = new THREE.MeshBasicMaterial({
    color: "red",
    side: THREE.DoubleSide,
    transparent: true, opacity: 0.5,
    polygonOffset: true,
    polygonOffsetFactor: -0.2,
    polygonOffsetUnits: -100,
    depthWrite: false
});

const setupThreeJSViewport = (oc) => {
    openCascade = oc
    window.openCascade = openCascade
    scene = new Scene();
    const viewport = document.getElementById("viewport");
    const viewportRect = viewport.getBoundingClientRect();
    window.addEventListener('resize', () => onWindowResize(viewportRect.width, viewportRect.height), false);

    const width = viewportRect.width,
        height = viewportRect.height,
        left = width * -0.5,
        right = width * 0.5,
        top = height * 0.5,
        bottom = height * -0.5,
        near = -500,
        far = 1000;

    camera = new THREE.OrthographicCamera(left, right, top, bottom, near, far);

    camera.layers.enableAll();
    scene.add(camera)
    scene.background = new THREE.Color("#ebebeb");
    renderer.setSize(viewportRect.width, viewportRect.height);
    viewport.appendChild(renderer.domElement);

    camera.position.set(0, 50, 500);
    camera.zoom = 3
    const controls = new ArcballControls(camera, renderer.domElement, scene)
    controls.target.set(0, 50, 0)
    controls.setGizmosVisible(false)
    controls.setCamera(camera)
    controls.dampingFactor = 25
    controls.wMax = 11
    controls.addEventListener('change', function () {
        renderer.render(scene, camera);
        controls.cursorZoom = true
    });
    controls.update()
    transformControl = new TransformControls(camera, renderer.domElement);
    transformControl.space = "local"
    transformControl.showZ = false;
    transformControl.addEventListener('dragging-changed', function (event) {
        controls.enabled = !event.value;
        currentObject = transformControl.object
        updateXYZValueInHtml()
        dimension.updateDimensions()
        // threeViewer.sketcher.entityMode = null
    });
    scene.add(transformControl);
    addLights(scene, camera)

    labelRenderInScene(viewportRect.width, viewportRect.height)
    function animate() {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
        labelRenderer.render(scene, camera);
    }

    container = renderer.domElement
    animate();
    threeViewer["scene"] = scene
    threeViewer["camera"] = camera
    threeViewer["transformControl"] = transformControl
    threeViewer["orbitControl"] = controls
    threeViewer["renderer"] = renderer
    dimension = new Dimension(threeViewer)

    // Update camera perpendicular to face on click
    container.addEventListener('mousedown', logMouseDown);
    container.addEventListener('mouseup', logMouseUp);
    container.addEventListener('click', onClickThreeScene);
    // Update color on hover
    container.addEventListener('mousemove', onPointerMove);
    function handleWheelScroll(event) {
        // Delta property indicates the distance of the wheel movement
        const delta = Math.sign(event.deltaY);
        updateSliderZoom(threeViewer.camera)
    }

    // Add the wheel scroll event listener to the document
    container.addEventListener('wheel', handleWheelScroll);
    const axesHelper = new THREE.AxesHelper(90);
    scene.add(axesHelper);

    let cameraViewIconEles = document.getElementsByClassName("cameraViewDiv")
    Array.from(cameraViewIconEles).forEach((ele) => {
        ele.addEventListener("click", () => {
            goToView(ele.id, scene, camera, controls)
        })
    })
    window.scene = scene
    scene["dimension"] = []
    window.scene = scene

    initJsHtml()

    // add all methods in threeViewer which are connected with html 
    threeViewer.loaders.incrementalLoader = new progressBar()

    return threeViewer;
}

export function onWindowResize(clientWidth, clientHeight) {
    const viewport = document.getElementById("viewport");
    const viewportRect = viewport.getBoundingClientRect();
    renderer.setSize(viewportRect.width, viewportRect.height);
    clientWidth = viewportRect.width
    clientHeight = viewportRect.height
    camera.left = -clientWidth / 2;
    camera.right = clientWidth / 2;
    camera.top = clientHeight / 2;
    camera.bottom = -clientHeight / 2;
    camera.updateProjectionMatrix();
    labelRenderer.setSize(viewportRect.width, viewportRect.height);
}
let displayVertices = (position, obj) => {
    const geometry = new THREE.SphereGeometry(3, 32, 16);
    const materials = new THREE.MeshBasicMaterial({ color: "green" });
    const sphere = new THREE.Mesh(geometry, materials);
    sphere.position.copy(position)
    obj.add(sphere);
    return sphere
}
let getVerticesFirstFour = (obj, forUpdate = false) => {
    if (!obj) {
        logMessage("obj is undefined in getVerticesFirstFor function")
        return
    }
    let v = []
    let verticesIndex = [0, 1, 3, 2]
    let verticesIndexColor = ["red", "green", "blue", "yellow"]
    verticesIndex.map((index) => {
        const vertex = new THREE.Vector3();
        const geometry = obj.geometry;
        const positionAttribute = geometry.getAttribute('position');
        vertex.fromBufferAttribute(positionAttribute, index);
        if (!forUpdate) obj.localToWorld(vertex);
        v.push(vertex)
        // for debugging purpose ==> call this function
        let displayVertices = () => {
            const geometrys = new THREE.SphereGeometry(3, 32, 16);
            const materials = new THREE.MeshBasicMaterial({ color: verticesIndexColor[verticesIndex.indexOf(index)] });
            const spheres = new THREE.Mesh(geometrys, materials);
            scene.add(spheres);
        }
    })
    return v
}
let getVerticesOfObjectsOfCircle = (obj, isForUpdate = false) => {
    if (!obj) {
        logMessage("obj is undefined in getVerticesOfObjectsOfCircle function")
        return
    }
    let gp = obj.geometry.attributes.position;
    let wPos = [];
    let circleCount = 0
    for (let i = 0; i < gp.count; i++) {
        let inI = [0, 1, 10, 19, 28]
        if (!inI.includes(i)) continue
        let vertexPosition = new THREE.Vector3().fromBufferAttribute(gp, i); // set p from `position`
        if (!isForUpdate)
            obj.localToWorld(vertexPosition); // p has wordl coords
        wPos.push(vertexPosition);
        function makeCircle(position) {
            let circle = makeSmallCircle(3);
            circle.name = "smallCircle_" + circleCount
            circle.position.copy(obj.worldToLocal(position))
            obj.add(circle);
        }
        if (isForUpdate) {
            let editCircle = obj.getObjectByName("smallCircle_" + circleCount)
            editCircle.position.copy(vertexPosition)

        } else {
            makeCircle(vertexPosition)
        }
        circleCount += 1
    }
    return gp
}
let getVerticesOfObjectsOfEllipse = (obj, isForUpdate = false) => {
    if (!obj) {
        logMessage("obj is undefined in getVerticesOfObjectsOfEllipse function")
        return
    }
    let gp = obj.geometry.attributes.position;
    let wPos = [];
    let circleCount = 0
    let count = 16
    let inI = [0, count, count * 2, count * 3]
    function makeCircle(position) {
        let circle = makeSmallCircle(3);
        circle.name = "smallCircle_" + circleCount
        circle.position.copy(obj.worldToLocal(position))
        // circle.visible = true
        obj.add(circle);
    }
    makeCircle(obj.position.clone())
    for (let i = 0; i < gp.count; i++) {
        if (!inI.includes(i)) continue
        let vertexPosition = new THREE.Vector3().fromBufferAttribute(gp, i); // set p from `position`
        if (!isForUpdate)
            obj.localToWorld(vertexPosition); // p has wordl coords
        wPos.push(vertexPosition);

        if (isForUpdate) {
            let editCircle = obj.getObjectByName("smallCircle_" + circleCount)
            editCircle.position.copy(vertexPosition)

        } else {
            makeCircle(vertexPosition)
        }
        circleCount += 1
    }
    return gp
}

function onPointerMove(event) {
    threeViewer.mouse.x = ((event.clientX - (container.getBoundingClientRect().left)) / container.clientWidth) * 2 - 1;
    threeViewer.mouse.y = - ((event.clientY - (container.getBoundingClientRect().top)) / container.clientHeight) * 2 + 1;
    let isIntersected
    checkIntersectiOnMove()
}

function logMouseDown() {
    threeViewer.mouseClickStart = Date.now()
}
function logMouseUp() {
    threeViewer.mouseClickEnd = Date.now()
    let totalTime = threeViewer.mouseClickEnd - threeViewer.mouseClickStart
}
function onClickThreeScene(event) {
    let totalTime = threeViewer.mouseClickEnd - threeViewer.mouseClickStart

    if (totalTime > 200) return
    threeViewer.mouse.x = ((event.clientX - (container.getBoundingClientRect().left)) / container.clientWidth) * 2 - 1;
    threeViewer.mouse.y = - ((event.clientY - (container.getBoundingClientRect().top)) / container.clientHeight) * 2 + 1;

    // Check if paste mode is on
    if (threeViewer.sketcher.mode === "paste") {
        // attachTransformControl(threeViewer, threeViewer.clipboardActions.currentObject)
        threeViewer.clipboardActions.copy(threeViewer.clipboardActions.currentObject)
        threeViewer.clipboardActions.paste()
        return
    }

    // Check if move mode is on : Enter if move mode is on or (entityMode is on with mouse right button is clicked)
    if (threeViewer.sketcher.entityMode === "move" || (threeViewer.sketcher.entityMode && (event.which === 3 || event.button === 2))) {
        selectAndAttachTransformControl(threeViewer);
        updateXYZValueInHtml()
        return
    }

    // check if global move button is
    if (threeViewer.sketcher.entityMode === "globalMeasure") {
        checkIntersectionClickOnScene(event)
        return
    }

    if (!mode.sketchMode) return
    checkIntersectiOnClick(event)
}

function makeSketchModeOff() {
    resetSketchMode(threeViewer)
    threeViewer.orbitControl.enableRotate = true
    swapAllGraphicsToScene(threeViewer)
    removeObjWithChildren(threeViewer.scene.getObjectByName("sketchPlane"))
    pickingObjects.remove(threeViewer.scene.getObjectByName("sketchPlane"))
    activateGeometry("event", null)

    mode.sketchMode = false
    isSketching = false
    dimension.clearDimension()
    scene.dimension.length = 0

    document.getElementById("sketchModeDiv").classList.add("displayNone")
    document.getElementById("move").classList.remove("selected")
    document.getElementById("frontView").click()
    document.getElementById("rightHeader").classList.add("displayNone")
    document.getElementById("rightHeaderHome").classList.remove("displayNone")
    document.getElementById("sketchBtn").classList.remove('selected');
    document.getElementById("operation").classList.add("displayNone")
    document.getElementById("File-tab").click()

}

function makeSketchModeOn() {
    mode.sketchMode = true
    document.getElementById("sketchBtn").classList.add('selected');
}

function activateGeometry(e, entityName) {
    threeViewer.customSketch.removeMeasuredLines()
    if (!entityName) threeViewer.sketcher.currentEntity = null
    threeViewer.sketcher.entityMode = entityName
    let geometricEles = document.getElementsByClassName("geometric")
    Array.from(geometricEles).forEach((ele) => {
        ele.classList.remove('selected');
    });
    if (entityName) document.getElementById(entityName).classList.add('selected');
    if (entityName != "move") {
        try {
            detachTransControlAndClearXYZValueInHtml(threeViewer)
        } catch (error) {
            logMessage(error, "at activateGeometry", transformControl)
        }
    }
}
function checkIntersectionClickOnScene() {
    let intersects = intersectToScene(threeViewer)
    let point

    point = intersects[0].point.clone()
    // if (mesh) point = mesh.position.clone()

    switch (threeViewer.sketcher.entityMode) {
        case "globalMeasure":
            handleMeasure(point, scene)
            break;

        default:
            break;
    }
}
function checkIntersectiOnClick() {



    // Initiate sketchMode
    if (!isSketching) {
        let bool = handleClickFaceSelection(threeViewer)
        if (bool) {
            isSketching = true
            document.getElementById("home-tab").click()
            document.getElementById("sketchModeDiv").classList.remove("displayNone")

        }
        return
    }

    // Check if trim mode is on
    if (threeViewer.sketcher.entityMode === "trim") {
        selectAndRemoveLine(threeViewer);
        return
    }

    let currentSelectedObj = threeViewer.transformControl.object
    if (currentSelectedObj && threeViewer.transformControl.showX == true && threeViewer.transformControl.showY == true) return
    // if (isSketching) {
    var raycaster = new THREE.Raycaster();
    raycaster.layers.disableAll()
    raycaster.layers.enable(2) // Sketch Layer
    raycaster.layers.enable(3) // Entity Layer
    raycaster.setFromCamera(threeViewer.mouse, threeViewer.camera);
    let intersectiOnClick = raycaster.intersectObjects(threeViewer.scene.children, true);

    // Check intersection, if not return
    if (intersectiOnClick.length == 0) return

    let intersectedData = intersectiOnClick[0]
    // }

    let point = intersectedData.point
    let mesh = intersectedData.object
    switch (threeViewer.sketcher.entityMode) {
        case "rectangle":
            if (mesh.name == "sketchPlane") {
                let localPoint = mesh.worldToLocal(point.clone())
                if (!threeViewer.sketcher.currentEntity) { // Need to improve this
                    threeViewer.sketcher.currentEntity = new DCRect({ sketchType: threeViewer.sketcher.sketchType })
                    threeViewer.sketcher.currentEntity.setParentFae(mesh.parent.parent)
                    mesh.parent.add(threeViewer.sketcher.currentEntity)
                    threeViewer.sketcher.currentEntity.addPoint(localPoint)
                } else {
                    threeViewer.sketcher.currentEntity.update()
                    threeViewer.sketcher.currentEntity.finalize()
                    updateXYZValueWithoutTransformControl()
                    threeViewer.state.objSequence.addEntity(threeViewer.sketcher.currentEntity)
                    threeViewer.sketcher.currentEntity = null
                }
            }
            break;
        case "circle":
            if (mesh.name == "sketchPlane") {
                let localPoint = mesh.worldToLocal(point.clone())
                if (!threeViewer.sketcher.currentEntity) {
                    threeViewer.sketcher.currentEntity = new DCCircle({ sketchType: threeViewer.sketcher.sketchType })
                    threeViewer.sketcher.currentEntity.setParentFae(mesh.parent.parent)
                    mesh.parent.add(threeViewer.sketcher.currentEntity)
                    threeViewer.sketcher.currentEntity.addPoint(localPoint)
                } else {
                    threeViewer.sketcher.currentEntity.update()
                    threeViewer.sketcher.currentEntity.finalize()
                    updateXYZValueWithoutTransformControl()
                    threeViewer.state.objSequence.addEntity(threeViewer.sketcher.currentEntity)
                    threeViewer.sketcher.currentEntity = null
                }
            }
            break;

        case "line":
            if (mesh.name == "sketchPlane") {
                let localPoint = mesh.worldToLocal(point.clone())
                if (!threeViewer.sketcher.currentEntity) {
                    threeViewer.sketcher.currentEntity = new DCPolyline({ sketchType: threeViewer.sketcher.sketchType })
                    threeViewer.sketcher.currentEntity.setParentFae(mesh.parent.parent)
                    mesh.parent.add(threeViewer.sketcher.currentEntity)
                    threeViewer.sketcher.currentEntity.addPoint(localPoint)
                } else {
                    let currEntity = threeViewer.sketcher.currentEntity
                    let startPoint = currEntity.getStartPoint()
                    if (currEntity.points.length > 2 && localPoint.distanceTo(startPoint.position) < 1) {
                        currEntity.isClosed = true
                    } else {
                        threeViewer.sketcher.currentEntity.addPoint(localPoint)
                    }
                    currEntity.update()

                    if (currEntity.isClosed) {
                        currEntity.finalize()
                        updateXYZValueWithoutTransformControl()

                        makeCurrentEntityNullWithState();
                    }
                }
            }
            break;
        case "measure":

            if (mesh.name == "sketchPlane") {
                // let localPoint = mesh.worldToLocal(point.clone())

                // let referenceIntersects = intersectToReferencePoint(threeViewer)
                // if (referenceIntersects){
                //    let mesh = referenceIntersects[0].object
                //    if (mesh) localPoint = mesh.position.clone()
                // }
                point = handleMeasure(point, threeViewer.sketcher.getCurrentFace());

            }
            break;

        case "dimension":
            if (mesh.name == "sketchPlane") {
                let referenceMesh, localPoint = new THREE.Vector3();

                let referenceIntersects = intersectToReferencePoint(threeViewer)
                if (referenceIntersects) {
                    referenceMesh = referenceIntersects[0].object
                    if (referenceMesh) {
                        if (referenceMesh.name === "perimeterBorder") localPoint = referenceIntersects[0].point.clone()
                        else {
                            localPoint = referenceMesh.position.clone()
                            referenceMesh.getWorldPosition(localPoint);
                        }
                    }
                }
                else return
                let currentFace = threeViewer.sketcher.getCurrentFace()
                // if not dimensionObject then create it in currentFace
                let dimensionObject = currentFace.getObjectByName("dimensionObject")
                if (!dimensionObject) {
                    dimensionObject = new THREE.Object3D()
                    dimensionObject.name = "dimensionObject"
                    threeViewer.sketcher.getCurrentFace().add(dimensionObject)
                }

                if (!threeViewer.sketcher.currentEntity) {
                    threeViewer.sketcher.currentEntity = measureDistance(localPoint, localPoint, dimensionObject)
                    threeViewer.sketcher.currentDimensionData["point1Circle"] = referenceMesh
                } else {
                    let line = threeViewer.scene.getObjectByName("refMeasureLine")
                    let startPoint = line.getStartPoint()

                    threeViewer.sketcher.currentEntity = measureDistance(localPoint, startPoint, dimensionObject)
                    threeViewer.sketcher.currentEntity.name = "dimension"

                    threeViewer.sketcher.currentDimensionData["point2Circle"] = referenceMesh
                    threeViewer.sketcher.currentDimensionData["dimensionLine"] = threeViewer.sketcher.currentEntity
                    threeViewer.scene.dimension.push(threeViewer.sketcher.currentDimensionData)
                    threeViewer.sketcher.currentDimensionData = {}
                    makeCurrentEntityNullWithState();
                }
            }
            break;

        case "ellipse":
            if (mesh.name == "sketchPlane") {
                let localPoint = mesh.worldToLocal(point.clone())
                if (!threeViewer.sketcher.currentEntity) {
                    threeViewer.sketcher.currentEntity = new DCEllipse({ sketchType: threeViewer.sketcher.sketchType })
                    threeViewer.sketcher.currentEntity.setParentFae(mesh.parent.parent)
                    mesh.parent.add(threeViewer.sketcher.currentEntity)
                    threeViewer.sketcher.currentEntity.addPoint(localPoint)
                } else {
                    threeViewer.sketcher.currentEntity.update()
                    threeViewer.sketcher.currentEntity.finalize()
                    updateXYZValueWithoutTransformControl()
                    makeCurrentEntityNullWithState();
                }
            }
            break;
        default:
            break;
    }

    function updateXYZValueWithoutTransformControl() {
        attachTransformControl(threeViewer, threeViewer.sketcher.currentEntity)
        transformControl.showX = false
        transformControl.showY = false
        updateXYZValueInHtml()
    }
}

function makeCurrentEntityNullWithState() {
    threeViewer.state.objSequence.addEntity(threeViewer.sketcher.currentEntity);
    threeViewer.sketcher.currentEntity = null;
}

function handleMeasure(point, parent) {
    let referenceMesh;
    let referenceIntersects = intersectToReferencePoint(threeViewer);
    if (referenceIntersects) {
        referenceMesh = referenceIntersects[0].object;
        if (referenceMesh) {
            if (referenceMesh.name === "perimeterBorder") point = referenceIntersects[0].point.clone();
            else {
                point = referenceMesh.position.clone();
                referenceMesh.getWorldPosition(point);
            }
        }
    }
    if (!threeViewer.sketcher.currentEntity) {
        threeViewer.sketcher.currentEntity = measureDistance(point.clone(), point.clone(), parent);
    } else {
        let line = threeViewer.scene.getObjectByName("refMeasureLine");
        let startPoint = line.getStartPoint();
        threeViewer.sketcher.currentEntity = measureDistance(startPoint, point.clone(), parent);
        threeViewer.sketcher.currentEntity.name = "measureLine";
        makeCurrentEntityNullWithState();
    }
    return point;
}

function checkIntersectiOnMove() {

    if (threeViewer.sketcher.entityMode === "globalMeasure") {
        checkIntersectionHoverOnScene(event)
        return
    }

    // If sketchMode is off, return
    if (!mode.sketchMode) {
        return
    }

    // If sketchMode is on, and face is not selected
    if (!isSketching) {
        handleHoverFaceSelection(threeViewer)
        return
    }
    // If sketchMode is on, and face is selected
    if (isSketching) {
        handleHoverWhileSketching(threeViewer)
    }
}

function addBtnEvents() {
    HeaderBoxEvents()
    sketchEntityEvents()
    sketchToolEvents()
    // SIDEBAR BOX EVENTS
    leftSidebarEvents()
    propertiesBoxEvents()
    notesBoxEvents()
    // SIDEBAR BOX EVENTS
    rightHeaderBoxEvents()
    operationsBoxEvent()
    patternEvent()
    designEvents()
    printerEstimate()
    addToCartEvents({ threeViewer, oc: openCascade })
    fileInputEvents()
    function fileInputEvents() {
        let okEle = document.getElementById("alertOk")
        okEle.addEventListener("click", openRegistrationForm)
        imageInputs()
        function imageInputs(params) {
            let imgEle = document.getElementById("inputImg")
            if (imgEle) {
                imgEle.addEventListener("change", handleImgInput)
            }
            async function handleImgInput(event) {
                let file = event.srcElement.files[0]
                if (!file) return
                // threeViewer.file = file
                let handleImgUpload = new HandleImgUpload({ threeViewer, file,sequenceFaceName : threeViewer.sketcher.currentFace.sequenceFaceName,isGraphicImg : true, sketchType: threeViewer.sketcher.sketchType });
                let imgObjs = await handleImgUpload.init()
                threeViewer.sketcher.currentFace.threeFace.add(imgObjs);
                threeViewer.state.objSequence.addEntity(imgObjs);
            }
        }

        function openRegistrationForm() {
            if (threeViewer.user.name === "Test 106") {
                openHtmlModal("registrationModel")
            } else {
                createEstimate()
            }
        }
        function createEstimate() {
            closeHtmlModal("customAlertModalWithCancel")
            let pdfData = new CreateSalesOrderForPDF({ file: threeViewer.file })
        }
    }


    function printerEstimate() {
        // let estimatePrinterEle = document.getElementById("estimatePrinter")
        // estimatePrinterEle.addEventListener("click", openEstimateFormModal)
        // let estimateEle = document.getElementById("estimateForm")
        // estimateEle.addEventListener("submit", estimate)

        function openEstimateFormModal(e) {
            let obj = threeViewer.scene.getObjectByName("shape")
            if (!obj) {
                customAlert("Please load the model before estimating.")
                return
            }
            openHtmlModal("estimateInputModal")
        }
        function estimate(e) {
            e.preventDefault()
            let estimateFormValues = getSubmitFormData("estimateForm")
            let obj = threeViewer.scene.getObjectByName("shape")
            let stlFile = objToStl(obj)
            const data = new FormData();
            data.append('inputFile', stlFile);
            data.append('layerHeight', estimateFormValues.layerHeight);
            data.append('inFill', estimateFormValues.inFill);
            document.getElementById("operation").classList.remove("displayNone")

            axios.post(`${environmentVariable.REACT_APP_DatabaseServer}printer/estimate`, data)
                .then((res) => {
                    document.getElementById("operation").classList.add("displayNone")
                    let response = res.data
                    if (response.errorAtReadGcode) {
                        customAlert(response.errorAtReadGcode)

                        return
                    }
                    if (response.errorAtExecuteExe) {
                        customAlert(response.errorAtExecuteExe.replace("stderrEXE: ", ""))
                        return
                    }
                    openHtmlModal("slicerEstimationModal")
                    let estimatorEle = document.getElementById("printerEstimateHtml")
                    estimatorEle.innerHTML = ""
                    estimatorEle.innerHTML += `<pre>Estimated time for first layer : ${response.printerEstimate.estimatedPrintingTimeFirstLayer}</pre>`;
                    estimatorEle.innerHTML += `<pre>Estimated time      : ${response.printerEstimate.estimatedPrintingTime} </pre></pre>`;
                    estimatorEle.innerHTML += `<pre>Filament used (CM)  : ${response.printerEstimate.filamentUsedCM} </pre>`;
                    estimatorEle.innerHTML += `<pre>Filament used (MM)  : ${response.printerEstimate.filamentUsedMM}</pre>`;
                    estimatorEle.innerHTML += `<pre>Total filament used : ${response.printerEstimate.totalFilamentUsedG} </pre>`;
                    estimatorEle.innerHTML += `<pre>Total filament cost : $${response.printerEstimate.totalFilamentCost}</pre>`;
                });
        }
    }
    function HeaderBoxEvents() {
        let fileTabEle = document.getElementById("File-tab")
        let homeTabEle = document.getElementById("home-tab")
        fileTabEle.addEventListener("click", (event) => {
            fileTabEle.classList.remove("menuDisable")
            homeTabEle.classList.add("menuDisable")
            document.getElementById('block1Box').style.display = "block";
            document.getElementById('block2Box').style.display = "none";
            alterShowOriginalShape(true, threeViewer);

        })
        homeTabEle.addEventListener("click", (event) => {
            fileTabEle.classList.add("menuDisable")
            homeTabEle.classList.remove("menuDisable")
            document.getElementById('block2Box').style.display = "block";
            document.getElementById('block1Box').style.display = "none";
            alterShowOriginalShape(false, threeViewer);

        })

    }
    function sketchToolEvents() {
        exportEvents()

        document.getElementById("sketchBtn").addEventListener("click", (event) => {
            resetSketchMode(threeViewer)
            if (mode.sketchMode) {
                makeSketchModeOff()
            } else {
                makeSketchModeOn()
            }
        })

        document.getElementById("measure").addEventListener("click", (e) => {

            if (!isSketching) {
                customAlert("Please enable sketch mode")
                return
            }
            if (threeViewer.sketcher.entityMode === "measure") { // deactivate
                resetSketchMode(threeViewer)
            }
            else {
                activateGeometry(e, "measure");
            }

        })
        document.getElementById("globalMeasure").addEventListener("click", (e) => {

            if (threeViewer.sketcher.entityMode === "globalMeasure") { // deactivate
                resetSketchMode(threeViewer)
            }
            else {
                activateGeometry(e, "globalMeasure");
            }

        })
        document.getElementById("move").addEventListener("click", (e) => {
            if (threeViewer.sketcher.entityMode === "move") {
                resetSketchMode(threeViewer)
                detachTransControlAndClearXYZValueInHtml(threeViewer, "at unClick move")
            }
            else {
                activateGeometry(e, "move");
            }
        })
        document.getElementById("trim").addEventListener("click", (e) => {
            if (!isSketching) {
                customAlert("Please enable sketch mode")
                return
            }
            let currentObject = threeViewer.transformControl.object
            if (!currentObject) {
                customAlert("Must select one object to Trim.")

                return
            }
            if (threeViewer.sketcher.entityMode === "trim") { // deactivate
                resetSketchMode(threeViewer)
            }
            else {
                activateGeometry(e, "trim");
            }

            let faceEntitiesArray = threeViewer.sketcher.getCurrentFace().getEntities()
            let normal = threeViewer.sketcher.getCurrentFace().normal
            window.tempNormal = normal
            let center = threeViewer.sketcher.getCurrentFace().threeFace.position.clone()
            window.tempCenter = center.clone()
            window.tempCenter.z += 0.1
            let entitiesLoop = []
            let twoOrMoreIntersect = false
            faceEntitiesArray.forEach(element => {
                let isIntersect = checkTwoShapeIntersect(currentObject, element)
                if (isIntersect) {
                    let entity2dDrive = getEntity2DDrive(openCascade, element, normal)
                    entitiesLoop.push(entity2dDrive)
                    if (element.id != currentObject.id) {
                        twoOrMoreIntersect = true
                        removeObjWithChildren(element)
                    }
                }
            });
            if (twoOrMoreIntersect) {
                transformControl.detach()
                removeObjWithChildren(currentObject)
            } else {
                customAlert("No one intersects with the selected entity.")
                resetSketchMode(threeViewer)
                return
            }

            let currentFace = threeViewer.sketcher.getCurrentFace()
            let lineMesh = allEntitiesLoop(openCascade, entitiesLoop.flat())
            let trimShape = new THREE.Object3D()
            for (let index = 0; index < lineMesh.length; index++) {
                let mesh = lineMesh[index].mesh
                mesh.forEach(element => {
                    element.name = "trimLine"
                    trimShape.add(element)
                    element.layers.set(5);
                });
            }
            trimShape.layers.set(5);
            trimShape.name = "trimShape"
            trimShape["ocData"] = entitiesLoop
            // threeViewer.sketcher["temp"] = [trimShape]
            currentFace.threeFace.add(trimShape)
            // threeViewer.sketcher.entityMode = "trim"
        })
        document.getElementById("dimension").addEventListener("click", (e) => {
            if (!isSketching) {
                customAlert("Please enable sketch mode")
                return
            }
            if (threeViewer.sketcher.entityMode === "dimension") { // deactivate 
                resetSketchMode(threeViewer)
                removeObjWithChildren(scene.getObjectByName("customCirc"))
            }
            else {
                activateGeometry(e, "dimension");
            }
        })

        function patternFormSubmit(e, patternType) {
            e.preventDefault()
            createPattern()

            function createPattern() {
                let patternObj = new THREE.Object3D()
                let currentObj = threeViewer.transformControl.object
                let parentFace = threeViewer.sketcher.currentFace

                switch (patternType) {

                    case "linearPattern":
                        let linearAngle = degreeToRadian(parseInt($("#linearAngle").val()))
                        let linearSpacing = parseInt($("#linearSpacing").val())
                        let linearQuantity = parseInt($("#linearQuantity").val())
                        let linearPattern = new DCRectangularPattern(currentObj, linearQuantity, linearSpacing, linearAngle, 1, 0, threeViewer.sketcher.sketchType)
                        linearPattern.generatePattern()
                        parentFace.threeFace.add(linearPattern)
                        parentFace.removeEntity(currentObj)
                        break;

                    case "rectangularPattern":
                        let rectAngle = degreeToRadian(parseInt($("#rectAngle").val()))
                        let rectXSpace = parseInt($("#rectXSpace").val())
                        let rectYSpace = parseInt($("#rectYSpace").val())
                        let rectXQuantity = parseInt($("#rectXQuantity").val())
                        let rectYQuantity = parseInt($("#rectYQuantity").val())
                        let pattern = new DCRectangularPattern(currentObj, rectXQuantity, rectXSpace, rectAngle, rectYQuantity, rectYSpace)
                        pattern.generatePattern()
                        parentFace.threeFace.add(pattern)
                        parentFace.removeEntity(currentObj)
                        break;

                    case "circularPattern":
                        let circularRadius = parseInt($("#circularRadius").val())
                        let circularRing = parseInt($("#circularRing").val())
                        let circularRingQuantity = parseInt($("#circularRingQuantity").val())
                        let circularPattern = new DCCircularPattern(currentObj, circularRadius, circularRingQuantity, circularRing, { sketchType: threeViewer.sketcher.sketchType })
                        circularPattern.generatePattern()
                        parentFace.threeFace.add(circularPattern)
                        parentFace.removeEntity(currentObj)
                        break;
                    default:
                        break;
                }
                scene.add(patternObj)
                patternObj.userData.draggable = true
                pickingObjectsForMove.push(patternObj)

                activateGeometry(e, "pattern");
            }
        }
        document.getElementById("linearForm").addEventListener("submit", (e) => patternFormSubmit(e, "linearPattern"))
        document.getElementById("rectangularForm").addEventListener("submit", (e) => patternFormSubmit(e, "rectangularPattern"))
        document.getElementById("circularForm").addEventListener("submit", (e) => patternFormSubmit(e, "circularPattern"))

        document.getElementById("pattern").addEventListener("click", (e) => {
            if (!threeViewer.transformControl.object) {
                customAlert("Must select one object to pattern.")
                return
            }
            if (threeViewer.sketcher.entityMode === "pattern") { // deactivate
                resetSketchMode(threeViewer)
            }
            else {
                function openModel() {
                    var myModal = new bootstrap.Modal(document.getElementById("patternModal"), {});
                    myModal.show();
                }
                openModel()
            }
        })
        async function exportEvents() {

            document.getElementById("export").addEventListener("click", (e) => {
                openHtmlModal("exportOptions")
            })

            document.getElementById("exportStep").addEventListener("click", async (e) => {
                // uploadReferenceShape()
                let stepBlob = await getShapeStepFileBlob(threeViewer, "main_referenceShape", "step")
                let link = blobToLInk(stepBlob, "step-configurator.step")
                link.click();
                document.body.removeChild(link);
            })

            document.getElementById("exportIges").addEventListener("click", (e) => {
                let stepBlob = getShapeStepFileBlob(threeViewer, undefined, "iges")
                let link = blobToLInk(stepBlob, "step-configurator.iges")
                link.click();
                document.body.removeChild(link);
            })
            document.getElementById("exportJson").addEventListener("click", async (e) => {
                let stepJson = await getShapeJsonFileBlob(threeViewer)
                let link = blobToLInk(stepJson, "step-configurator.json")
                link.click();
                document.body.removeChild(link);
            })
        }
    }

    function sketchEntityEvents() {
        document.getElementById("circle").addEventListener("click", (e) => {
            if (!isSketching) {
                customAlert("Please enable sketch mode")
                return
            }
            if (threeViewer.sketcher.entityMode === "circle") { // deactivate circle
                resetSketchMode(threeViewer)
            }
            else {

                activateGeometry(e, "circle");
            }
        })

        document.getElementById("line").addEventListener("click", (e) => {
            if (!isSketching) {
                customAlert("Please enable sketch mode")
                return
            }
            if (threeViewer.sketcher.entityMode === "line") { // deactivate 
                resetSketchMode(threeViewer)
                removeObjWithChildren(scene.getObjectByName("customCirc"))
            }
            else {
                activateGeometry(e, "line");
            }

        })

        document.getElementById("rectangle").addEventListener("click", (e) => {
            if (!isSketching) {
                customAlert("Please enable sketch mode")
                return
            }
            if (threeViewer.sketcher.entityMode === "rectangle") { // deactivate 
                resetSketchMode(threeViewer)
            }
            else {
                activateGeometry(e, "rectangle");
            }
        })
        document.getElementById("ellipse").addEventListener("click", (e) => {
            if (!isSketching) {
                customAlert("Please enable sketch mode")
                return
            }
            if (threeViewer.sketcher.entityMode === "ellipse") { // deactivate
                resetSketchMode(threeViewer)
            }
            else {
                activateGeometry(e, "ellipse");
            }
        })
        document.getElementById("textEntity").addEventListener("click", (e) => {
            openHtmlModal("textInputModal")
        })
        document.getElementById("textInputModalForm").addEventListener("submit",async  (e) => {
            e.preventDefault()
            let data = getSubmitFormData("textInputModalForm")
            let embossText = data.embossText
            let embossTextFontSize = data.embossTextFontSize
            let fontTTFName = data.fontTTFName
            let embossWordObj = new CreateEmbossText(
                {
                    text: embossText,
                    inViewer: threeViewer,
                    fontSize: embossTextFontSize,
                    fontTTFName,
                    sketchType: threeViewer.sketcher.sketchType,
                    sequenceFaceName : threeViewer.sketcher.currentFace.sequenceFaceName
                }
            )
            await embossWordObj.create()
            embossWordObj.sequenceFaceName = threeViewer.sketcher.getCurrentFace().sequenceFaceName
            let currentFace = threeViewer.sketcher.getCurrentFace()
            currentFace.threeFace.add(embossWordObj)
            threeViewer.state.objSequence.addEntity(embossWordObj);
        })


        let ele = document.getElementById("cutOutLibrary")
        ele.addEventListener("change", cutoutSketch)
        async function cutoutSketch() {
            if (!isSketching) {
                customAlert("Please enable sketch mode")
                return
            }
            let val = document.getElementById("cutOutLibrary").value
            let customShape

            if (val == "shapePlus") {
                let customJsonData = shapePlus
                customShape = new CutoutShape({threeViewer, customJsonData, openCascade,name : val})
                customShape.init()
                customShape.attachTransformControlToObj()
            }
            if (val == "shapeMounting") {
                let customJsonData = shapeMounting
                customShape = new CutoutShape({threeViewer, customJsonData, openCascade,name : val})
                customShape.init()
                customShape.attachTransformControlToObj()
            }
            if (val == "keyHole") {
                let customJsonData = keyHole
                customShape = new CutoutShape({threeViewer, customJsonData, openCascade,name : val})
                customShape.init()
                customShape.attachTransformControlToObj()
            }
            if (val == "compactFilteredPowerEntryModule") {
                let customJsonData = compactFilteredPowerEntryModule
                customShape = new CutoutShape({threeViewer, customJsonData, openCascade,name : val})
                customShape.init()
                customShape.attachTransformControlToObj()

            }
            if (val == "customSTPShape") {
                let cutOutLibraryEle = document.getElementById("cutOutLibrary")
                let selectedOption = cutOutLibraryEle.options[cutOutLibraryEle.selectedIndex];
                let fileName = selectedOption.getAttribute("nm")

                await createStepShape({fileName});
            }

            // Reset the selection in html
            document.getElementById("cutOutLibrary").selectedIndex = 0;
        }
    }

    // Sidebar events
    function leftSidebarEvents() {
        sketchTypeEvents()

        function sketchTypeEvents() {
            let cutoutEle = document.getElementById("cutout")
            let graphicEle = document.getElementById("graphic")
            let maskingEle = document.getElementById("masking")
            let referenceEle = document.getElementById("reference")
            let exclusionEle = document.getElementById("exclusion")

            let sketchTypeEles = [cutoutEle, graphicEle, maskingEle, referenceEle, exclusionEle]
            sketchTypeEles.forEach((ele) => {
                ele.addEventListener('change', (e) => sketchTypeChangeEvent(e))
            })


            function sketchTypeChangeEvent(e) {
                disableImgInput()

                let selectedValue = document.querySelector('input[name="sketchType"]:checked').value;
                threeViewer.sketcher.sketchType = selectedValue

                enableImgInput()

            }
            function enableImgInput() {
                if (threeViewer.sketcher.sketchType === "graphic") {

                    document.getElementById("inputImgDiv").classList.remove("menuDisable")
                }
            }
            function disableImgInput() {
                document.getElementById("inputImgDiv").classList.add("menuDisable")
            }
        }
    }
    function propertiesBoxEvents() {

        // UP DOWN RIGHT LEFT EVENTS
        let translateInX = (value) => {
            translateTransformControl({
                translateX: value
            })
            updateXYZValueInHtml()
        }
        let translateInY = (value) => {
            translateTransformControl({
                translateY: value
            })
            updateXYZValueInHtml()
        }

        document.getElementById("positiveX").addEventListener("click", (e) => {
            let xVal = document.getElementById("translateX").value
            if (xVal) xVal = Number(xVal)
            else {
                console.warn('no val', xVal)
                return
            }
            translateInX(xVal)
            dimension.updateDimensions()

        })
        document.getElementById("negativeX").addEventListener("click", (e) => {
            let xVal = document.getElementById("translateX").value
            if (xVal) xVal = Number(xVal)
            else {
                console.warn('no val', xVal)
                return
            }
            translateInX(xVal * -1)
            dimension.updateDimensions()

        })
        document.getElementById("positiveY").addEventListener("click", (e) => {
            let xVal = document.getElementById("translateY").value
            if (xVal) xVal = Number(xVal)
            else {
                console.warn('no val', xVal)
                return
            }
            translateInY(xVal)
            dimension.updateDimensions()

        })
        document.getElementById("negativeY").addEventListener("click", (e) => {
            let xVal = document.getElementById("translateY").value
            if (xVal) xVal = Number(xVal)
            else {
                console.warn('no val', xVal)
                return
            }
            translateInY(-xVal)
            dimension.updateDimensions()

        })
        // UP DOWN RIGHT LEFT EVENTS END

        //  ORIGIN POINT EVENT START
        document.getElementById("originX").addEventListener("focusout", (e) => {
            let val = e.target.value
            if (val) val = Number(val)
            else {
                console.warn('no val', val)
                return
            }
            threeViewer.transformControl.object.position.x = val
            dimension.updateDimensions()
        })
        document.getElementById("originX").addEventListener("change", (e) => {
            let val = e.target.value
            if (val) val = Number(val)
            else {
                console.warn('no val', val)
                return
            }
            threeViewer.transformControl.object.position.x = val
            dimension.updateDimensions()
        })
        document.getElementById("originY").addEventListener("focusout", (e) => {
            let val = e.target.value
            if (val) val = Number(val)
            else {
                console.warn('no val', val)
                return
            }
            threeViewer.transformControl.object.position.y = val
            dimension.updateDimensions()
        })
        document.getElementById("originY").addEventListener("change", (e) => {
            let val = e.target.value
            if (val) val = Number(val)
            else {
                console.warn('no val', val)
                return
            }
            threeViewer.transformControl.object.position.y = val
            dimension.updateDimensions()
        })

        //  ORIGIN POINT EVENT END

        // ENGRAVING EVENT START
        document.getElementById("objEngrave").addEventListener("change", objEngraveChange)
        function objEngraveChange(e) {
            let ele = document.getElementById("objEngrave")
            if (ele.checked) {
                document.getElementById("objDepthMainDiv").style = "display:flex"
            } else {
                document.getElementById("objDepthMainDiv").style = "display:none"
            }
            changeEngraveInEntity(ele.checked)
            function changeEngraveInEntity(inEngraveVal) {
                let currentObject = threeViewer.transformControl.object;
                if (["DCPattern", "cutOutLibrary"].includes(currentObject.name)) {
                    currentObject.children.forEach((entity) => {
                        entity.entityData.engrave = inEngraveVal;
                    });
                }
                currentObject.entityData.engrave = inEngraveVal;
            }
        }
        document.getElementById("objDepth").addEventListener("change", objDepthChange)
        function objDepthChange() {
            let ele = document.getElementById("objDepth")
            let val = Number(ele.value)
            if (val) {
                let currentObject = threeViewer.transformControl.object
                if (["DCPattern", "cutOutLibrary"].includes(currentObject.name)) {
                    currentObject.children.forEach((entity) => {
                        entity.entityData.extrudeDepth = val
                    })
                }
                currentObject.entityData.extrudeDepth = val
            }

        }

        // ENGRAVING EVENT END

        // PROPERTIES BOX EVENTS START 
        let rectHeightChange = (e) => {
            let val = e.target.value
            let selectedEntity = threeViewer.sketcher.getSelectedEntity()
            if (selectedEntity && selectedEntity.entityType === "Rect") {
                selectedEntity.updateHeight(val)
            }
            updateXYZValueInHtml()

            dimension.updateDimensions()
        }

        let rectWidthChange = (e) => {
            let val = e.target.value
            if (val) val = Number(val)
            else return
            let selectedEntity = threeViewer.sketcher.getSelectedEntity()
            if (selectedEntity && selectedEntity.entityType === "Rect") {
                selectedEntity.updateWidth(val)
            }
            updateXYZValueInHtml()

            dimension.updateDimensions()
        }

        document.getElementById("rectHeight").addEventListener("change", rectHeightChange)
        document.getElementById("rectWidth").addEventListener("change", rectWidthChange)

        let objRotationChangeY = (e) => {
            let val = e.target.value
            if (val) val = Number(val)
            else return
            let deg = (val / 180) * Math.PI
            threeViewer.transformControl.object.rotation.z = deg
            dimension.updateDimensions()
            setTimeout(() => {
                updateXYZValueInHtml()

            }, 500);
        }
        document.getElementById("objRotation").addEventListener("change", objRotationChangeY)

        let changeCircleDiameter = (e) => {
            let val = e.target.value
            if (val) val = Number(val)
            else return
            let selectedEntity = threeViewer.sketcher.getSelectedEntity()
            if (selectedEntity && selectedEntity.entityType === "Circle") {
                selectedEntity.updateDiameter(val)
            }
            // dimension.updateDimensions()
            updateXYZValueInHtml()
        }
        document.getElementById("circleDiameter").addEventListener("change", changeCircleDiameter)

        let changeEllipseRadius = (e, radiusAxis) => {
            let val = e.target.value
            if (val) val = Number(val)
            else return
            currentObject = threeViewer.transformControl.object
            if (radiusAxis === "x") {
                currentObject.updateXRadius(val)
            } else if (radiusAxis === "y") {
                currentObject.updateYRadius(val)
            }
            dimension.updateDimensions()
            updateXYZValueInHtml()
        }
        document.getElementById("ellipseRadiusX").addEventListener("change", (e) => changeEllipseRadius(e, "x"))
        document.getElementById("ellipseRadiusY").addEventListener("change", (e) => changeEllipseRadius(e, "y"))

        // PROPERTIES BOX EVENTS END
    }
    function notesBoxEvents() {
        document.getElementById("newNoteBtn").addEventListener("click", newNoteClickEvent)
        document.getElementById("noteForm").addEventListener("submit", noteFormSubmitEvent)
        document.getElementById("noteDeleteForm").addEventListener("submit", noteDeleteFormSubmitEvent)

        function newNoteClickEvent(e) {
            threeViewer.entityNotes.index = -1
            threeViewer.entityNotes.openNotesHtmlModal()
        }
        function noteFormSubmitEvent(e) {
            e.preventDefault()
            threeViewer.entityNotes.submitNoteForm()
        }
        function noteDeleteFormSubmitEvent(e) {
            e.preventDefault()
            threeViewer.entityNotes.deleteSelectedNote()
        }
    }

    function operationsBoxEvent() {

        // Events
        document.getElementById("cutObject").addEventListener("click", cutObjectEvent)
        document.getElementById("copyObject").addEventListener("click", copyObjectEvent)
        document.getElementById("pasteObject").addEventListener("click", pasteObjectEvent)
        let goPreviousObjs = document.getElementsByClassName("goPreviousObj")
        Array.from(goPreviousObjs).forEach((ele)=>{
            ele.addEventListener("click", goPreviousObjEvent)
        })

        document.getElementById("removeDimension").addEventListener("click", threeViewer.customSketch.removeDimension)

        function cutObjectEvent() {
            threeViewer.clipboardActions.cut(threeViewer.transformControl.object)
        }
        function copyObjectEvent() {
            threeViewer.clipboardActions.copy(threeViewer.transformControl.object)
        }
        function pasteObjectEvent() {
            threeViewer.clipboardActions.paste()
        }
        function goPreviousObjEvent() {
            threeViewer.state.objSequence.goPreviousState({threeViewer})
        }
    }

    function rightHeaderBoxEvents() {
        document.getElementById("logout").addEventListener("click", (e) => {
            logout();
        })
        document.getElementById("clearSketch").addEventListener("click", (e) => {
            clearSketch();
        })
        document.getElementById("cancelSketch").addEventListener("click", (e) => {
            clearSketch();
            visibleDCShapeAsPreviousVisible()
            let currentFaceReference = threeViewer.sketcher.currentFace
            let currentFaceShapeName = getFaceNameFromReferenceFaceName(currentFaceReference.sequenceFaceName)
            let currentFaceShape = threeViewer.scene.getObjectByProperty("sequenceFaceName", currentFaceShapeName)
            currentFaceShape.material.color = new THREE.Color(currentFaceShape.material.previousColor)
            makeSketchModeOff()
        })

        document.getElementById("done").addEventListener("click", checkAndCutShape)

        function checkAndCutShape(e){
            checkAndShowExlusionError(threeViewer)
        }

        document.getElementById("deleteObj").addEventListener("click", deleteObj)

        function clearSketch() {
            detachTransControlAndClearXYZValueInHtml(threeViewer);
            removeDrawingObjects(threeViewer.sketcher.currentFace, threeViewer);
            scene.dimension.length = 0;
        }
        function logout() {
            cookies.remove('user');
            window.location.href = environmentVariable.FRONT_END_URL + "login.html";
        }
    }

    function patternEvent() {

        // modal javascript
        $("#subPatternSelect").click(function () {
            var selectValue = $("#patternSelect option:selected").val();
            if (selectValue == 'Linear') {
                var myModal = new bootstrap.Modal(document.getElementById("patternSubModal1"), {});
                myModal.show();
            }
            else if (selectValue == 'Rectangular') {
                var myModal = new bootstrap.Modal(document.getElementById("patternSubModal2"), {});
                myModal.show();
            }
            else if (selectValue == 'Circular') {
                var myModal = new bootstrap.Modal(document.getElementById("patternSubModal3"), {});
                myModal.show();
            }
        });
    }
    function designEvents() {
        $("#modelColorPicker").on("change", modelColorChange);

        function modelColorChange(e) {
            let color = e.target.value;
            let models = ["shape", "referenceShape"]

            models.forEach((model) => {
                changeShapeObjColor(model, color)
            })
        }

        function changeShapeObjColor(model, color) {
            let shapeObj = threeViewer.scene.getObjectByName(model)

            shapeObj.children.forEach((object) => {
                object.commonColor = color
                object.children.forEach((o) => {
                    if (o instanceof THREE.Mesh) {
                        if (o.material)
                            o.material.color = new THREE.Color(color)
                    }
                })
            })

            environmentVariable.modelColor = color
        }
    }
}

export async function createStepShape(params) {
    let fileName = params.fileName
    let sequenceFaceName = params.sequenceFaceName
    let entityData = params.entityData

    // set threeFace
    let threeFace,DCFaceObj
    if (params.sequenceFaceName) {
        DCFaceObj = threeViewer.scene.getObjectByProperty('sequenceFaceName', sequenceFaceName)
        threeFace = DCFaceObj.threeFace
    } else {
        DCFaceObj = threeViewer.sketcher.currentFace
        threeFace = DCFaceObj.threeFace
        sequenceFaceName = threeViewer.sketcher.currentFace.sequenceFaceName
    }
    let libraryStepFile = await readFileText(environmentVariable.REACT_APP_DatabaseServer_Main + `assets/three/libraryShapes/${fileName}`, "libraryShape.step");
    if (!libraryStepFile) {
        customAlert("While fetching the shape, something went wrong, Please contact support.");
    } else {
        let res = await loadSTEPorIGESInScene(openCascade, libraryStepFile, threeFace, "libraryShape",)
        if (!res.proceed) {
            alert("something went wrong while loading shape.");
            return false;
        }
        res.threeJsShape.fileName = fileName
        res.threeJsShape.setParentFace(DCFaceObj);
        res.threeJsShape.entityType = "customSTPShape"

        res.threeJsShape.info = info
        // res.threeJsShape.entityData = entityData || res.threeJsShape.entityData

        attachTransformControl(threeViewer, res.threeJsShape);

        function info() {
            /**
             * This function will return information of entity
             * @returns {object} data
             */
            let scope = res.threeJsShape;

            let data = {

                fileName: fileName,

                entityType: this.entityType,

                sequenceFaceName: sequenceFaceName, // It is parentFace, will used for clone

                entityData: scope.entityData,

                position: scope.position.clone(),

            };
            return data
        }

        return res.threeJsShape;

    }
}

function removeAndAddBorder(obj) {
    removeObjWithAllSameName(obj, "perimeterBorder")
    let border = generateGeometryBorder(obj.geometry)
    obj.add(border)
    return obj
}

function removeAndAddBorderRectangle(obj) {
    getVerticesFirstFour(obj)

    removeSeparatedBorderAndCircle()
    makeSeparatedBorderAndCircle(obj, true, true)

    function removeSeparatedBorderAndCircle() {
        obj.children.forEach((o) => {
            if (o.name === "smallCircle") {
                pickingObjectsCircle.splice(pickingObjectsCircle.indexOf(o), 1)
            }
        })
        removeObjWithAllSameName(obj, "smallCircle")
        removeObjWithAllSameName(obj, "border")
    }
}

function addEventsListenersCustom() {

    document.addEventListener("stepFile-change", () => {
        pickingObjects.length = 0
        removeObjWithChildren(scene.getObjectByName("refPlane"))

        let shape = scene.getObjectByName("shape")
        if (shape) {
            pickingObjects.push(shape)
        }
    })
    window.addEventListener('keydown', function (e) {
        if (checkInputTextModalIsOn()) return
        // Check if the Ctrl key is pressed
        if (event.ctrlKey) {
            // Ctrl+C (copy)
            if (['c','C'].includes(event.key)) {
                let copyObjectEle = document.getElementById("copyObject")
                if (copyObjectEle){
                    copyObjectEle.click()
                }
            }
            // Ctrl+V (paste)
            else if (['v','V'].includes(event.key)) {
                let copyObjectEle = document.getElementById("pasteObject")
                if (copyObjectEle){
                    copyObjectEle.click()
                }
            }
            // Ctrl+Z (undo)
            else if (['x','X'].includes(event.key)) {
                let copyObjectEle = document.getElementById("cutObject")
                if (copyObjectEle){
                    copyObjectEle.click()
                }
            }
        }
        switch (e.keyCode) {
            case 81: // Q
                transformControl.setSpace(transformControl.space === 'local' ? 'world' : 'local');
                break;

            // case 16: // Shift
            //     transformControl.setTranslationSnap(100);
            //     transformControl.setRotationSnap(THREE.MathUtils.degToRad(15));
            //     transformControl.setScaleSnap(0.25);
            //     break;

            case 87: // W
                transformControl.showX = true;
                transformControl.showY = true;
                transformControl.showZ = false;
                transformControl.setMode('translate');
                break;

            case 69: // E
                transformControl.showX = false;
                transformControl.showY = false;
                transformControl.showZ = true;
                transformControl.setMode('rotate');
                break;

            case 82: // R
                transformControl.showX = true;
                transformControl.showY = true;
                transformControl.showZ = false;
                transformControl.setMode('scale');
                break;

            // case 67: // C
            //     break;

            //     return
            //     const position = currentCamera.position.clone();

            //     currentCamera = currentCamera.isPerspectiveCamera ? cameraOrtho : cameraPersp;
            //     currentCamera.position.copy(position);

            //     orbit.object = currentCamera;
            //     transformControl.camera = currentCamera;

            //     currentCamera.lookAt(orbit.target.x, orbit.target.y, orbit.target.z);
            //     onWindowResize();
            //     break;

            // case 86: // V
            //     const randomFoV = Math.random() + 0.1;
            //     const randomZoom = Math.random() + 0.1;

            //     cameraPersp.fov = randomFoV * 160;
            //     cameraOrtho.bottom = - randomFoV * 500;
            //     cameraOrtho.top = randomFoV * 500;

            //     cameraPersp.zoom = randomZoom * 5;
            //     cameraOrtho.zoom = randomZoom * 5;
            //     onWindowResize();
            //     break;

            case 187:
            case 107: // +, =, num+
                transformControl.setSize(transformControl.size + 0.1);
                break;

            case 189:
            case 109: // -, _, num-
                transformControl.setSize(Math.max(transformControl.size - 0.1, 0.1));
                break;

            case 88: // X
                transformControl.showX = !transformControl.showX;
                break;

            case 89: // Y
                transformControl.showY = !transformControl.showY;
                break;

            case 90: // Z
                transformControl.showZ = !transformControl.showZ;
                break;

            case 32: // Spacebar
                transformControl.enabled = !transformControl.enabled;
                break;

            case 27: // Esc
                let tControl = threeViewer.transformControl
                if (tControl.object && tControl.showX && tControl.showY && threeViewer.sketcher.entityMode !== "move") {
                    detachTransControlAndClearXYZValueInHtml(threeViewer, "from ESC")
                    return
                }
                currentObject = undefined
                if (lastEntityMode)
                    threeViewer.sketcher.entityMode = lastEntityMode
                detachTransControlAndClearXYZValueInHtml(threeViewer, "from ESC")
                activateGeometry(e, null)
                threeViewer.clipboardActions.reset()
                threeViewer.entityNotes.reset()
                break;
            case 46: // Delete
                deleteObj()
                break;

            // case 37: // Left arrow
            //     // Handle left arrow key press
            //     let ele = document.getElementById("negativeX")
            //     if (!ele) {
            //         console.warn("up ele not found");
            //         return;
            //     }
            //     ele.click()

            //     break;

            // case 38: // Up arrow
            //     // Handle up arrow key press
            //     let eleUp = document.getElementById("positiveY")
            //     if (!eleUp) {
            //         console.warn("up ele not found");
            //         return;
            //     }
            //     eleUp.click()
            //     break;

            // case 39: // Right arrow
            //     // Handle right arrow key press
            //     let positiveX = document.getElementById("positiveX")
            //     if (!positiveX) {
            //         console.warn("up ele not found");
            //         return;
            //     }
            //     positiveX.click()
            //     break;

            // case 40: // Down arrow
            //     // Handle down arrow key press
            //     let negativeY = document.getElementById("negativeY")
            //     if (!negativeY) {
            //         console.warn("up ele not found");
            //         return;
            //     }
            //     negativeY.click()
            //     break;
        }
    });

    // Set the default color to color-picker
    document.getElementById("modelColorPicker").value = "#" + (environmentVariable.modelColor).toString(16)

}

addBtnEvents()
addEventsListenersCustom()
addComponents()

let getCircle4Points = (circleCenter, circleRadius, obj) => {
    // THIS FUNCTION FIND CIRCLE POINTS USING CIRCLE NOT USING EDGE POINTS

    const angles = [0, 90, 180, 270].map(angle => THREE.MathUtils.degToRad(angle));
    const points = angles.map(angle => {
        const x = origin.x + circleRadius * Math.cos(angle);
        const y = origin.y + circleRadius * Math.sin(angle);
        const z = 0;
        let circle = makeSmallCircle(3)
        circle.position.copy(new THREE.Vector3(x, y, z))
        obj.add(circle)
        return new THREE.Vector3(x, y, z);
    });
}

let makeCircle = (radius, name = "refPlane") => {
    let lastObj = scene.getObjectByName(name)
    const geometry = new THREE.CircleGeometry(radius, 36);
    if (lastObj) {
        lastObj.geometry = new THREE.CircleGeometry(radius, 36);
        removeAndAddBorder(lastObj);
        return { circleMesh: lastObj, lastObj }
    }
    const circle = new THREE.Mesh(geometry, material);
    circle.name = name
    circle.userData.draggable = true
    circle.renderOrder = 1;
    // getVerticesOfObjects(circle)
    let border = generateGeometryBorder(geometry)
    circle.add(border);
    let centeredCircle = moveObjectToCenter(circle, true)
    pickingObjects.push(circle)
    return { circleMesh: centeredCircle, lastObj }
}

let makeCustomGeoStartCircle = (isVertexPoint = false) => {
    if (!isVertexPoint)
        removeObjWithChildren(scene.getObjectByName("customCirc"))
    const geometry = new THREE.CylinderGeometry(2, 2, 0.001, 37, 1);
    const material = new THREE.MeshBasicMaterial({
        color: "red", side: THREE.FrontSide, transparent: true, opacity: 0.5, polygonOffset: true,
        polygonOffsetFactor: -0.2,
        polygonOffsetUnits: -100,
        depthWrite: false
    });
    const cube = new THREE.Mesh(geometry, material);
    cube.name = "customCirc"
    cube.renderOrder = 1;
    let cube2 = moveObjectToCenter(cube, true)
    pickingObjects.push(cube)
    return cube2
}

let makeSmallCircle = (distance, vIndex, visible = false) => {

    const geometry = new THREE.CircleGeometry(distance, 37);
    const circle = new THREE.Mesh(geometry, material.clone());
    circle.name = "smallCircle"
    circle.renderOrder = 1;
    circle.visible = visible
    if (vIndex) circle.userData = vIndex
    return circle
}



let makeSeparatedBorderAndCircle = (mesh, addCircleToPickingArray = false, forUpdate = false) => {
    let vertexes = getVerticesFirstFour(mesh, true)
    let circleCount = 0
    for (let i = 0; i < vertexes.length; i++) {
        let position1 = vertexes[i];
        let position2
        let last = i + 1
        if (i === vertexes.length - 1) {
            position2 = vertexes[0];
            last = 0
        } else {
            position2 = vertexes[i + 1];
        }
        if (forUpdate) {
            let editCircle1 = mesh.getObjectByName("smallCircle_" + circleCount)
            circleCount += 1
            editCircle1.position.copy(position1)
            let editCircle2 = mesh.getObjectByName("smallCircle_" + circleCount)
            circleCount += 1
            editCircle2.position.copy(getMidPoint(position1, position2))
        } else {
            makeCircle(position1, [i], circleCount);
            circleCount += 1
            makeCircle(getMidPoint(position1, position2), [i, last], circleCount)
            circleCount += 1
        }
        function makeCircle(position1, vIndex, circleCount) {
            let circle = makeSmallCircle(3, vIndex);
            circle.position.copy(position1)
            circle.name = "smallCircle_" + circleCount
            mesh.add(circle);
            if (addCircleToPickingArray) {
                pickingObjectsCircle.push(circle)
            }
        }
        let line = makeLine(position1, position2, true)
        mesh.add(line)
    }
}

let makeRectangle2D = (width, height) => {
    let lastObj = scene.getObjectByName("refPlane")
    removeObjWithChildren(lastObj)
    const geometry = new THREE.PlaneGeometry(width, height);
    const plane = new THREE.Mesh(geometry, material);
    makeSeparatedBorderAndCircle(plane)
    plane.name = "refPlane"
    plane.userData.draggable = true
    return { plane, lastObj }
}

let makeSquare = (distance) => {
    let lastObj = scene.getObjectByName("refPlane")
    removeObjWithChildren(lastObj)
    const geometry = new THREE.PlaneGeometry(distance, distance);
    const plane = new THREE.Mesh(geometry, material);
    makeSeparatedBorderAndCircle(plane)
    plane.name = "refPlane"
    plane.userData.draggable = true
    return { plane, lastObj }
}

function makeGeometryForEllipse(radiusX, radiusY) {
    var path = new THREE.Shape();
    path.absellipse(0, 0,
        radiusX, radiusY,
        0, Math.PI * 2,
        false, 0);
    const geometry = new THREE.ShapeGeometry(path, 32)
    return geometry;
}

let makeEllipse = (vector1, vector2) => {
    removeObjWithChildren(scene.getObjectByName("refPlane"))
    var geometry = makeGeometryForEllipse(vector2.x - vector1.x, vector2.y - vector1.y);
    const material = new THREE.MeshBasicMaterial({
        color: "red",
        side: THREE.FrontSide,
        transparent: true, opacity: 0.5,
        polygonOffset: true,
        polygonOffsetFactor: -0.2,
        polygonOffsetUnits: -100,
        depthWrite: false,
        // wireframe:true
    });

    var ellipse = new THREE.Mesh(geometry, material);
    let border = generateGeometryBorder(geometry)
    ellipse.add(border)
    ellipse.name = "refPlane"
    ellipse.userData.draggable = true
    return ellipse
}


let makeRectangleForReference = (distance) => {
    removeObjWithChildren(scene.getObjectByName("rectangleReference"))

    const geometry = new THREE.BoxGeometry(distance, 0.001, distance);
    const material = new THREE.MeshBasicMaterial({
        color: "red",
        side: THREE.FrontSide,
        transparent: true, opacity: 0.5,
        polygonOffset: true,
        polygonOffsetFactor: -0.2,
        polygonOffsetUnits: -100,
        depthWrite: false
    });

    const cube = new THREE.Mesh(geometry, material);
    cube.name = "rectangleReference"
    cube.renderOrder = 1;

    let cube2 = moveObjectToCenter(cube, true)
    var geo = new THREE.EdgesGeometry(geometry);
    var mat = new THREE.LineBasicMaterial({ color: "black" });
    var wireframe = new THREE.LineSegments(geo, mat);
    cube.add(wireframe);
    return cube2
}


let makeVertices = () => {
    const geometrys = new THREE.SphereGeometry(3, 32, 16);
    const vertexMesh = new THREE.Mesh(geometrys, material);
    vertexMesh.name = "vertexSphere"
    pickingObjects.push(vertexMesh)
    vertexMesh.visible = false
    return vertexMesh
}

function getIntersectingData(intersection, is2D = false) {
    if (intersection) { }
    else return
    if (intersection.length > 0) {
        // set the position of the cylinder
        let firstMeshPoint = intersection[0].point
        let firstMesh = intersection[0]
        if (!firstMesh) {
            firstMeshPoint = intersection[0].point
            firstMesh = intersection[0]
        }
        // get the vector normal to face
        if (firstMesh.face && firstMesh.face.normal) { }
        else return { intersectionPoint: firstMeshPoint, firstMesh: firstMesh }

        var normalVector = firstMesh.face.normal.clone();

        // get rotation in case of object rotation 
        var objRotation = firstMesh.object.rotation;

        // Apply mesh rotation to vector
        normalVector.applyEuler(objRotation);

        // the object points up 
        if (is2D) var up = new THREE.Vector3(0, 0, 1)
        else var up = firstMesh.object.up;

        // determine an axis to rotate around
        // cross will not work if normalVector == +up or -up, so there is a special case
        if (normalVector.y == 1 || normalVector.y == -1) {
            var axis = new THREE.Vector3(1, 0, 0)
        }
        else {
            var axis = new THREE.Vector3().crossVectors(up, normalVector);
            axis.normalize();
        }

        // determine the amount to rotate
        // var radians = Math.acos( normalVector.dot( up ) );
        var radians = normalVector.angleTo(up)
        return { intersectionPoint: firstMeshPoint, axis, radians, normalVector, firstMesh: firstMesh }
    }
}

function calculateMassProperties(shape) {
    if (!shape) return
    let totalVolume = 0
    let totalArea = 0
    shape.traverse((ocShape) => {
        if (ocShape instanceof DCShape) {
            //Volume
            let volume = findVolumeFromShape(openCascade, ocShape.userData.ocShape)
            let area = findSurfaceAreaFromShape(openCascade, ocShape.userData.ocShape)
            ocShape.userData.Volume = volume
            ocShape.userData.Area = area
            totalVolume += volume
            totalArea += area
        }
    })
    let dimensions = getDimensionOfObject(shape)
    return {
        totalVolume,
        totalArea,
        dimensions,
    }
}
document.getElementById('calculateWeight').addEventListener('click', () => {
    let shape = threeViewer.scene.getObjectByName("shape")
    if (!shape) {
        customAlert("Please load the model for estimate")
        return
    }
    let massProperties = calculateMassProperties(shape)
    openHtmlModal("massPropertiesModal")
    let modelVolumeEle = document.getElementById("modelVolume")
    modelVolumeEle.innerHTML = roundDigit(massProperties.totalVolume)
    let modelAreaEle = document.getElementById("modelArea")
    modelAreaEle.innerHTML = roundDigit(massProperties.totalArea)
    if (shape) {
        let d = massProperties.dimensions
        document.getElementById("modelLength").innerHTML = roundDigit(d.x)
        document.getElementById("modelWidth").innerHTML = roundDigit(d.y)
        document.getElementById("modelHeight").innerHTML = roundDigit(d.z)
    }
})

 let cutShapes = async () => {
    let progressBars = threeViewer.loaders.incrementalLoader
    await progressBars.setProgress(2);
    let extrudeData = getCutExtrudeDataOfScene(threeViewer)
    let currentFace = threeViewer.sketcher.currentFace
    let stepData = [currentFace.parent.userData.ocShapeCloned]

    replaceSTepData(stepData, stepData[1], currentFace)

    let countTotalCutAndNeedPercent = () => {
        let totalCuts = 0
        for (const key in extrudeData) {
            totalCuts += extrudeData[key].length
        }
        let needIncrementPercentPerCut = 100 / totalCuts
        return needIncrementPercentPerCut
    }

    let incrementPercent = countTotalCutAndNeedPercent()
    let currentPercent = 0
    async function incrementProgress() {
        currentPercent += incrementPercent;
        await progressBars.setProgress(currentPercent);
    }

    await cutCircles();
    await cutCustomShapes(); // made by lines only : DCPoly lines
    await cutRectangleShapes();
    await cutEllipse();
    await cutTrimShapes()
    // await cutOutCustomShapes() // Neglect it because we are not using it right now.  // was made by json data polyLines: lines and archs 
    await cutStepShape()
    hideGeometricDimensionDataInHtml()
    replaceShapeToolShapeInOpenCascade()
    progressBars.resetProgress()
    pickingObjectsForMove.length = 0
    pickingObjectsCircle.length = 0

    return {stepData,progressBars}

    function areSame(inP1, inP2) {
        let Tol = 0.0001
        if (Math.abs(inP1.X() - inP2.X()) < Tol && Math.abs(inP1.Y() - inP2.Y()) < Tol)
            return true;
        return false;
    }
    async function cutOutCustomShapes() {
        let currentFace = threeViewer.sketcher.getCurrentFace()
        let profileData = {
            normal: currentFace.normal,
            edges: []
        }

        let jsonPolyShapes = currentFace.getObjectsByProperty("name", "jsonPolyShape")
        jsonPolyShapes.forEach(polyShape => {

            // Get all cutting data for line and arch
            let totalLines = polyShape.length // total lines and arch

            for (let i = 0; i < totalLines; i++) {
                let obj = polyShape.getObjectsByProperty("sequence", i)[0]
                if (obj) {
                    let objCuttingData = obj.getCuttingData()
                    objCuttingData.startPoint = obj.localToWorld(objCuttingData.startPointL.clone())
                    objCuttingData.endPoint = obj.localToWorld(objCuttingData.endPointL.clone())
                    if (objCuttingData.center) {
                        objCuttingData.center = obj.localToWorld(objCuttingData.center)
                    }
                    profileData.edges.push(objCuttingData)
                }
            }
        });
        if (profileData.edges.length == 0) return
        let profileBody = makeCustomProfile(profileData, openCascade)
        let newStepData = makeCut(openCascade, stepData[0], profileBody.Shape(), threeViewer);
        replaceSTepData(stepData, newStepData, currentFace)

        await incrementProgress();

    }
    async function cutTrimShapes() {
        let shapes = extrudeData.shapes;
        for (let s of shapes){
            await cutTrimShape(s)
        }
    }
    async function cutTrimShape(trimedCurves) {
        // let trimedCurves = threeViewer.scene.getObjectByName("trimShape")
        if (!trimedCurves) return
        trimedCurves = trimedCurves.ocData
        let trimmedcurveall = trimedCurves.flat()
        let allTrimmedCurve = []
        trimmedcurveall.forEach(element => {
            allTrimmedCurve.push(...element.trimmedCurve)
        });
        // if(allTrimmedCurve.length <= 2)
        //     return;
        let acquiredTrimedCurves = [];


        // Acquire curve from all curve
        function isAcquired(inCurve) {
            for (let index = 0; index < acquiredTrimedCurves.length; index++) {
                if (acquiredTrimedCurves[index] == inCurve)
                    return true
            }
            return false;
        }
        let isStartPoint = true;

        // Get next curve whose point is similar to previous 
        function getNextCurve(inPoint) {
            for (let i = 0; i < allTrimmedCurve.length; i++) {
                if (isAcquired(allTrimmedCurve[i]))
                    continue;
                let p1 = allTrimmedCurve[i].StartPoint();

                if (areSame(inPoint, p1)) {
                    isStartPoint = true;
                    return allTrimmedCurve[i]
                }
                let p2 = allTrimmedCurve[i].EndPoint();
                if (areSame(inPoint, p2)) {
                    isStartPoint = false;
                    return allTrimmedCurve[i]
                }

            }
        }
        // Acquire curve from all curve (Sorted)
        let currentCurve = allTrimmedCurve[0];
        acquiredTrimedCurves.push(currentCurve);
        while (1) {
            if (acquiredTrimedCurves.length == allTrimmedCurve.length)
                break;

            let p = null
            if (isStartPoint)
                p = currentCurve.EndPoint();
            else
                p = currentCurve.StartPoint();

            let nextCurve = getNextCurve(p, isStartPoint);

            acquiredTrimedCurves.push(nextCurve);
            currentCurve = nextCurve;
        }

        const mkWire = new openCascade.BRepBuilderAPI_MakeWire_1();
        // Make profile from the sorted curve
        for (let i = 0; i < acquiredTrimedCurves.length; i++) {
            if (acquiredTrimedCurves[i].BasisCurve().get() instanceof openCascade.Geom_Line) // Line
            {

                let p1 = new openCascade.gp_Pnt_3(acquiredTrimedCurves[i].StartPoint().X(), acquiredTrimedCurves[i].StartPoint().Y(), acquiredTrimedCurves[i].StartPoint().Z())
                let p2 = new openCascade.gp_Pnt_3(acquiredTrimedCurves[i].EndPoint().X(), acquiredTrimedCurves[i].EndPoint().Y(), acquiredTrimedCurves[i].EndPoint().Z())

                let aSegment = new openCascade.GC_MakeSegment_1(p1, p2);
                let mEdge = new openCascade.BRepBuilderAPI_MakeEdge_24(new openCascade.Handle_Geom_Curve_2(aSegment.Value().get()));
                let aWire = new openCascade.BRepBuilderAPI_MakeWire_2(mEdge.Edge());
                mkWire.Add_2(aWire.Wire());
            }
            else if (acquiredTrimedCurves[i].BasisCurve().get() instanceof openCascade.Geom_Circle) {//Circle
                let circle = acquiredTrimedCurves[i].BasisCurve().get().Circ()
                let p1 = new openCascade.gp_Pnt_3(acquiredTrimedCurves[i].StartPoint().X(), acquiredTrimedCurves[i].StartPoint().Y(), acquiredTrimedCurves[i].StartPoint().Z())
                let p2 = new openCascade.gp_Pnt_3(acquiredTrimedCurves[i].EndPoint().X(), acquiredTrimedCurves[i].EndPoint().Y(), acquiredTrimedCurves[i].EndPoint().Z())
                let anArcOfCircle = new openCascade.GC_MakeArcOfCircle_3(circle, p1, p2, false)
                const mEdge = new openCascade.BRepBuilderAPI_MakeEdge_24(new openCascade.Handle_Geom_Curve_2(anArcOfCircle.Value().get()));
                let aWire = new openCascade.BRepBuilderAPI_MakeWire_2(mEdge.Edge());
                mkWire.Add_2(aWire.Wire());
            }

        }
        // Extrude the profile
        const myWireProfile = mkWire.Wire();
        const myFaceProfile = new openCascade.BRepBuilderAPI_MakeFace_15(myWireProfile, false);
        const aPrismVec = new openCascade.gp_Vec_4(10 * -window.tempNormal.x, 10 * -window.tempNormal.y, 20 * -window.tempNormal.z);
        let myBody = new openCascade.BRepPrimAPI_MakePrism_1(myFaceProfile.Face(), aPrismVec, false, true);
        let newStepData = makeCut(openCascade, stepData[0], myBody.Shape(), threeViewer);
        replaceSTepData(stepData, newStepData, currentFace)

        await incrementProgress();
        // threeViewer.sketcher.temp.length = 0
    }
    function hideGeometricDimensionDataInHtml() {
        resetSketchMode(threeViewer)
        let geometricDimensionDataEle = document.getElementsByClassName("geometricDimensionData")
        Array.from(geometricDimensionDataEle).forEach(element => {
            element.style = "display:none"
        });
    }
    async function cutEllipse() {
        let ellipseShapes = extrudeData.ellipse;
        for (let entity of ellipseShapes){
            let data = entity.getCuttingData()
            let face = entity.getParentFace()
            let faceNormal = face.normal
            let customBodyData = ellipseBody(openCascade, data.center, data.xRadius, data.yRadius, faceNormal, data.rotation, data.entityData.getExtrudeDepth());
            // let threeJsGeometry = addShapeToSceneNew(openCascade, customBodyData.Shape(), new THREE.Color(environmentVariable.color), new THREE.Color(environmentVariable.color),threeViewer.scene, "names", undefined,"cutShapeNsame",6);
            let newStepData = makeCut(openCascade, stepData[0], customBodyData.Shape(), threeViewer);

            replaceSTepData(stepData, newStepData, currentFace)
            await incrementProgress();
        };
        ellipseShapes.length = 0;
    }

    async function cutRectangleShapes() {
        let rectangleShapes = extrudeData.rectangles;
        for (let entity of rectangleShapes){
            let cuttingData = entity.getCuttingData()
            let face = entity.getParentFace()
            let faceNormal = face.normal
            let extrudeDepth = cuttingData.entityData.getExtrudeDepth()

            let customBodyData = rectangleBody2(openCascade, cuttingData.points, -faceNormal.x, -faceNormal.y, -faceNormal.z, extrudeDepth);
            // let threeJsGeometry = addShapeToSceneNew(openCascade, customBodyData.Shape(), new THREE.Color(environmentVariable.color), new THREE.Color(environmentVariable.color),threeViewer.scene, "names", undefined,"cutShapeNsame",6);
            let newStepData = makeCut(openCascade, stepData[0], customBodyData.Shape(), threeViewer);
            replaceSTepData(stepData, newStepData, currentFace)

            await incrementProgress();

        };
        rectangleShapes.length = 0;
    }

    async function cutCustomShapes() {
        let customShapes = extrudeData.customShapes;
        for (let entity of customShapes){
            let cuttingData = entity.getCuttingData()
            let face = entity.getParentFace()
            let faceNormal = face.normal
            let extrudeDepth = cuttingData.entityData.getExtrudeDepth()
            let customBodyData = rectangleBody2(openCascade, cuttingData.points, -faceNormal.x, -faceNormal.y, -faceNormal.z, extrudeDepth);
            // let threeJsGeometry = addShapeToSceneNew(openCascade, customBodyData.Shape(), new THREE.Color(environmentVariable.color), new THREE.Color(environmentVariable.color),threeViewer.scene, "name", undefined,"cutShapeName",6);
            let newStepData = makeCut(openCascade, stepData[0], customBodyData.Shape(), threeViewer);
            replaceSTepData(stepData, newStepData, currentFace)

            await incrementProgress();
        };
        customShapes.length = 0;
    }

    async function cutCircles() {
        let cir = extrudeData.circles;
        for(let entity of cir){
            let data = entity.getCuttingData()
            let face = entity.getParentFace()
            let faceNormal = face.normal
            let radius = data.radius
            let center = data.center
            let extrudeDepth = data.entityData.getExtrudeDepth()
            let cylinderBodyData = cylinderBody(openCascade, radius, center.x, center.y, center.z, -faceNormal.x, -faceNormal.y, -faceNormal.z, extrudeDepth).Shape();
            // let threeJsGeometry = addShapeToSceneNew(openCascade, customBodyData.Shape(), new THREE.Color(environmentVariable.color), new THREE.Color(environmentVariable.color),threeViewer.scene, "names", undefined,"cutShapeNsame",6);

            // let threeJsGeometry = addShapeToSceneNew(openCascade, cylinderBodyData, undefined, new THREE.Color(environmentVariable.modelColor),threeViewer.scene, "name", undefined,"cutShapeName",6);
            let newStepData = makeCut(openCascade, stepData[0], cylinderBodyData, threeViewer);
            replaceSTepData(stepData, newStepData, currentFace)

            await incrementProgress();
        };
       

        // cir.length = 0;
    }
    async function cutStepShape() {
        // get shape position and normal
        let shapes = extrudeData.libraryShapes;
        for (let shape of shapes){
            cut(shape)
            await incrementProgress(); 
        }

        function cut(libraryShape) {
            let assemblyTree = libraryShape.assemblyTree;

            if (assemblyTree.children) {
                cutAllChildrenShape(c.children)
            }
            else if (assemblyTree.shape) {
                cutOneShape(assemblyTree.shape);
            }

            function cutAllChildrenShape(children) {
                children.forEach((c) => {
                    if (c.children) {
                        cutAllChildrenShape(c.children)
                    }
                    else if (c.shape) {
                        cutOneShape(c.shape);
                    }
                })
            }

            function cutOneShape(shape) {
                let face = libraryShape.getParentFace();
                let faceNormal = face.normal;

                //cut using cutting data
                let cuttingData = libraryShape.getCuttingData();

                let position = cuttingData.point;
                let rotationQuaternion = cuttingData.rotation;

                let gp_Quaternion = new openCascade.gp_Quaternion_2(rotationQuaternion.x, rotationQuaternion.y, rotationQuaternion.z, rotationQuaternion.w);
                const tf1 = new openCascade.gp_Trsf_1();
                const tf2 = new openCascade.gp_Trsf_1();
                tf1.SetRotation_2(gp_Quaternion);
                tf2.SetTranslation_1(new openCascade.gp_Vec_4(position.x, position.y, position.z));
                const loc1 = new openCascade.TopLoc_Location_2(tf1);
                const loc2 = new openCascade.TopLoc_Location_2(tf2);
                let libraryShapeTranslated = shape.Moved(loc1, false);
                libraryShapeTranslated = libraryShapeTranslated.Moved(loc2, false);

                let extrudeDepth = libraryShape.entityData.getExtrudeDepth();
                const aPrismVec = new openCascade.gp_Vec_4(-faceNormal.x * extrudeDepth, -faceNormal.y * extrudeDepth, extrudeDepth * -faceNormal.z);
                let myBody = new openCascade.BRepPrimAPI_MakePrism_1(libraryShapeTranslated, aPrismVec, false, true).Shape();

                // let threeJsGeometry = addShapeToSceneNew(openCascade, myBody, new THREE.Color(environmentVariable.color), new THREE.Color(environmentVariable.color),cuttingData.dcShape, "name", undefined,"cutShapeName",6);
                let newStepData = makeCut(openCascade, stepData[0], myBody, threeViewer);
                replaceSTepData(stepData, newStepData, currentFace);
            }
        }
    }

    function replaceShapeToolShapeInOpenCascade() {
        if (incrementPercent === Infinity) return
        replaceOpenCascadeShape(threeViewer.ocTools.shapeTool, currentFace.parent.userData.ocShape, stepData[0], openCascade);
        currentFace.parent.userData.ocShape = stepData[0]
    }
}
let labelRenderInScene = (innerWidth, innerHeight) => {
    labelRenderer = new CSS2DRenderer();
    const viewport = document.getElementById("viewport");
    labelRenderer.setSize(innerWidth, innerHeight);
    labelRenderer.domElement.classList.add("labelRenderer");

    labelRenderer.domElement.style.position = 'absolute';
    labelRenderer.domElement.style.top = '0px';
    viewport.appendChild(labelRenderer.domElement);
}

let translateTransformControl = (translateData) => {
    // translateData : object
    if (!threeViewer.transformControl.object) return
    if (translateData) {
        if (translateData.translateX) threeViewer.transformControl.object.translateX(translateData.translateX);
        if (translateData.translateY) threeViewer.transformControl.object.translateY(translateData.translateY);
    }
}

let updateXYZValueInHtml = (obj) => {
    if (!obj && !threeViewer.transformControl.object) return
    else if (!obj) {
        obj = threeViewer.transformControl.object
    }
    if (["DCFace", "shape"].includes(obj.name)) return

    // Reset properties box
    let changeDimensionEles = document.getElementsByClassName("changeDimension")
    Array.from(changeDimensionEles).forEach((element) => {
        element.style.display = "none"
    })
    // Show properties box
    document.getElementById("propertyBox").style = "display:block"
    document.getElementById("commonEditDimensionDiv").style = "display:block"

    // Update Notes
    threeViewer.entityNotes.updateNoteDom()


    // Set origin values
    let originX = document.getElementById("originX")
    let originY = document.getElementById("originY")
    originX.value = roundDigit(obj.position.x, 3)
    originY.value = roundDigit(obj.position.y, 3)

    // Set rotation values
    let radianToDegreeValue = radianToDegree(obj.rotation.z)
    document.getElementById("objRotation").value = roundDigit(radianToDegreeValue)
    if (obj.entityData) {
        let extrudeDepth = obj.entityData.extrudeDepth
        let engrave = obj.entityData.engrave
        document.getElementById("objDepth").value = extrudeDepth
        document.getElementById("objDepthMainDiv").style = `display:${engrave ? 'flex' : 'none'}`
        document.getElementById("objEngrave").checked = engrave
    }

    // Change rectangle data
    if (obj.entityType === "Rect") {
        // change width
        document.getElementById("rectGeoData").style.display = "block"
        let rectWidth = document.getElementById("rectWidth")
        let objWidth = obj.getWidth()
        rectWidth.value = roundDigit(objWidth)
        // change height
        document.getElementById("rectGeoData").style.display = "block"
        let rectHeight = document.getElementById("rectHeight")
        let objHeight = obj.getHeight()
        rectHeight.value = roundDigit(objHeight)
    }

    // Change circle's diameter
    else if (obj.entityType === "Circle") {
        document.getElementById("circleGeoData").style.display = "block"
        let circleDiameter = document.getElementById("circleDiameter")
        circleDiameter.value = roundDigit(obj.getDiameter())
    }

    // Change ellipse's radiusX and radiusY
    else if (obj.entityType === "Ellipse") {
        document.getElementById("ellipseGeoData").style.display = "block"
        let ellipseRadiusX = document.getElementById("ellipseRadiusX")
        let ellipseRadiusY = document.getElementById("ellipseRadiusY")
        ellipseRadiusX.value = roundDigit(Math.abs(obj.getXRadius()))
        ellipseRadiusY.value = roundDigit(Math.abs(obj.getYRadius()))
    }
}
function referencePlane(name) {
    const width = 5;
    const height = 5;
    const segmentsX = 1; // Number of segments along the width
    const segmentsY = 1; // Number of segments along the height
    const planeGeometry = new THREE.PlaneGeometry(width, height, segmentsX, segmentsY);
    let planeMesh = new THREE.MeshBasicMaterial({ color: 0x00ff00, side: THREE.DoubleSide })
    let plane = new THREE.Mesh(planeGeometry, planeMesh)
    plane.name = name ? name : "referencePlane"
    return plane
}
function getGlobalRotation(object) {
    const globalRotation = new THREE.Euler();

    // Traverse the object's parent hierarchy and accumulate rotations
    let currentObject = object;
    while (currentObject) {
        globalRotation.x += currentObject.rotation.x;
        globalRotation.y += currentObject.rotation.y;
        globalRotation.z += currentObject.rotation.z;

        currentObject = currentObject.parent;
    }

    return globalRotation;
}

function addComponents() {
    addRegistrationComponent()
}

export async function cutOutTheShapes (params) {
    let needGuide = params.needGuide // true/false
    if (!openCascade) {
        console.warn("opencascade is undefined, maybe design mode is on")
        return
    }
    pickingObjects.remove(scene.getObjectByName("sketchPlane"))

        visibleDCShapeAsPreviousVisible()

        let stepData,progressBars
        // stepData = await cutShapes()
        if (environmentVariable.development){
            let cutOutData = await cutShapes()  
            stepData = cutOutData.stepData
        }
        else{
            try {
                let cutOutData = await cutShapes() // cutShapes will cutout the type of stepData and finally update the stepData[0]
                stepData = cutOutData.stepData
                threeViewer.loaders.incrementalLoader.resetProgress();
            } catch (error) {
                customAlert("something went wrong while cutting the object")
                console.error(error)
                threeViewer.loaders.incrementalLoader.resetProgress();
            }
        }

        makeSketchModeOff()

        if (!stepData) return

        let currentFace = threeViewer.sketcher.currentFace

        // GET NEEDED INFORMATION ABOUT REFERENCE SHAPE
        let faceParent = currentFace.parent
        let name = faceParent.name
        let color = new THREE.Color(faceParent.commonColor)
        let sequenceName = faceParent.sequenceName
        let sequenceCount = sequenceName.split("_referenceShape")[0]

        // GET NEEDED INFORMATION ABOUT CUT SHAPE
        let cutShapeName = sequenceCount + "_shape"
        let cutoutShapeObject = scene.getObjectByProperty("sequenceName", cutShapeName)
        let cutUserData = cutoutShapeObject.userData
        let facesColor = cutUserData.facesColor
        let cutoutShapeObjectMatrix = cutoutShapeObject.matrix
        let cutShapeParent = cutoutShapeObject.parent
        // REMOVE OLD CUT SHAPE AND CREATE NEW CUT-HOLE SHAPE 
        removeObjWithChildren(cutoutShapeObject);
        let threeJsGeometry = addShapeToSceneNew(openCascade, stepData[0], color, facesColor, cutShapeParent, name, cutoutShapeObjectMatrix, cutShapeName, 6);
        threeViewer.state.objSequence.reset()
        // GENERATE NEW PART TREE FOR REFERENCE SHAPE
        let partTree = new PartTree(scene.getObjectByName("referenceShape"), threeViewer)
        partTree.init()
        threeViewer.sketcher.currentFace = null
        if (needGuide){
            setTimeout(() => {
                HtmlGuideForProceedToProcurement({threeViewer})
            }, 4000);
        }
}
export { setupThreeJSViewport };
              