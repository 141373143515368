import { createStepShape, cutOutTheShapes } from "../setupTHREE"
import { GenerateDCCircle, GenerateDCEllipse, GenerateDCPolyline, GenerateDCRect } from "../threeCustom/cuttingEntities/DCEntity"
import { CreateEmbossText } from "../threeCustom/embossing/textEmboss/emboss"
import { CutoutShape } from "../threeCustom/library/cutouts/cutoutShape"
import { uploadGraphicImages } from "../utils/commonFunction"
import { readBlob } from "../utils/utils"
import { environmentVariable } from "../variables"
import { HandleImgUpload, swapAllGraphicsToPreviousParent, swapAllGraphicsToScene } from "./imgUpload"

export class CreateDrawnEntitiesUsingJson {
    constructor(params) {
        this.file = params.file
        this.threeViewer = params.threeViewer
        this.openModelUsingUrl = params.openModelUsingUrl
    }
    async init(){
        let scope = this
        // Get Json
        let jsonString = await readBlob(this.file)
        const json = JSON.parse(jsonString);
        this.json = json

        // Enable loader
        document.getElementById("inputStepLoader").style = "display:block"

        let downloadUrlFile = json.fileUrl
        await scope.openModelUsingUrl(downloadUrlFile)
        await this.createEntities(json)
        await this.cutoutShapes()
    }
    async createEntities(json){
        let scope = this
        for(let entity of json.entities){
            let type = entity.entityType
            switch (type) {
                case "image":
                    await scope.createImageModelGroup({ entityData: entity })
                    break;
                    case "font":
                    await scope.createEmbossWords({entityData : entity})
                    break;
                    
                    case "Rect":
                    scope.createRect({sketchType:entity.sketchType,po1:entity.cornerPoints[0],po2:entity.cornerPoints[1],sequenceFaceName:entity.sequenceFaceName,position:entity.position})
                    break;
                    case "Circle":
                    scope.createCircle({entityData : entity})
                    break;
                case "Ellipse":
                    scope.createEllipse({entityData : entity})
                    break;
                case "Polyline":
                    scope.createPolyline({entityData : entity})
                    break;
                case "DCPolylineWithArc":
                    scope.createPolyline({entityData : entity})
                    break;
                    
                case "cutOutLibrary":
                    await scope.createCutOutLibrary({entityData : entity})
                    break;
                case "customSTPShape":
                    await scope.createCustomSTPShape({entityData : entity})
                    break;
            
                default:
                    break;
            }

        }
        

        // cutOutTheSha?pes(this.threeViewer.scene)
    }
    async cutoutShapes(){
        let referenceShapeObj = threeViewer.scene.getObjectByName("referenceShape")
        let faces = referenceShapeObj.getObjectsByProperty("customType", "DCFace")
        for(let face of faces){
            let entities = face.getObjectsByProperty("customType", "DCEntity");
            if (entities.length > 0){
                this.threeViewer.sketcher.currentFace = face
                let cutoutShape = await cutOutTheShapes({needGuide : false})
            }
        }

    }
    
    createRect(params) {

        let sketchType = params.sketchType
        let po1 = params.po1
        let po2 = params.po2
        let sequenceFaceName = params.sequenceFaceName
        let position = params.position
        let sequenceFaceObj = threeViewer.scene.getObjectByProperty('sequenceFaceName', sequenceFaceName)
        let rect = new GenerateDCRect({ sketchType: sketchType, po1: new THREE.Vector2(po1.x, po1.y), po2: new THREE.Vector2(po2.x, po2.y),sequenceFaceName: sequenceFaceName,position : position })
        sequenceFaceObj.threeFace.add(rect)

    }
    createCircle(params) {

        let entityData = params.entityData

        // Create circle using entity info data
        let circle = new GenerateDCCircle(entityData)

        // Add circle in related face
        let sequenceFaceName = entityData.sequenceFaceName
        let sequenceFaceObj = threeViewer.scene.getObjectByProperty('sequenceFaceName', sequenceFaceName)
        sequenceFaceObj.threeFace.add(circle)

    }
    createEllipse(params) {

        let entityData = params.entityData

        // Create circle using entity info data
        let circle = new GenerateDCEllipse(entityData)

        // Add ellipse in related face
        let sequenceFaceName = entityData.sequenceFaceName
        let sequenceFaceObj = threeViewer.scene.getObjectByProperty('sequenceFaceName', sequenceFaceName)
        sequenceFaceObj.threeFace.add(circle)

    }
    createPolyline(params) {

        let entityData = params.entityData

        // Create polyline using entity info data
        let polyLine = new GenerateDCPolyline(entityData)

        // Add ellipse in related face
        let sequenceFaceName = entityData.sequenceFaceName
        let sequenceFaceObj = threeViewer.scene.getObjectByProperty('sequenceFaceName', sequenceFaceName)
        sequenceFaceObj.threeFace.add(polyLine)

    }
    async createEmbossWords(params) {

        let entityData = params.entityData
        // Create polyline using entity info data
        let embossWordObj = new CreateEmbossText(
            {...entityData, ...{inViewer : this.threeViewer,sequenceFaceName : entityData.sequenceFaceName}}
        )
        await embossWordObj.create()
        
        // set position
        embossWordObj.position.x = entityData.position.x
        embossWordObj.position.y = entityData.position.y
        embossWordObj.position.z = entityData.position.z
        let sequenceFaceName = entityData.sequenceFaceName
        embossWordObj.sequenceFaceName = sequenceFaceName

        let sequenceFaceObj = threeViewer.scene.getObjectByProperty('sequenceFaceName', sequenceFaceName)
        sequenceFaceObj.threeFace.add(embossWordObj)

        swapAllGraphicsToScene(threeViewer)

    }
    async createImageModelGroup(params) {
        let entityData = params.entityData
        // let handleImgUpload = new HandleImgUpload({ threeViewer, file,sequenceFaceName : threeViewer.sketcher.currentFace.sequenceFaceName,isGraphicImg : true });
        // Create polyline using entity info data
        let embossWordObj = new HandleImgUpload(
            { ...entityData, ...{ threeViewer: this.threeViewer, sequenceFaceName: entityData.sequenceFaceName, isGraphicImg: true, fileUrl: entityData.fileUrl } }
        )
        let imgObj = await embossWordObj.init()
        // Add in relevant DCFace->threeFace
        let faceObj = this.threeViewer.scene.getObjectByProperty("sequenceFaceName", entityData.sequenceFaceName)
        faceObj.threeFace.add(imgObj);

        imgObj.position.x = entityData.position.x
        imgObj.position.y = entityData.position.y
        imgObj.position.z = entityData.position.z

        swapAllGraphicsToScene(threeViewer)

    }
    async createCutOutLibrary(params) {
        let entityData = params.entityData
        // this.entityType = 'cutOutLibrary'
        // this.libraryName = params.name
        let customShape = new CutoutShape({...entityData, ...{threeViewer : this.threeViewer,openCascade : window.openCascade} })
        customShape.init()
        customShape.position.x = entityData.position.x
        customShape.position.y = entityData.position.y
        customShape.position.z = entityData.position.z

    }
    async createCustomSTPShape(params) {
        let entityData = params.entityData
        this.entityType = 'cutOutLibrary'
        this.libraryName = params.name
        let customShape = await createStepShape({...entityData })

        customShape.position.x = entityData.position.x
        customShape.position.y = entityData.position.y
        customShape.position.z = entityData.position.z

    }

}

export async function getDrawnEntitiesJson(threeViewer) {
    let referenceShapeObj = threeViewer.scene.getObjectByName("referenceShape")
    let json = []
    let entities = referenceShapeObj.getObjectsByProperty("customType", "DCEntity")

    swapAllGraphicsToPreviousParent(threeViewer)
    let fontObjs = threeViewer.scene.getObjectsByProperty("customType", "font")
    let imgObjs = threeViewer.scene.getObjectsByProperty("name", "image")
    entities.forEach((entity)=>{
        if (entity.parent.name == "threeFace"){
            let entityData = entity.info()
            json.push(entityData)
        }
    })
    fontObjs.forEach((entity)=>{
        let entityData = entity.info()
        json.push(entityData)
    })
    for(let entity of imgObjs){
        if (entity.isGraphicImg){
            let uploadedImageUrl = await uploadImage(entity)
            let backendImageUrl = uploadedImageUrl
            let entityData = entity.info()
            entityData["fileUrl"] = backendImageUrl
            json.push(entityData)
        }
    }

    pushCutOutLibrary({json})
    pushCustomSTPShape({json})
    swapAllGraphicsToScene(threeViewer)

    function pushCutOutLibrary(params){
        let json = params.json
        let cutOutLibraryObjs = threeViewer.scene.getObjectsByProperty("name", "cutOutLibrary") 
        cutOutLibraryObjs.forEach((entity)=>{
            let entityData = entity.info()
            json.push(entityData)
        })
        return {json}
    }
    function pushCustomSTPShape(params){
        let json = params.json

        let cutOutLibraryObjs = threeViewer.scene.getObjectsByProperty("entityType", "customSTPShape") 
        cutOutLibraryObjs.forEach((entity)=>{
            let entityData = entity.info()
            json.push(entityData)
        })

        return {json}
    }
    return json
}

export async function uploadImage(imgObj) {
    if (!imgObj.fileUrl) {
        let imgFileData = await uploadGraphicImages(imgObj.info().file)
        if (!imgFileData) {
            return false
        }
        imgObj.fileUrl = environmentVariable.REACT_APP_DatabaseServer_Main + `graphicImages/${imgFileData.file_name}`
    }
    return imgObj.fileUrl
}