import { blobToFile, blobToLInk } from "../../utils/customUtils";
import { STLExporter } from 'three/examples/jsm/exporters/STLExporter.js';

export function objToStl(obj){
    var exporter = new STLExporter();
    var str = exporter.parse( obj ); // Export the scene
    var blob = new Blob( [str], { type : 'text/plain' } ); // Generate Blob from the string
    let stlFile = blobToFile(blob,"temp.stl")
    return stlFile
}
