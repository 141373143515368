import * as THREE from 'three'
import { DCCurveTypeEnum } from './DCOpenCascadeEnums/DCEnum'

export class DCCurve extends THREE.Object3D {
    constructor(inOcEdge) {
        super()
        this.customType = "DCCurve"
        this.ocEdge = inOcEdge
        this.points = []
        this.update()
    }
    update() {
        this.generatePoints()
        this.generate3JSLines()
    }
    getCustomType() {
        return this.customType
    }
    getEdge() {
        return this.ocEdge
    }

    getEdgeType() {
        return this.edgeType
    }
    generate3JSLines() {
        const geometry = new THREE.BufferGeometry().setFromPoints(this.points);
        const material = new THREE.LineBasicMaterial({
            color: "black"
        });
        let line = new THREE.Line(geometry, material);
        this.add(line)
    }
    generatePoints () {
        let scope = this
        let adpCurve = new ocGlobal.BRepAdaptor_Curve_2(scope.ocEdge)

        this.points = [];
        const ExpVertex = new ocGlobal.TopExp_Explorer_1();
        let curveType = ocGlobal.GeomAbs_CurveType


        if (adpCurve.GetType() == curveType.GeomAbs_Circle || adpCurve.GetType() == curveType.GeomAbs_BSplineCurve) {
            let t1 = adpCurve.FirstParameter();
            let t2 = adpCurve.LastParameter();
            let dt = (t2 - t1) / 80.0

            let hGeomCurve = ocGlobal.BRep_Tool.Curve_2(scope.ocEdge, t1, t2)
            for (let t = t1; t <= t2; t += dt) {
                let point = hGeomCurve.get().Value(t);
                this.points.push(new THREE.Vector3(point.X(), point.Y(), point.Z()));
            }
            let point = hGeomCurve.get().Value(t2);
            this.points.push(new THREE.Vector3(point.X(), point.Y(), point.Z()));
        }

        else {
            for (ExpVertex.Init(scope.ocEdge, ocGlobal.TopAbs_ShapeEnum.TopAbs_VERTEX, ocGlobal.TopAbs_ShapeEnum.TopAbs_SHAPE); ExpVertex.More(); ExpVertex.Next()) {
                // Start point end point
                const myShapeVertex = ExpVertex.Current();
                const myVertex = ocGlobal.TopoDS.Vertex_1(myShapeVertex);
                const point = ocGlobal.BRep_Tool.Pnt(myVertex)
                this.points.push(new THREE.Vector3(point.X(), point.Y(), point.Z()));
            }
        }
        return this.points
    }

}

// Not using below classes for now
export class DCLineCurve extends DCCurve {
    constructor(inOcEdge, inEdgeId) {
        super(inOcEdge, inEdgeId)
        this.edgeType = DCCurveTypeEnum.LINE
    }
    
}
export class DCCircleCurve extends DCCurve {
    constructor(inOcEdge, inEdgeId) {
        super(inOcEdge, inEdgeId)
        this.edgeType = DCCurveTypeEnum.CIRCLE
    }
    
}