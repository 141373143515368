import React, { useEffect, useState } from 'react';
import { createRoot, render } from 'react-dom/client'; // Import createRoot from "react-dom/client"
import { CreateSalesOrderForPDF } from '../../views/addToCartHandlers';
import { closeHtmlModal, customAlert } from '../../utils/utils';
import { nodeApiV2, odooApiV2 } from '../../utils/apiRequest/requestV2';
import { removeLoader, showLoader } from '../../utils/loaders/loader';
import { countryCodes, mainCountryCodes } from '../../utils/countryCodes';

const RegistrationComponent = () => {
  const [formData, setFormData] = useState({
    name: '',
    // lastName: '',
    email: '',
    phone: '',
    street: '',
    city: '',
    zip: '',
    country_id: 'India',
    // password: '',
    // confirmPassword: '',
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    // lastName: '',
    email: '',
    phone: '',
    street: '',
    city: '',
    zip: '',
    country_id: '',
    // password: '',
    // confirmPassword: '',
  });

  let countries = mainCountryCodes("country")

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Perform form validation here
    let isValidate = validateForm();
    if (!isValidate) return

    closeHtmlModal("registrationModel")

    let isCustomerCreated = await createCustomerInBackend()
    if (!isCustomerCreated) {
      customAlert("While registration something went wrong")
      return
    }
    threeViewer.user.name = formData.name
    let pdfData = new CreateSalesOrderForPDF({file : threeViewer.file})
  };

  const validateForm = () => {
    let errors = {};

    // Example validation rules (you can customize as needed)
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }

    // if (!formData.lastName.trim()) {
    //   errors.lastName = 'Last Name is required';
    // }

    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!isValidEmail(formData.email)) {
      errors.email = 'Invalid email address';
    }

    if (!formData.phone.trim()) {
      errors.phone = 'Phone is required';
    }

    if (!formData.street.trim()) {
      errors.street = 'Street is required';
    }

    if (!formData.city.trim()) {
      errors.city = 'City is required';
    }

    if (!formData.zip.trim()) {
      errors.zip = 'Zip Code is required';
    }

    if (!formData.country_id.trim()) {
      errors.country_id = 'Country is required';
    }

    // if (!formData.password.trim()) {
    //   errors.password = 'Password is required';
    // } else if (formData.password.length < 6) {
    //   errors.password = 'Password must be at least 6 characters';
    // }

    // if (formData.confirmPassword !== formData.password) {
    //   errors.confirmPassword = 'Passwords do not match';
    // }

    setFormErrors(errors);

    setFormErrors(errors);

    // If there are no errors, you can proceed with form submission
    if (Object.keys(errors).length === 0) {
      // Perform form submission logic here
      return true
    }else {
      console.warn("validation failed")
      return false
    }
  };

  const isValidEmail = (email) => {
    // You can use a more sophisticated email validation regex if needed
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const createCustomerInBackend = async ()=>{
    showLoader()
    let postData = await odooApiV2.post("create_customer",formData)
    removeLoader()
    if (postData.res.status === "success"){
      return postData
    }else {
      return false
    }
  }

  return (
    <>
      <div className="modal patternModal fade" id="registrationModel" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modalHeaderLogo">
                <img src="./assets/img/logo-image.svg" className="modal-logo" alt="Logo" />
              </div>
              <h5 className="modal-title" id="alertLabel">
                Registration
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <i className="far fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    First Name
                  </label>
                  <input type="text" className={`form-control ${formErrors.name && 'is-invalid'}`} id="name" name="name" value={formData.name} onChange={handleChange} />
                  {formErrors.name && <div className="invalid-feedback">{formErrors.name}</div>}
                </div>
                {/* <div className="mb-3">
                  <label htmlFor="lastName" className="form-label">
                    Last Name
                  </label>
                  <input type="text" className={`form-control ${formErrors.lastName && 'is-invalid'}`} id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} />
                  {formErrors.lastName && <div className="invalid-feedback">{formErrors.lastName}</div>}
                </div> */}
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input type="email" className={`form-control ${formErrors.email && 'is-invalid'}`} id="email" name="email" value={formData.email} onChange={handleChange} />
                  {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                </div>
                {/* <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input type="password" className={`form-control ${formErrors.password && 'is-invalid'}`} id="password" name="password" value={formData.password} onChange={handleChange} />
                  {formErrors.password && <div className="invalid-feedback">{formErrors.password}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="confirmPassword" className="form-label">
                    Confirm Password
                  </label>
                  <input type="password" className={`form-control ${formErrors.confirmPassword && 'is-invalid'}`} id="confirmPassword" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} />
                  {formErrors.confirmPassword && <div className="invalid-feedback">{formErrors.confirmPassword}</div>}
                </div> */}
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    className={`form-control ${formErrors.phone && 'is-invalid'}`}
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  {formErrors.phone && <div className="invalid-feedback">{formErrors.phone}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="street" className="form-label">
                    Street
                  </label>
                  <input
                    type="text"
                    className={`form-control ${formErrors.street && 'is-invalid'}`}
                    id="street"
                    name="street"
                    value={formData.street}
                    onChange={handleChange}
                  />
                  {formErrors.street && <div className="invalid-feedback">{formErrors.street}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className={`form-control ${formErrors.city && 'is-invalid'}`}
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                  {formErrors.city && <div className="invalid-feedback">{formErrors.city}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="zip" className="form-label">
                    Zip Code
                  </label>
                  <input
                    type="text"
                    className={`form-control ${formErrors.zip && 'is-invalid'}`}
                    id="zip"
                    name="zip"
                    value={formData.zip}
                    onChange={handleChange}
                  />
                  {formErrors.zip && <div className="invalid-feedback">{formErrors.zip}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="country_id" className="form-label">
                    Country
                  </label>
                  {/* <input
                    type="text"
                    className={`form-control ${formErrors.country_id && 'is-invalid'}`}
                    id="country_id"
                    name="country_id"
                    value={formData.country_id}
                    onChange={handleChange}
                  /> */}
                  <select
                    className={`form-select ${formErrors.country_name && 'is-invalid'}`}
                    id="country_id"
                    name="country_id"
                    defaultValue={formData.country_id}
                    onChange={handleChange}
                  >
                    {Object.keys(countries).map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                  {formErrors.country_id && <div className="invalid-feedback">{formErrors.country_id}</div>}
                </div>
                {/* <div class="modal-footer"> */}
                <button type="submit" className="btn btn-primary btn-success marginRight" >
                  Register
                </button>
                <button type="button" className="btn btn-secondary btn-remove" data-bs-dismiss="modal">
                  Cancel
                </button>
                {/* </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};



export default RegistrationComponent;

export function addRegistrationComponent() {
  const root = document.getElementById('registrationModal');
  const reactRoot = createRoot(root);
  reactRoot.render(<RegistrationComponent />);
}

