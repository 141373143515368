import { odooApi } from "../utils/apiRequest/request";
import { removeLoader, showLoader } from "../utils/loaders/loader";
import { customAlert, customAlertWithLOkLink } from "../utils/utils";
import { environmentVariable } from "../variables";

export function setCartData(params) {
    let threeViewer = params.threeViewer

    let addToCartName = "Proceed to Procurement"
    let addToCartData = {}
  
    if (params.urlParams.get("model")) {
      
      addToCartName = "Add to Cart";
  
      addToCartData["isAddToCart"] = true
      addToCartData["productName"] = params.urlParams.get("productname")
      addToCartData["modelUrl"] = params.urlParams.get("model")
      addToCartData["price"] = params.urlParams.get("price")
  
      let editFaceEle  = document.getElementById("sketchBtn")
      editFaceEle.classList.add("menuDisable")
      
    } else {
      
      addToCartName = "Proceed to Procurement";
      
      addToCartData["isAddToCart"] = false
      addToCartData["productName"] = undefined
      addToCartData["modelUrl"] = undefined
      addToCartData["price"] = 0
  
    }
  
    threeViewer.addToCart = addToCartData
  
    let eles = document.getElementsByClassName("addToCartText")
    Array.from(eles).forEach(ele => {
      ele.innerHTML = addToCartName
    })
  
}

export async function addToCart(params) {

    let threeViewer = params.threeViewer

    showLoader()

    let productName,salesOrderUrl,partQuantitiesValue,mainAssemblyFilePath,partnerName,gPrice

    productName = threeViewer.addToCart.productName 
    salesOrderUrl = "create_sales_order_inq"
    partQuantitiesValue = 1
    gPrice = threeViewer.addToCart.price
    mainAssemblyFilePath = threeViewer.addToCart.modelUrl
    partnerName = threeViewer.user.name
    
    if (environmentVariable.development) mainAssemblyFilePath = "https://www.w3schools.com/images/img_certification_up_generic_html_300.png"

    let salesOrderBody = {
        "partner_name": partnerName,
        "file_path": mainAssemblyFilePath,
        "order_lines": [
            {
                "product_name": productName,
                "quantity": partQuantitiesValue,
                "unit_price": gPrice,
            }
        ]
    }
    console.log(salesOrderBody,"salesOrderBody")
    let salesOrderData
    try {

        salesOrderData = await odooApi.post(salesOrderUrl,
            salesOrderBody
        )
        console.log(salesOrderData, "salesOrderData")

    } 
    
    catch (error) {console.log(error,"error from add to cart api");}
    
    removeLoader()

    // Handle sales order res/error.
    if (!salesOrderData) {
        customAlert("Something went wrong, please try again later")
        return false
    }
    if (salesOrderData.error) {
        customAlert(salesOrderData.error)
        return false
    }
    else 
    customAlertWithLOkLink("Quotation has been created kindly accept to confirm the order.", "Go to Quotation", "http://120.72.89.249:8069/my/quotes")        

    return {salesOrderData}

}