import { getFacesFromShape } from "./utils/ocUtils";
import { PartTree } from "./threeCustom/partTree/partTree";
import { DCAssembly } from "./threeCustom/sketch/DCAssembly";
import { customAlert } from "./utils/utils";
import { removeObjWithChildren } from "./utils/customUtils";
import { removeDrawingObjects } from "./utils/commonFunction";
const loadFileAsync = (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsText(file);
    })
    .catch(error => console.error('Error:', error));
}

const loadSTEPorIGES = async (openCascade, inputFile, threeViewer) => {
    return await loadFileAsync(inputFile).then(async (fileText) => {
        const fileType = (() => {
            switch (inputFile.name.toLowerCase().split(".").pop()) {
                case "step":
                case "stp":
                    return "step";
                case "iges":
                case "igs":
                    return "iges";
                default:
                    return undefined;
            }
        })();
        await updatePercentInInputFileLoader(20)
        // Writes the uploaded file to Emscripten's Virtual Filesystem
        openCascade.FS.createDataFile("/", `file.${fileType}`, fileText, true, true);

        // Choose the correct OpenCascade file parsers to read the CAD file
        var reader = null;
        if (fileType === "step") {
            reader = new openCascade.STEPCAFControl_Reader_1();
        } else if (fileType === "iges") {
            reader = new openCascade.IGESCAFControl_Reader_1();
        } else { 
            console.error("opencascade.js can't parse this extension! (yet)");
     }
        const readResult = reader.ReadFile(`file.${fileType}`);            // Read the file
        if (readResult === openCascade.IFSelect_ReturnStatus.IFSelect_RetDone) {
            console.log("file loaded successfully!     Converting to OCC now...");
            let fileName = inputFile.name
            if (reader.NbRootsForTransfer() > 0) {
                await updatePercentInInputFileLoader(45)
                console.log(`Loaded ${reader.NbRootsForTransfer()} shapes from ${fileName}`);
                const doc = new openCascade.TDocStd_Document(new openCascade.TCollection_ExtendedString_1());

                if (fileType === "step"){
                    if (!reader.Transfer_1(new openCascade.Handle_TDocStd_Document_2(doc), new openCascade.Message_ProgressRange_1())) {
                        console.error(`Error transferring shapes from ${fileName}`);
                    }
                }else if (fileType === "iges"){
                    if (!reader.Transfer(new openCascade.Handle_TDocStd_Document_2(doc), new openCascade.Message_ProgressRange_1())) {
                        console.error(`Error transferring shapes from ${fileName}`);
                    }
                }
                await updatePercentInInputFileLoader(55)
                let shape1 = openCascade.XCAFDoc_DocumentTool.ShapeTool(doc.Main()).get()
                let objectTree = traverseAssembly(openCascade, shape1 )
                await updatePercentInInputFileLoader(80)
                console.log(objectTree,"objectTree")
                
                // START : NOW CREATE STEP SHAPE IN THE THREE.JS SCENE

                // Out with the old, in with the new!
                threeViewer.scene.remove(threeViewer.scene.getObjectByName("shape"));
                threeViewer.scene.remove(threeViewer.scene.getObjectByName("referenceShape"));
                removeDrawingObjects(threeViewer.scene)
                let threeJsGeometry = new DCAssembly({openCascade,assemblyTree:objectTree,threeViewer,shapeLayer:1,shapeName:"referenceShape",originalName:"referenceShape"}) // THIS ASSEMBLY IS ONLY FOR DRAWING SKETCH & CLICK FACE WITH LAYER 1
                await updatePercentInInputFileLoader(90)
                let threeJsGeometry2 = new DCAssembly({openCascade,assemblyTree:objectTree,threeViewer,shapeLayer:6,shapeName:"shape",originalName:"shape"}) // THIS ASSEMBLY IS ONLY FOR CUT HOLE & HOVER FACE WITH LAYER 6
                threeJsGeometry.isCutShapeVisible = true // Set the default for referenceShape that cutShape is visible
                threeViewer.scene.add(threeJsGeometry)
                threeViewer.scene.add(threeJsGeometry2)
                let partTree = new PartTree(threeJsGeometry,threeViewer)
                partTree.init()
                await updatePercentInInputFileLoader(100)
                // END : NOW CREATE STEP SHAPE IN THE THREE.JS SCENE

                console.log(inputFile.name + " triangulated and added to the scene!");
    
                // Remove the file when we're done (otherwise we run into errors on reupload)
                openCascade.FS.unlink(`/file.${fileType}`);
                console.log(inputFile.name + " converted successfully!  Triangulating now...");
                await updatePercentInInputFileLoader(0)
                return {proceed:true,doc,shapeTool : shape1, threeJsShape : threeJsGeometry}
                
            } else {
                console.error(`No shapes found in ${fileName}`);
                customAlert("No shapes found.")
                await updatePercentInInputFileLoader(0)
                return {proceed : false}
            }

        } else {
            console.error("Something in OCCT went wrong trying to read " + inputFile.name);
            customAlert("Step type file is invalid")
            return {proceed : false}
        }
        await updatePercentInInputFileLoader(0)
    });
};
export const loadSTEPorIGESInScene = async (openCascade, inputFile, parent,shapeName) => {
    return await loadFileAsync(inputFile).then(async (fileText) => {
        const fileType = (() => {
            switch (inputFile.name.toLowerCase().split(".").pop()) {
                case "step":
                case "stp":
                    return "step";
                case "iges":
                case "igs":
                    return "iges";
                default:
                    return undefined;
            }
        })();
        // Writes the uploaded file to Emscripten's Virtual Filesystem
        openCascade.FS.createDataFile("/", `file.${fileType}`, fileText, true, true);

        // Choose the correct OpenCascade file parsers to read the CAD file
        var reader = null;
        if (fileType === "step") {
            reader = new openCascade.STEPCAFControl_Reader_1();
        } else if (fileType === "iges") {
            reader = new openCascade.IGESControl_Reader_1();
        } else { 
            console.error("opencascade.js can't parse this extension! (yet)");
     }
        const readResult = reader.ReadFile(`file.${fileType}`);            // Read the file
        if (readResult === openCascade.IFSelect_ReturnStatus.IFSelect_RetDone) {
            console.log("file loaded successfully!     Converting to OCC now...");
            let fileName = inputFile.name
            if (reader.NbRootsForTransfer() > 0) {
                console.log(`Loaded ${reader.NbRootsForTransfer()} shapes from ${fileName}`);
                const doc = new openCascade.TDocStd_Document(new openCascade.TCollection_ExtendedString_1());
                if (!reader.Transfer_1(new openCascade.Handle_TDocStd_Document_2(doc), new openCascade.Message_ProgressRange_1())) {
                    console.error(`Error transferring shapes from ${fileName}`);
                }
                let shape1 = openCascade.XCAFDoc_DocumentTool.ShapeTool(doc.Main()).get()
                
                let objectTree = traverseAssembly(openCascade, shape1 )
                console.log(objectTree,"objectTree")
                // START : NOW CREATE STEP SHAPE IN THE THREE.JS SCENE

                // Out with the old, in with the new!
                parent.remove(shapeName);

                let threeJsGeometry = new DCAssembly({openCascade,assemblyTree:objectTree,threeViewer: parent,shapeLayer:3,shapeName:shapeName,originalName:shapeName,DCShapeLayer : 3}) // THIS ASSEMBLY IS ONLY FOR DRAWING SKETCH & CLICK FACE WITH LAYER 1
                threeJsGeometry.isCutShapeVisible = true // Set the default for referenceShape that cutShape is visible

                parent.add(threeJsGeometry)

                // END : NOW CREATE STEP SHAPE IN THE THREE.JS SCENE

                console.log(inputFile.name + " triangulated and added to the scene!");
    
                // Remove the file when we're done (otherwise we run into errors on reupload)
                openCascade.FS.unlink(`/file.${fileType}`);
                console.log(inputFile.name + " converted successfully!  Triangulating now...");

                return {proceed:true,doc,shapeTool : shape1, threeJsShape : threeJsGeometry}
            } else {
                console.error(`No shapes found in ${fileName}`);
                customAlert("No shapes found.")
                return {proceed : false}
            }

        } else {
            console.error("Something in OCCT went wrong trying to read " + inputFile.name);
            customAlert("Step type file is invalid")
            return {proceed : false}
        }
    });
};

function jsStringFromAsciiString(asciiString) {
    let myJsString = "";
    for (let i = 1; i <= asciiString.Length(); i++) {
        myJsString += String.fromCharCode(asciiString.Value(i));
    }
    return myJsString;
}

const traverseLabel = (oc, label, inMatrix) => {
    const att = new oc.Handle_TDF_Attribute_2(new oc.TDataStd_Name());
    label.FindAttribute_1(oc.TDataStd_Name.GetID(), att);
    // @ts-ignore
    let ascii13 = new oc.TCollection_AsciiString_13(att.get().Get(), 0)
    const name = jsStringFromAsciiString(ascii13)
    const shape = new oc.XCAFDoc_ShapeTool.GetShape_2(label)
    const faces = getFacesFromShape(oc, shape)
    const components = new oc.TDF_LabelSequence_1();
    const color = getColor(oc, label)
    let facesColor = getFacesColor(oc, label, faces)

    let matrix = inMatrix ? inMatrix : getShapeMatrix(shape)
    
    if (oc.XCAFDoc_ShapeTool.GetComponents(label, components, false)) {
        const children = [];
        for (let i = 1; i <= components.Length(); i++) {
            children.push(traverseLabel(oc, components.Value(i)));
        }
        return {
            name,
            children,
            matrix
        }
    } else {
        const referredShapeLabel = new oc.TDF_Label();
        if (oc.XCAFDoc_ShapeTool.GetReferredShape(label, referredShapeLabel)) {
            return traverseLabel(oc, referredShapeLabel, matrix);
        } else {
            return { name, shape, faces, color, matrix, facesColor };
        }
    }
}

const traverseAssembly = (oc, assembly) => {
    const freeShapes = new oc.TDF_LabelSequence_1();
    assembly.GetFreeShapes(freeShapes);
    const children = [];
    for (let i = 1; i <= freeShapes.Length(); i++) {
        children.push(traverseLabel(oc, freeShapes.Value(i)));
    }
    if (children.length == 1 ) {
      return children[0]
    }
    else{
        let multipleAsm = {name: 'Assembly', children: children, matrix: new THREE.Matrix4()}
        return multipleAsm
    }
}
const getColor = (oc, label) => {
    const colorTool = oc.XCAFDoc_DocumentTool.ColorTool(label).get()
    // Get the color of the shape
    const color = new oc.Quantity_Color_1();
    let threeColor
    if (colorTool.GetColor_4(label, oc.XCAFDoc_ColorType.XCAFDoc_ColorSurf, color)) {
        let red = color.Red()
        let green = color.Green()
        let blue = color.Blue()
        threeColor = new THREE.Color(red, green, blue )
    }
    return threeColor
}

const getFacesColor = (oc, label, faces) => {
    let facesColor = []
    const colorTool = oc.XCAFDoc_DocumentTool.ColorTool(label).get()
    const faceColor = new oc.Quantity_Color_1();
    let threeColor

    for (let i = 0; i < faces.length; i++) {
        const face = faces[i];
        if (colorTool.GetColor_7(face, oc.XCAFDoc_ColorType.XCAFDoc_ColorSurf, faceColor)) {
            let red = faceColor.Red()
            let green = faceColor.Green()
            let blue = faceColor.Blue()
            threeColor = new THREE.Color(red, green, blue )
            facesColor.push(threeColor)

        }else {
            threeColor = null
            facesColor.push(threeColor)
        }
    }
    return facesColor
}
export function getShapeMatrix(shape) {

    let position = shape.Location_1().Transformation().TranslationPart();
    
    let rotation = shape.Location_1().Transformation().GetRotation_2();
    
    let euler = new THREE.Euler();
    euler.setFromQuaternion(new THREE.Quaternion(rotation.X(), rotation.Y(), rotation.Z(), rotation.W()));
    let rotationMatrix = new THREE.Matrix4();
    rotationMatrix.makeRotationFromEuler(euler);

    let transformMatrix = new THREE.Matrix4();
    transformMatrix.makeTranslation(position.X(), position.Y(), position.Z());
    transformMatrix.multiply(rotationMatrix);
    return transformMatrix
}

export function applyMatrix4TShape(oc, inShape, inMatrix) {

    let rotationMat = new THREE.Matrix4();
    rotationMat.extractRotation(inMatrix)

    let threeQuaternion = new THREE.Quaternion();
    threeQuaternion.setFromRotationMatrix(rotationMat);

    let ocQuaternion = new oc.gp_Quaternion_2(threeQuaternion.x, threeQuaternion.y, threeQuaternion.z, threeQuaternion.w);

    const translationTransform = new ocGlobal.gp_Trsf_1();

    translationTransform.SetRotation_2(ocQuaternion);

    let position = new oc.gp_Vec_4(inMatrix.elements[12], inMatrix.elements[13], inMatrix.elements[14])

    translationTransform.SetTranslationPart(position);

    let x = new ocGlobal.TopLoc_Location_2(translationTransform)
    let shape = inShape.Moved(x, true);
    getShapeMatrix(shape)
    return shape
}

function applyTransformation(inShape, inX, inY, inZ) {
    let position = new ocGlobal.gp_Vec_4(inX, inY, inZ);


    const translationTransform = new ocGlobal.gp_Trsf_1();
    translationTransform.SetTranslation_1(position);

    let x = new ocGlobal.TopLoc_Location_2(translationTransform)
    let shape = inShape.Moved(x, true);
    return shape
}

async function updatePercentInInputFileLoader(percent){
    let ele = document.getElementById("modelLoadPercentage")
    if (!ele) {
        console.warn("modelLoadPercentage ele not found")
        return
    }
    await sleep(0)
    ele.innerHTML = percent
}
function sleep(inMs) {
    return new Promise(resolve => setTimeout(resolve, inMs));
}
export { loadSTEPorIGES };
