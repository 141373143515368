import { nodeApi, odooApi } from "../utils/apiRequest/request.js";
import { checkMessage } from "./addToCartHandlers.js";

export class BackendApiCall {
    constructor() {
        let quantityEle = document.getElementById("partQuantities");
        let val = quantityEle.value;
        let quantity = Number(val);
        this.mainAssemblyCount = quantity;
    }
    async createBom(apiMakerData, mainAssemblyName) {
        let scope = this;

        // create part bomLines
        let bomLines = [];

        for (const [key, partData] of Object.entries(apiMakerData)) {

            let { counts, engineeringCode } = partData;
            bomLines.push({
                "partnumber": engineeringCode,
                "product_qty": counts
            });

        }

        if (bomLines.length > 0) {
            let { bomData } = await this.apiCreateBom(mainAssemblyName, bomLines);
            if (!checkMessage(bomData)) return false;
            else return true;
        }

    }

    async apiCreateBom(mainAssemblyName, bomLines) {
        let scope = this;
        let bomBody = {
            "main_product_name": mainAssemblyName,
            "product_qty": scope.mainAssemblyCount,
            "bom_lines": bomLines
        };
        let bomData = await odooApi.post('create_bom',
            bomBody
        );
        console.log(bomData, "bomData", bomBody);
        return { bomData };
    }

    async getEngineeringCodeAssembly(assemblyBody) {
        // get engineering code for main assembly
        let engineeringCode;
        console.log(assemblyBody, "assemblyBody");
        let assemblyData = await nodeApi.post('add-to-cart/generate-assembly-shape', assemblyBody);

        console.log(assemblyData, "assemblyData");
        let mainAssemblyCode = "CFG00000";
        let mainAssemblyId = assemblyData.data[0].id;
        engineeringCode = mainAssemblyCode + mainAssemblyId;
        return { engineeringCode, mainAssemblyId };
    }
}
