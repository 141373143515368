export let customShapeTestInit = 
[
    {
        "type"    : "circle",
        "center"  : {x:0,y:0,z:0},
        "radius"  : 2,
    },
    {
        type : "polyLine",
        points : [
            {
                type     :"line",
                position : {x:0,y:0,z:0},
            },
            {
                type     :"arch",
                position :{x:3,y:0,z:0},
                radius   : 2,
            },
            {
                type     :"semiCircle",
                position :{x:60,y:0,z:0},
                side     : "upWord" // upWord/inWord
            },
            {
                type     :"semiEllipse",
                position :{x:60,y:0,z:0},
                side     : "inWord" // upWord/inWord
            },
        ]
    },
    {
        "type"  : "rectangle",
        "width" : 1,
        "height" : 1,
        "center": {x:8,y:0,z:0},
        "rotation" : 0,
    },
]

export let customShapeTest2 =  // DOCS
[
    {
        "type"    : "circle",
        "center"  : {x:-10,y:0,z:0},
        "radius"  : 5,
    },
    {
        type : "polyLine",
        points : [
            {
                type :"line",
                start: {x:0,y:0,z:0},
                end  : {x:3,y:0,z:0},
            },
            {
                type  :"arch",
                start :{x:3,y:0,z:0},
                end   :{x:30,y:0,z:0},
                radius: 6,
            },
            {
                type :"semiCircle",
                start:{x:30,y:0,z:0},
                end  :{x:40,y:0,z:0},
                theta : "0-180" // "0-180" "180-360"
            },
            {
                type:"semiEllipse",
                start:{x:40,y:0,z:0},
                end  :{x:0,y:0,z:0},
                theta:"180-360"// "0-180" "180-360"
            },
        ]
    },
    {
        "type"  : "rectangle",
        "width" : 1,
        "height": 1,
        "center": {x:8,y:0,z:0},
        "rotation" : 0,
    },
]

export let customShapeTest = 
[
    { // profile
        "type"    : "circle",
        "center"  : {x:25,y:0,z:0},
        "radius"  : 5,
    },
    { // profile
        "type"    : "ellipse",
        "center"  : {x:45,y:0,z:0},
        "radiusX" : 3,
        "radiusY" : 2,
    },
    { // profile
        type : "polyShape",
        points : [
            {
                type :"line",
                start: {x:0,y:0,z:0},
                end  : {x:3,y:0,z:0},
            },
            {
                type :"line",
                start: {x:3,y:0,z:0},
                end  : {x:3,y:5,z:0},
            },
            {
                type  :"arch",
                start :{x:3,y:5,z:0},
                end   :{x:0,y:0,z:0},
                radius: 5,
            },
            {
                type :"semiCircle",
                start:{x:30,y:0,z:0},
                end  :{x:40,y:0,z:0},
                theta : "0-180" // "0-180" "180-360"
            },
            {
                type:"semiEllipse",
                start:{x:40,y:0,z:0},
                end  :{x:0,y:0,z:0},
                theta:"180-360"// "0-180" "180-360"
            },
        ]
    },


]

export let customShapeTest3 = 
[
    {
        "type"  : "circle",
        "center": {x:0,y:0,z:0},
        "radius": 2,
    },
    {
        type : "polyLine",
        points : [
            {
                type:"line",
                position:{x:3,y:0,z:0}
            },
            {
                type:"line",
                position:{x:3,y:3,z:0}
            },
            {
                type:"line",
                position:{x:6,y:3,z:0}
            },
            {
                type:"line",
                position:{x:6,y:0,z:0}
            }
        ]
    },
    {
        "type"  : "circle",
        "center": {x:8,y:0,z:0},
        "radius": 2,
    },
]

export let shapePlus = 
[
    { // profile
        type : "polyShape",
        points : [
            {
                type :"line",
                start: {x:0,y:0,z:0},
                end  : {x:10,y:0,z:0},
            },
            {
                type :"line",
                start: {x:10,y:0,z:0},
                end  : {x:10,y:10,z:0},
            },
            {
                type  :"line",
                start :{x:10,y:10,z:0},
                end   :{x:20,y:10,z:0},
            },
            {
                type :"line",
                start:{x:20,y:10,z:0},
                end  :{x:20,y:20,z:0},
            },
            {
                type:"line",
                start:{x:20,y:20,z:0},
                end  :{x:10,y:20,z:0},
            },
            {
                type:"line",
                start:{x:10,y:20,z:0},
                end  :{x:10,y:30,z:0},
            },
            {
                type:"line",
                start:{x:10,y:30,z:0},
                end  :{x:0,y:30,z:0},
            },
            {
                type:"line",
                start:{x:0,y:30,z:0},
                end  :{x:0,y:20,z:0},
            },
            {
                type:"line",
                start:{x:0,y:20,z:0},
                end  :{x:-10,y:20,z:0},
            },
            {
                type:"line",
                start:{x:-10,y:20,z:0},
                end  :{x:-10,y:10,z:0},
            },
            {
                type:"line",
                start:{x:-10,y:10,z:0},
                end  :{x:0,y:10,z:0},
            },
            {
                type:"line",
                start:{x:0,y:10,z:0},
                end  :{x:0,y:0,z:0},
            },
        ]
    },


]
export let keyHole = 
[
    { // profile
        type : "polyShape",
        points : [
            {
                type :"line",
                start: {x:0,y:0,z:0},
                end  : {x:10,y:0,z:0},
            },
            {
                type :"arch",
                start: {x:10,y:0,z:0},
                end  : {x:10,y:10,z:0},
                radius: 5,
            },
            {
                type  :"line",
                start :{x:10,y:10,z:0},
                end   :{x:0,y:10,z:0},
            },
            {
                type :"arch",
                start:{x:0,y:10,z:0},
                end  :{x:0,y:0,z:0},
                radius: 10,
            }
        ]
    },

]

export let shapeMounting = 
[
    { // profile
        "type"    : "circle",
        "center"  : {x:-10,y:10,z:0},
        "radius"  : 4,
    },
    { // profile
        "type"    : "circle",
        "center"  : {x:20,y:10,z:0},
        "radius" : 4,
    },
    { // profile
        type : "polyShape",
        points : [
            {
                type :"line",
                start: {x:0,y:0,z:0},
                end  : {x:10,y:0,z:0},
            },
            {
                type :"line",
                start: {x:10,y:0,z:0},
                end  : {x:10,y:20,z:0},
            },
            {
                type  :"line",
                start :{x:10,y:20,z:0},
                end   :{x:0,y:20,z:0},
            },
            {
                type :"line",
                start:{x:0,y:20,z:0},
                end  :{x:0,y:0,z:0},
            },
        ]
    },


]
export let compactFilteredPowerEntryModule = 
[
    { // profile
        "type"    : "circle",
        "center"  : {x:-10,y:12.5,z:0},
        "radius"  : 2,
    },
    { // profile
        "type"    : "circle",
        "center"  : {x:20,y:12.5,z:0},
        "radius" : 2,
    },
    { // profile
        type : "polyShape",
        points : [
            {
                type :"line",
                start: {x:0,y:0,z:0},
                end  : {x:10,y:0,z:0},
            },
            {
                type :"line",
                start: {x:10,y:0,z:0},
                end  : {x:10,y:25,z:0},
            },
            {
                type  :"line",
                start :{x:10,y:25,z:0},
                end   :{x:0,y:25,z:0},
            },
            {
                type :"line",
                start:{x:0,y:25,z:0},
                end  :{x:0,y:0,z:0},
            },
        ]
    },


]