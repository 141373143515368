import { nodeApiV2 } from "../utils/apiRequest/requestV2"

export function initJsHtml(){
    setLibraryShapeDropdown()
}

async function setLibraryShapeDropdown(){
    let cutOutLibraryEle = document.getElementById("cutOutLibrary")
    let resData = await nodeApiV2.get("admin/panel/library_shape")
    let html = ""
    if (resData.status === 200){
        let objs = resData.res
        objs.forEach(obj => {
            html += `<option value="customSTPShape" nm = "${obj.file_name}">${obj.shape_name}</option>`
        });
    }
    cutOutLibraryEle.insertAdjacentHTML('beforeend', html);
}