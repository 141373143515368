import $ from 'jquery';
import { environmentVariable } from '../../variables';
import { getEntityName } from '../../utils/commonFunction';
import { updateCartPrice } from '../../views/addToCartHandlers';
import { removeLoader, showLoader } from '../../utils/loaders/loader';
import { objToStl } from '../stlExporter/stlExporter';
import axios from 'axios';
import { nodeApi } from '../../utils/apiRequest/request';

export class PartTree{
    constructor(object,threeViewer) {
        this.object = object;
        this.threeViewer = threeViewer;
    }
    init(){
        this.parseScene(this.object);
        this.assignEvent();
    }
    parseScene(object) {
        var h = this.check(object);
        $('#partTree').html(h);
    }
    check(obj) {
        let html = '';
        let name = obj.name ? obj.name : obj.type;
        if (obj.name == "referenceShape"){
            let stepName = obj.stepName
            if (stepName) name = stepName 
        } 
            html +='<div class="node">'
            html += `<div class="ntitle treeNode" objId="${obj.id}" > ` + name + '</div>';
            html += `
                    <img 
                        id      = "eye-${obj.id}" 
                        objSequenceName   = "${obj.sequenceName}"
                        checked = "true"
                        class   = "eyeImg" 
                        src     = "./assets/img/partTree/eye.svg" 
                        alt     = "Display"
                    >
                    `
        
        if (obj.children) {
            html += `<div class="children ${obj.name=="shape"? "show" :"hide"}">`;
            for (var i = 0; i < obj.children.length; i++) {
                let cObj = obj.children[i]
                if (cObj.name == "DCFace"){
                    html+= this.addDCEntities(cObj)
                }else if (cObj.name !== "shapeBorder"){
                    html += this.check(cObj)
                }
            }
            html += '</div>';
        }
        html += "</div>";
        return html;
    }
    addDCEntities(obj) {
        let html = '';
        let DCEntities = obj.getObjectsByProperty("customType", "DCEntity")
        if (DCEntities.length > 0) {
            let name = obj.name ? obj.name : obj.type;

            html += '<div class="node">'
            html += `<div class="ntitle treeNode" objId="${obj.id}" > ` + "Sketch entities" + '</div>';

            html += `<div class="children ${obj.name == "shape" ? "show" : "hide"}">`;
            for (let i = 0; i < DCEntities.length; i++) {
                let entity = DCEntities[i];
                html += this.oneDropDown(entity)
            }

            html += '</div>';

            html += "</div>";
        }
        return html;        
    }
    oneDropDown(obj){
        let html = '';
        let name = obj.sketchType ? obj.sketchType : obj.type;
        html +='<div class="node">'
            html += `<div class="ntitle treeNode" objId="${obj.id}" > ` + `${name.toUpperCase()} ${getEntityName(obj.entityType)}`  + '</div>';
        html += "</div>";
        return html;

    }
    assignEvent() {
        let scope = this
        // ARROW UP-DOWN EVENTS
        $('.ntitle').click( function () {
            var p = $(this);
            var c = p.parent().find('.children:first')
            if (c.hasClass('hide')) {
                c.removeClass('hide');
                p.addClass('open');
                // let objId = Number(p[0].getAttribute("objId"))
            }
            else {
                c.addClass('hide');
                p.removeClass('open');
            }
        })
        // OBJECT SELECT EVENT
        let eyeEles = document.getElementsByClassName("eyeImg")
        Array.from(eyeEles).forEach((eye)=>{
            eye.addEventListener("click",(e)=> this.eyeClickEvent(e,eye))
        })

    }
    eyeClickEvent(e,eye){
        let isChecked = eye.getAttribute("checked")
        let sequenceName = eye.getAttribute("objSequenceName")
        let count = sequenceName.split("_referenceShape")[0]
        let cutShapeName = `${count}_shape`
        let obj = this.threeViewer.scene.getObjectByProperty("sequenceName", cutShapeName)
        let referenceShapeName = `${count}_referenceShape`
        let referenceObj = this.threeViewer.scene.getObjectByProperty("sequenceName", referenceShapeName)
        if (isChecked == "true"){
            if (obj) {
                obj.visible = false
                referenceObj.visible = false
                referenceObj.visiblePrevious = false
                if (obj.name === "shape") referenceObj.isCutShapeVisible = false
            }
            eye.setAttribute("checked", false)
            eye.src = "./assets/img/partTree/hide.svg"
        }else{
            if (obj) {
                obj.visible = true
                referenceObj.visible = true
                referenceObj.visiblePrevious = true
                if (obj.name === "shape") referenceObj.isCutShapeVisible = true
            }
            eye.setAttribute("checked", true)
            eye.src = "./assets/img/partTree/eye.svg"
        }
    }
}
 
export class PartTreeForAddParts{

    constructor(object ,htmlId, threeViewer) {
        this.htmlId = htmlId
        this.object = object;
        this.threeViewer = threeViewer;
        this.seq = 0
        this.ignoreParts = ["pcb","screw"]
        this.materialColors = {
            PLA: ["Black", "Blue", "White", "Orange", "Green", "Grey", "Red"],
            ABS: ["Black", "Blue", "White", "Orange", "Green", "Grey", "Red", "Natural"],
            PETG:["Black", "Blue", "Yellow", "White", "Natural"],
            ASA: ["Black", "White", "Orange", "Green"],
            TPU: ["Black", "Natural"],
            TPE: ["Natural"],
            proBlk10: ["Black"],
            tough60CWhite: ["White"],
            rigidGrey: ["Grey"],
            flexBlk20: ["Black"],
            toughClear: ["Clear"]
        };
        this.estimatedTime = 0
        this.addToCartData = {}
        this.currentAbbreviation = "fdm" 
        this.backendApi = new BackendApi()
        

    }
    async htmlForEstimationTime(){
        let estimation = await this.estimate()
        let t = estimation.estimatedPrintingTime
        let {days} = this.getEstimatedTotalTime(t)
        this.estimatedTime = days
        let leadTimeHtml = this.getLeadTimeHtml(this.estimatedTime.totalDays)
        this.estimatedTimeHtml = leadTimeHtml
        return leadTimeHtml
    }
    estimate() {
        return new Promise((resolve, reject) => {
            let threeViewer = this.threeViewer
            let estimateValues = {
                layerHeight: 0.1,
                inFill: 40,
            }
            let obj = threeViewer.scene.getObjectByName("shape")
            let stlFile = objToStl(obj)
            const data = new FormData();
            data.append('inputFile', stlFile);
            data.append('layerHeight', estimateValues.layerHeight);
            data.append('inFill', estimateValues.inFill);

            axios.post(`${environmentVariable.REACT_APP_DatabaseServer}printer/estimate`, data)
                .then((res) => {
                    let response = res.data
                    if (response.errorAtReadGcode) {
                        reject(response.errorAtReadGcode)
                        return
                    }
                    if (response.errorAtExecuteExe) {
                        reject(response.errorAtExecuteExe)
                        return
                    }
                    resolve(response.printerEstimate)
                    return {
                        estimation : response.printerEstimate
                    }
                });
        })
    }
    async parseScene(object) {
        let scope = this
        let estimationHtml = await this.htmlForEstimationTime()
        let cartMlTimeEle = document.getElementById("cartLeadTime")
        cartMlTimeEle.innerHTML = estimationHtml
        scope.addToCartData = await scope.backendApi.addToCartData()
        if (!scope.addToCartData) return
        var h = this.check(object);
        document.getElementById(this.htmlId).innerHTML = ""
        if (this.htmlId) $(`#${this.htmlId}`).html(h);
        else console.warn("html id is required")
        this.assignEvent();
    }
    getEstimatedTotalTime(t) {
        let aT = t.split(" ")

        let days = 1
        let time
        let length = aT.length
        let timeObject = {
            d: 0,
            h: 0,
            m: 0,
            s: 0,
        }
        for (let i = 0; i < length; i++) {
            let timeVar = aT[i];
            if (timeVar.includes("d")){
                timeObject["d"] = Number(timeVar.slice(0, -1))
            }
            else if (timeVar.includes("h")){
                timeObject["h"] = Number(timeVar.slice(0, -1))
            }
            else if (timeVar.includes("m")){
                timeObject["m"] = Number(timeVar.slice(0, -1))
            }
            else if (timeVar.includes("s")){
                timeObject["s"] = Number(timeVar.slice(0, -1))
            }
            else{
                timeObject["extra"] = timeVar
            }
        }

        days = this.getTotalDays(timeObject)
        
        return { days }
    }

    getTotalDays(time) {
        // It will add 20 hours 

        // add 20 hours for delivery
        let totalSeconds = ((time.d * 24 *3600 ) + (time.h + 20) * 3600) + (time.m * 60) + time.s;

        let totalDays = totalSeconds / (24 * 3600);
        let totalHours = totalSeconds / 3600

        return {totalDays,totalHours,totalSeconds}
    }
    getLeadTimeHtml(d) {
        let optionValue = {
            '1-2': 120, '3-4': 110, '5-7': 100, 'More than 7': 100
        }
        let days = ['1-2', '3-4', '5-7']
        let neededDays = []
        if (d > 7) {
            neededDays = neededDays.push("More than 7")
        }
        else if (d > 4) {
            neededDays = days.slice(2)
        }
        else if (d > 2) {
            neededDays = days.slice(1)
        }
        else if (d > 0) {
            neededDays = days.slice(0)
        }

        let html = ``
        neededDays.forEach((d) => {
            html += `<option value="${optionValue[d]}">${d} days</option>`
        })
        return html


    }
    check(obj) {
        let scope = this
        this.seq = this.seq + 1
        let html = '';
        let name = obj.name ? obj.name : obj.type;
        let quantityValue = 1
        let className = "partShape"
        let readOnlyHtml = "readonly"
        let isPartAssembly = true
        if (obj.name == "shape"){
            className = "mainAssembly"
            let stepName = obj.stepName
            if (stepName) name = stepName 
            quantityValue = 1
            readOnlyHtml = ""
            isPartAssembly = false
        } 
            html +='<div class="node nodeData">'
            html += `<div class="ntitle2 treeNode" objId="${obj.id}" > ` + name + '</div>';
            html +='<div class="abc">'
                html += `

                <div class="addToCartProperties addDropdownCart ${className}"  objSequenceName="${obj.sequenceName}" objOriginalName="${name}">


                    <select  class="form-select cartAbbreviation ${!isPartAssembly ? "visibilityHidden" : ""}" aria-label="Default select example " nm="cartAbbreviation">
                        <option value="fdm">FDM</option>
                        <option value="projectionPrinting">Projection Printing</option>
                    </select>

                   <div class="FDM ${!isPartAssembly ? "visibilityHidden" : ""}">
                        <select class="form-select aTCMaterials" id=mFDM_${this.seq} aria-label="Default select example " nm="cartMaterial" dbNm="fdm_material_rates">
                            ${scope.addToCartData.fdm_material_rates.map((obj,index) => {
                                    return `<option index=${index}  value="${obj.id}">${obj.material}</option>`;
                                })
                            }
                            })}
                        </select>
                        <select  class="form-select" aria-label="Default select example" nm="cartPrintQuality" dbNm="fdm_print_quality_rates">
                            ${scope.addToCartData.fdm_print_quality_rates.map((obj,index) => {
                                    return `<option index=${index} value=${obj.id}>${obj.thickness} ${obj.thickness_type}</option>`;
                                })
                            }
                        </select>
                    </div> 
                      
                    <div class="displayNone projectionPrinting ${!isPartAssembly ? "visibilityHidden" : ""}">
                        <select class="form-select aTCMaterials" id=mPP_${this.seq} aria-label="Default select example " nm="pCartMaterial" dbNm="pp_material_rates">
                            ${scope.addToCartData.pp_material_rates.map((obj,index) => {
                                    return `<option index=${index} value=${obj.id}>${obj.material}</option>`;
                                })
                            }
                        </select>

                        <select  class="form-select" aria-label="Default select example" nm="pLayerDeposition" dbNm="pp_print_quality_rates">
                            ${scope.addToCartData.pp_print_quality_rates.map((obj,index) => {
                                    return `<option index=${index} value="${obj.id}" >${obj.thickness} ${obj.thickness_type}</option>`;
                                })
                            }
                        </select>
                    </div>

                    <select  class="form-select cartInfill ${!isPartAssembly ? "visibilityHidden" : ""}" aria-label="Default select example" nm="cartInfill" dbNm="fdm_infill_rates">
                        ${scope.addToCartData.fdm_infill_rates.map((obj,index) => {
                                return `<option index=${index} value=${obj.id}>${obj.infill} %</option>`;
                            })
                        }
                    </select>

                    <select class="form-select partColorPicker ${!isPartAssembly ? "visibilityHidden" : ""}" id = "aTCC_${this.seq}" nm="partColor" dbNm="fdm_material_colors">
                        ${scope.addToCartData.fdm_material_rates[0].fdm_material_colors.map((obj,index) => {
                                return `<option index=${index} value=${obj.id}>${obj.name}</option>`;
                            })
                        }
                    </select>
                    
                    <input 
                        type="checkbox" 
                        class="checkPrint ${!isPartAssembly ? "visibilityHidden" : ""}" 
                        nm="checkPrint"
                    >

                </div>
                        `
            html +='</div>'
           
        
        if (obj.children) {
            html += `<div class="children ${obj.name=="shape"? "show" :"hide"}">`;
            for (var i = 0; i < obj.children.length; i++) {
                let cObj = obj.children[i]
                if (cObj.name == "DCFace"){
                    html+= this.addDCEntities(cObj)
                }else if (cObj.name !== "shapeBorder" && (!cObj.name.toLowerCase().includes("pcb") && !cObj.name.toLowerCase().includes("screw"))){
                    html += this.check(cObj)
                }
            }
            html += '</div>';
        }
        html += "</div>";
        return html;
    }
    addDCEntities(obj) {
        let html = '';
        let DCEntities = obj.getObjectsByProperty("customType", "DCEntity")
        if (DCEntities.length > 0) {
            let name = obj.name ? obj.name : obj.type;

            html += '<div class="node nodeData">'
            html += `<div class="ntitle2 treeNode" objId="${obj.id}" > ` + "Sketch entities" + '</div>';

            html += `<div class="children ${obj.name == "shape" ? "show" : "hide"}">`;
            for (let i = 0; i < DCEntities.length; i++) {
                let entity = DCEntities[i];
                html += this.oneDropDown(entity)
            }

            html += '</div>';

            html += "</div>";
        }
        return html;        
    }
    oneDropDown(obj){
        let html = '';
        let name = obj.sketchType ? obj.sketchType : obj.type;
        html +='<div class="node">'
            html += `<div class="ntitle2 treeNode" objId="${obj.id}" > ` + `${name.toUpperCase()} ${getEntityName(obj.entityType)}`  + '</div>';
        html += "</div>";
        return html;

    }
    async assignEvent() {
        let scope = this
        // ARROW UP-DOWN EVENTS
        $('.ntitle2').click( function () {
            var p = $(this);
            var c = p.parent().find('.children:first')
            if (c.hasClass('hide')) {
                c.removeClass('hide');
                p.addClass('open');
                // let objId = Number(p[0].getAttribute("objId"))
            }
            else {
                c.addClass('hide');
                p.removeClass('open');
            }
        })

        // Call this function whenever the add-cart's attributes change then update the pricing.
        await this.onChangeQuantity()
        this.onChangeEventAddToCart()
        this.onChangeEventCartAbbreviation()
        this.onChangePartColor()
        this.onChangeMaterial()

    }

    onChangeEventCartAbbreviation(){
        let scope = this
        let cartAbbreviation = document.getElementsByClassName("cartAbbreviation")
        Array.from(cartAbbreviation).forEach((ele)=>{
            ele.addEventListener("change",changeSelectOptions)
            function changeSelectOptions(){
                let val = ele.value
                scope.currentAbbreviation = val
                let parent = ele.parentNode
                let fdmEles = parent.getElementsByClassName("FDM"),fdmEle
                if (fdmEles) fdmEle = fdmEles[0]
                if (fdmEle) {
                    fdmEle.classList.add("displayNone")
                }
                let fdmEles2 = parent.getElementsByClassName("projectionPrinting"),fdmEle2
                if (fdmEles2) fdmEle2 = fdmEles2[0]
                if (fdmEle2) {
                    fdmEle2.classList.add("displayNone")
                }
                let cartInfills = parent.getElementsByClassName("cartInfill"),cartInfill
                if (cartInfills) cartInfill = cartInfills[0]
                if (val === "fdm") {
                    fdmEle.classList.remove("displayNone")
                    let materialsEles = fdmEle.getElementsByClassName("aTCMaterials")
                    Array.from(materialsEles).forEach((ele)=>{
                        scope.changeMaterialColorUseEle(ele)
                    });
                    if (cartInfill) {
                        cartInfill.classList.remove("menuDisable")
                    }
                }
                if (val === "projectionPrinting") {
                    fdmEle2.classList.remove("displayNone")
                    let materialsEles = fdmEle2.getElementsByClassName("aTCMaterials")
                    Array.from(materialsEles).forEach((ele)=>{
                        scope.changeMaterialColorUseEle(ele)
                    });
                    if (cartInfill) {
                        cartInfill.classList.add("menuDisable")
                    }
                }
            }
        })
    }
    onChangeEventAddToCart(){
        let cartInputs = document.getElementsByClassName("addToCartProperties")
        Array.from(cartInputs).forEach((section)=>{
            this.addEventToSectionInputs(section,updateCartPrice)
        })
        let cartLeadTimeEle = document.getElementById("cartLeadTime")
        if (!cartLeadTimeEle) console.warn("cartLeadTime not found")
        cartLeadTimeEle.addEventListener("change",async ()=>{
            await showLoader()
            await updateCartPrice()
            removeLoader()
        })
    }
    async onChangeQuantity(){
        let mainQuantityEle = document.getElementById("partQuantities")
        if (mainQuantityEle){
            mainQuantityEle.addEventListener("change",async (e)=>{
                e.preventDefault()
                await showLoader()
                let mainQuantityEle = document.getElementById("partQuantities")
                let mainValue = Number(mainQuantityEle.value)
                if (mainValue < 0){
                    mainValue = 0
                    mainQuantityEle.value = mainValue
                }
                if (mainValue > 100){
                    mainValue = 100
                    mainQuantityEle.value = mainValue
                }
                await updateCartPrice()
                removeLoader()
            })
        }else{
            console.warn("mainQuantityEle not found, can not add eventlistener")
        }
    }
    onChangePartColor() {
        let scope = this
        let partColorPickers = document.getElementsByClassName("partColorPicker")
        Array.from(partColorPickers).forEach((ele) => {
            ele.addEventListener("change", () => scope.changePartColorUseEle(ele))
        })

    }
    changePartColorUseEle(ele){
        let scope = this
        changeColors(ele)
        function changeColors(ele) {
            let parent = ele.parentNode
            let objSequenceName = parent.getAttribute("objSequenceName")
            let obj = scope.threeViewer.scene.getObjectByProperty("sequenceName", objSequenceName)
            if (ele.selectedIndex !== -1){
                const selectedOption = ele.options[ele.selectedIndex];

                let color = selectedOption.innerHTML
                color = color.replace(/\s/g, '');
                changeShapeObjColor(obj, color);
            }
        }
        function changeShapeObjColor(obj, color) {
            obj.traverse((o) => {
                if (o instanceof THREE.Mesh) {
                    if (o.material)
                        o.material.color = new THREE.Color(color)
                }
            })
        }
    }
    addEventToSectionInputs(section,eventFunction){ 
        // make sure in form all input tags must have tagName otherwise it will give error
        let inputs = section.getElementsByTagName("input");
        let textAreas = section.getElementsByTagName("textarea");
        let selects = section.getElementsByTagName("select");
        let tags = [inputs,selects,textAreas]
        tags.forEach(elements => {
            [...elements].forEach(element => {
                element.addEventListener("change", (e)=>{eventFunction()})
            });    
        });
        return null;
    }
    onChangeMaterial(){
        let scope = this

          
        let materialsEles = document.getElementsByClassName("aTCMaterials")
        Array.from(materialsEles).forEach((ele)=>{
            ele.addEventListener("change",()=>this.changeMaterialColorUseEle(ele))
        });
        // partColorPicker

    }

    changeMaterialColorUseEle(ele,changeColor = true){
        let scope = this
        let id = ele.id
        const selectedOption = ele.options[ele.selectedIndex];

        let index= selectedOption.getAttribute("index")
        let seq = id.split("_")[1]
        let colorId = `aTCC_${seq}`
        let colorEle = document.getElementById(colorId)
        // colorEle = parent.getElementsByClassName("partColorPicker")[0]
        let html = ''
        let val = Number(ele.value)
        
        let colors = scope.materialColors[val]
        if (!colors) colors = ["white"]
        let backendTable
        if (scope.currentAbbreviation == "fdm"){
            backendTable = "fdm_material_rates"
        }else{
            backendTable = "pp_material_rates"
        }
        let colorHtmlArray = scope.addToCartData[backendTable][index].fdm_material_colors.map((obj,index) => {
            return `<option  index=${index} value=${obj.id}>${obj.name}</option>`;
        })
        
        colorHtmlArray.forEach((c)=>{
            html += c
        })
        colorEle.innerHTML = html
        if (changeColor){
            scope.changePartColorUseEle(colorEle)
        }
    }


}
async function waitForDOMUpdate() {
    return new Promise((resolve) => {
      requestAnimationFrame(() => {
        resolve(); // Resolve the Promise when the next frame is rendered
      });
    });
  }

class BackendApi {
    constructor(){

    }
    async addToCartData(){
        let addToCartData = await nodeApi.get('admin/add-to-cart-data');
        return addToCartData
    }

}