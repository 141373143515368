import * as THREE from 'three'
import { DCPattern } from '../cuttingEntities/DCEntityGroups';
import { removeObjWithChildren } from '../../utils/customUtils';
export class DCSketcher {
    constructor(inThreeViewer) {
        this.mode = null
        this.entityMode = null;
        
        // sketch type is the type of sketch which are drawn of the plane it can be :: cutout, graphic, masking, reference, exclusion
        this.sketchType = "cutout" 

        this.currentEntity = null;
        this.currentDimensionData = {
            point1Circle: undefined,
            point2Circle: undefined,
            dimensionLine: undefined,
        };
        this.threeViewer = inThreeViewer

    }
    setCurrentFace(inFace) {
        this.currentFace = inFace;
    }
    getCurrentFace() {
        return this.currentFace;
    }
    removeEntity(inEntity) {
        /**
         * This function will remove the entity from the face
         * @param {DCEntity} inEntity
         * @param {DCPattern} inEntity
         * @returns {void}
         */
        if (!inEntity) return

        let scope = this;
        if (inEntity.customType == "DCEntity" || inEntity.name === "cutOutLibrary") {
            scope.currentFace.removeEntity(inEntity);
        } else if (inEntity.customType == "DCPattern"){
            scope.currentFace.removePattern(inEntity);
        }else if(inEntity.shapeName === "libraryShape"){
            removeObjWithChildren(inEntity)
        }
    }
    setSelectedEntity(inEntity) {
        this.selectedEntity = inEntity;
    }
    getSelectedEntity() {
        return this.threeViewer.transformControl.object
    }
}

export function createSketchPlane() {
    /**
     * This function creates a sketch plane
     * @returns {THREE.Mesh}
     * Plane will created at 0.1 z position for cutting
     */
    let plane = new THREE.PlaneGeometry(1000, 1000, 1, 1)
    let planeMesh = new THREE.Mesh(plane, new THREE.MeshPhongMaterial({
        color: 0x00ff00, side: THREE.DoubleSide,
    }))
    planeMesh.position.z = +0.1
    planeMesh.name = "sketchPlane"
    planeMesh.visible = false
    planeMesh.material.wireframe = true
    // Add ref axes
    let axes = new THREE.AxesHelper(50)
    planeMesh.add(axes)
    planeMesh.layers.set(2)
    return planeMesh
}