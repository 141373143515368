import { environmentVariable } from "../../../variables"

export let DCEntityData = () => {
    return {
        extrudeDepth: 1.5,
        engrave: false,
        getExtrudeDepth: function () {
            if (this.engrave) {
                return this.extrudeDepth
            } else {
                return environmentVariable.extrudeDepth
            }
        },

        notes: {
            values: [],
        }
    }
}