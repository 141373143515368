import * as THREE from 'three';
import { DCFace } from './DCFace';
export class DCShape extends THREE.Object3D {
    constructor() {
        super();
        this.customType = "DCShape";
        this.dcFaces = [];
    }
    getCustomType() {
        return this.customType;
    }
    getFaces() {
        return this.dcFaces;
    }
    getFace(inFaceId) {
        // TODO: check better way to find face
        return this.dcFaces[inFaceId];
    }
    addFace(inFace) {
        this.dcFaces.push(inFace);
        this.add(inFace);
    }
    getAllFaces() {
        let faces = []
        for (let i = 0; i < this.dcFaces.length; i++) {
            if (this.dcFaces[i] instanceof DCFace) {
              faces.push(this.dcFaces[i])
            }
        }
        return faces;
    }
}