import { odooApiV2 } from "../utils/apiRequest/requestV2";
import { removeLoader, showLoader } from "../utils/loaders/loader";
import { getSubmitFormData } from "../utils/submitData";
import { customAlert } from "../utils/utils";
import { checkMessageV2 } from "./addToCartHandlers";

export class FeedBackInit{

    constructor(){
        this.init()
    }

    init(){
        this.events()
    }

    events(){
        let submitBtnEle = document.getElementById("contactform_feedback")
        submitBtnEle.addEventListener("submit",(event)=>this.handleSubmit(event))
    }

    async handleSubmit(e){
        let scope = this
        e.preventDefault();
        showLoader()
        let submitData = getSubmitFormData("contactform_feedback")
        console.log(submitData,"submitData")
        let userName = threeViewer.user.name 
        let nameMergerdDescription = userName

        let taskName = submitData["name_feedback"]
        let description = submitData["description_feedback"]

        // add description in new line in mergedDescrviptiojn
        nameMergerdDescription = nameMergerdDescription + " \n " + description

        let submitApiData = {
            "name": taskName,
            "description": nameMergerdDescription,
            "project_name": "Website Feedback"
        }

        console.log(submitApiData)

        let postFeedback = await scope.postData(submitApiData)
        removeLoader()
        if (postFeedback){
            customAlert("Thank you for your feedback!")
        }else{
            customAlert("something went wrong !")
        }

    }


    async postData(postData){
        let postFeedback = await odooApiV2.post("insert_task", postData);
        console.log(postFeedback,"postFeedback Server");
        if (!checkMessageV2(postFeedback)){
            return
        }else return true
    }

}