import * as THREE from 'three';

export function addLights(scene, camera) {
    // Add Lights
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
    ambientLight.layers.enableAll();
    scene.add(ambientLight)

    const light = new THREE.PointLight(0xffffff, 0.8)
    light.layers.enableAll();
    camera.add(light)
}