import { detachTransControlAndClearXYZValueInHtml, resetSketchMode } from "../../utils/commonFunction"
import { Dimension } from "../../utils/customSketch"
import { removeObjWithChildren } from "../../utils/customUtils"

export class ClipboardActions{
    constructor(inViewer){
        this.threeViewer = inViewer
        this.currentObject = null
    }
    
    cut(inObject){
        if (!inObject) return
        this.currentObject = inObject.cloneIndividual() // Pattern check
        detachTransControlAndClearXYZValueInHtml(this.threeViewer, "error while cut object");
        threeViewer.sketcher.removeEntity(inObject)
        resetSketchMode(this.threeViewer)
        let dimension = new Dimension(this.threeViewer)
        dimension.updateDimensions()
        this.updateHtml() 
    }

    copy(inObject){
        if (!inObject) return
        this.currentObject = inObject.customClone()
        this.updateHtml()
    }

    paste(){
        if (!this.currentObject) return
        this.threeViewer.sketcher.mode = "paste"
        let face = this.threeViewer.sketcher.getCurrentFace().threeFace
        face.add(this.currentObject)
    }

    changePosition(inPoint){
        if (!this.currentObject) return
        this.currentObject.position.copy(inPoint)
    }

    reset(){
        if (!this.threeViewer.sketcher.mode) return
        let temp = this.currentObject.customClone()
        removeObjWithChildren(this.currentObject)
        delete this.currentObject;
        this.currentObject = temp
        this.threeViewer.sketcher.mode = null
        this.updateHtml()
    }

    updateHtml(){
        if (!this.currentObject) {
            document.getElementById("pasteObject").classList.add("menuDisable");
        }else{
            document.getElementById("pasteObject").classList.remove("menuDisable");
        }
    }
}

