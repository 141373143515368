export let environmentVariable3 = {
    development : true,
    modelColor  : 0x5b95d4,
    originalModelColor  : 0x5b95d4,
    extrudeDepth : 5,
    FRONT_END_URL : "http://localhost:8080/",
    REACT_APP_DatabaseServer : "http://127.0.0.1:4000/api/v1/", 
    REACT_APP_DatabaseServer_Main : "http://127.0.0.1:4000/", 
    // FRONT_END_URL : "https://webcad.iotapi.in/",
    // REACT_APP_DatabaseServer : "https://iotapi.in/api/", 
    ODOO_DatabaseServer : "https://odooapi.azurewebsites.net/", 
}
export let environmentVariable= {
    development : false,
    modelColor  : 0x5b95d4,
    originalModelColor  : 0x5b95d4,
    extrudeDepth : 5,
    FRONT_END_URL : "https://webcad.iotapi.in/",
    // REACT_APP_DatabaseServer : "http://127.0.0.1:4500/api/", 
    // FRONT_END_URL : "https://webcad.iotapi.in/",
    REACT_APP_DatabaseServer : "https://iotapi.in/api/v1/", 
    REACT_APP_DatabaseServer_Main : "https://iotapi.in/", 
    ODOO_DatabaseServer : "https://odooapi.azurewebsites.net/", 
}
export let environmentVariable1 = {
    development : true,
    modelColor  : 0x5b95d4,
    originalModelColor  : 0x5b95d4,
    extrudeDepth : 5,
    // FRONT_END_URL : "http://localhost:8080/",
    // REACT_APP_DatabaseServer : "http://127.0.0.1:4500/api/", 
    FRONT_END_URL : "https://webcad.iotapi.in/",
    REACT_APP_DatabaseServer : "https://iotapi.in/api/v1/", 
    REACT_APP_DatabaseServer_Main : "https://iotapi.in/", 
    ODOO_DatabaseServer : "https://odooapi.azurewebsites.net/", 
}