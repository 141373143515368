import * as THREE from 'three'
import { getEdgesFromFace } from '../utils/ocUtils'
import { DCCurve } from './DCEdge'
import { addRefSphere } from '../utils/customUtils2'
import { createFace3DObjectFromPlaner3DPoints } from './CustomShape'
import { removeObjWithChildren } from '../utils/customUtils'

export class DCFace extends THREE.Mesh {
    constructor(geometry, material, inOcFace, inFaceId, inLayerNumber = 1) {
        super(geometry, material)
        this.customType     = "DCFace"
        this.name           = "DCFace"
        this.ocFace         = inOcFace
        this.faceId         = inFaceId
        this.edges          = []
        this.layers.set(inLayerNumber)
        this.update()
    }
    update() {
        this.generateNormal()
        // this.generateEdges()
        this.generateAlignedObject()
    }
    generateNormal() {
        let scope = this
        // Get first face normal from geometry
        let firstFaceNormal = new THREE.Vector3()
        firstFaceNormal.setX(scope.geometry.attributes.normal.getX(0))
        firstFaceNormal.setY(scope.geometry.attributes.normal.getY(0))
        firstFaceNormal.setZ(scope.geometry.attributes.normal.getZ(0))
        scope.normal = firstFaceNormal 
    }

    generateAlignedObject() {
        /**
         * This function will create aligned {THREE.Object3D} from the face
         * It will use for sketching and storing sketch entities
         */

        let scope = this
        scope.threeFace = new THREE.Object3D()

        // Get face center
        let center = new THREE.Vector3()
        scope.geometry.computeBoundingBox()
        scope.geometry.boundingBox.getCenter(center)
        
        // Get Matrix to align object
        let matrix = new THREE.Matrix4()
        matrix.lookAt(scope.normal, new THREE.Vector3(), new THREE.Vector3(0, 1, 0))
        matrix.setPosition(center)
        
        scope.threeFace.applyMatrix4(matrix)
        scope.threeFace.name = "threeFace"
        scope.add(scope.threeFace)
    }

    getOCFace() {
        return this.ocFace
    }
    getFaceId() {
        return this.faceId
    }
    setFaceId(inFaceId) {
        this.faceId = inFaceId
    }
    generateEdges() {
        let scope = this
        let edges = getEdgesFromFace(ocGlobal, scope.ocFace)

        for (let index = 0; index < edges.length; index++) {

            const myEdge = edges[index]
            let dcCurve = new DCCurve(myEdge)
            scope.addEdge(dcCurve)
        }
    }
    addEdge(inEdge) {
        this.add(inEdge)
        this.edges.push(inEdge)
    }
    getEdges() {
        return this.edges
    }

    getFacePoints() {
        let scope = this
        let points = []

        scope.edges.forEach((edge) => {
            points = points.concat(edge.points)
        })
        return points
           
    }

    getEntities() {
        let scope = this
        let entities = scope.getObjectsByProperty("customType", "DCEntity")
        return entities
    }
    getCutoutEntities() {
        let scope = this
        let entities = scope.getObjectsByProperty("sketchType", "cutout")
        return entities
    }

    removeEntity(inEntity) {
        let scope = this
        removeObjWithChildren(inEntity)
    }

    removePattern(inPattern) {
        let scope = this
        removeObjWithChildren(inPattern)
    }

    getCutExtrudeData() {
        let entities = this.getCutoutEntities()

        let extrudeData = {
            circles: [],
            rectangles: [],
            customShapes: [],
            ellipse: [],
        }

        entities.forEach((entity) => {
            let entityData = entity
            let type = entity.entityType
            
            switch (type) {
                case "Circle":
                    extrudeData.circles.push(entityData)
                    break;
                case "Rect":
                    extrudeData.rectangles.push(entityData)
                    break;
                case "Ellipse":
                    extrudeData.ellipse.push(entityData)
                    break;
                case "Polyline":
                    extrudeData.customShapes.push(entityData)
                    break;
                default:
                    break;
            }  
        })

        return extrudeData
    }
}

