
init()
function init() {
    addGuideBtnEvents()
}
function guideCounter() {
    let count = 0
    function getCount() {
        return count
    }
    function incrementCount() {
        count = count + 1
        return count
    }
    return {
        getCount,
        incrementCount
    }
}

let gCounts = guideCounter()

export function HtmlGuideForProceedToProcurement(params) {

    if (gCounts.getCount() > 0) return

    let threeViewer = params.threeViewer
    if (threeViewer.sketcher.currentFace) return
    gCounts.incrementCount()
    var procurementBox = document.querySelector('.procurement_box');
    var overlay = document.getElementById('overlay');
    var addToCartBox = document.getElementById('addToCart');
    addToCartBox.style = "z-index:99"

    // Show the div
    overlay.style.display = 'block';
    procurementBox.style.display = 'block';
    let skipBtnEle = document.getElementById("skipButton")
    skipBtnEle.style.display = 'block'
    // Hide the div after 3 seconds
    setTimeout(function () {
        removeGuideBox()
    }, 5000);

}


export function removeGuideBox() {
    var overlay = document.getElementById('overlay');
    // if (overlay.style.display === 'none') return
    var addToCartBox = document.getElementById('addToCart');
    addToCartBox.style = "z-index:1"
    var procurementBox = document.querySelector('.procurement_box');
    overlay.style.display = 'none';
    procurementBox.style.display = 'none';

    let skipBtnEle = document.getElementById("skipButton")
    skipBtnEle.style.display = 'none'
}
function addGuideBtnEvents() {
    let skipBtnEle = document.getElementById("skipButton")
    skipBtnEle.addEventListener("click", removeGuideBox)

}