import opentype from 'opentype.js'
import * as makerjs from 'makerjs';
import * as THREE from "three";
import { environmentVariable } from '../../../variables';
import { DCPolylineWithArc } from '../../cuttingEntities/DCEntity';
import  { pathDataToPolys }  from 'svg-path-to-polygons';
import { HandleImgUpload } from '../../../views/imgUpload';
export class CreateEmbossText extends THREE.Object3D{
    constructor(params){
        super();
        let {text,inViewer,fontSize} = params
        this.text = text
        this.threeViewer = inViewer
        this.size = fontSize
        this.bezierAccuracy = 0.5
        this.fontName = params.fontTTFName
        this.sketchType = params.sketchType
        // this.fontName = "TT-Octosquares-Trial-Condensed-Regular.ttf"
        this.name = "font"
        this.customType = "font"
        this.entityType = "font"
        this.sequenceFaceName = params.sequenceFaceName

        this.setGraphicSequence()
        // this.loadSVGAndExtractPoints("http://localhost:5173/svg/Beach_Sunglasses.svg")
    }
    setGraphicSequence() {
        this.sequenceFaceName = this.sequenceFaceName
        let face = this.threeViewer.scene.getObjectByProperty("sequenceFaceName", this.sequenceFaceName)
        face.totalGraphics = face.totalGraphics + 1
    }
    info(){
        let scope = this
        // retutn                     
        // text: embossText,
        // inViewer: threeViewer,
        // fontSize: embossTextFontSize,
        // fontTTFName,
        // sketchType: threeViewer.sketcher.sketchType
        return {
            entityType  : this.entityType,
            text        : this.text,
            fontSize    : this.size,
            fontTTFName : this.fontName,
            sketchType  : this.sketchType,
            rotation    : this.rotation,
            scale       : this.scale,
            position    : this.position.clone(),
            sequenceFaceName : this.sequenceFaceName,
            // previousParent : scope.previousParent.id
        }
    }
    async create(){
        let scope = this
        if (scope.sketchType === "cutout"){
            await  this.cutOutFont();
        }else if (scope.sketchType === "graphic"){
            await scope.svgImageFont()
        }else {
            console.warn("Emboss type is not valid")
        }
    }
    setParentFae(inFace) {
        this.parentFace = inFace
    }
    getParentFace() {
        let DCFaceObj = this.threeViewer.scene.getObjectByProperty("sequenceFaceName", this.sequenceFaceName)
        this.parentFace = DCFaceObj
        return this.parentFace
    }
    cutOutFont() {
        let scope = this
        return new Promise((resolve, reject) => {
            opentype.load(environmentVariable.FRONT_END_URL + "assets/fonts/" + scope.fontName, async (err, font) => {
                if (err) {
                    console.error("---err---", err);
                    reject(err)
                    return;
                }
                let text = scope.text;
                let size = scope.size;
                let bezierAccuracy = 0.5;
                const textModel = new makerjs.models.Text(font, text, size, false, false, bezierAccuracy);
    
                let points = scope.getPointsOfModel(textModel);
                for (let modelPoint of points){
                    scope.createShapeUsingPoints(modelPoint);
                }
                resolve()
            });
        })
    }
    async svgImageFont(){
        let scope = this
        return new Promise((resolve, reject) => {
            opentype.load(environmentVariable.FRONT_END_URL + "assets/fonts/" + scope.fontName, async (err, font) => {
                if (err) {
                    console.error("---err-at-loading-font---", err, "link : ",environmentVariable.FRONT_END_URL + "assets/fonts/" + scope.fontName);
                    resolve(err)
                    return;
                }

                let text = scope.text;
                let size = scope.size;
                let bezierAccuracy = 0.5;
                const textModel = new makerjs.models.Text(font, text, size, false, false, bezierAccuracy);
                let svgPath = scope.convertModelToSvgPath(textModel)
                let svg = scope.createSvgUsingPath(svgPath)
                let handleImgUpload = new HandleImgUpload({
                    threeViewer : scope.threeViewer,
                    svgRawData  : svg,
                    size        : scope.size,
                    sequenceFaceName : scope.sequenceFaceName,
                    sketchType  : scope.sketchType,
                    isGraphicImg: "no",
                })
                let imgObjs = await handleImgUpload.init()
                scope.add(imgObjs);
                scope.threeViewer.state.objSequence.addEntity(imgObjs);

                resolve(handleImgUpload)
            });
        })

    }

    createSvgUsingPath(path) {
        const svgString = `<svg fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="${path}" fill="black"/>
        </svg>`;
    
        // Convert the SVG string to a Blob
        const svgBlob = new Blob([svgString], { type: 'image/svg+xml' });
    
        // Create a data URL from the Blob
        const svgDataURL = URL.createObjectURL(svgBlob);
    
        // You can use svgDataURL as a file-like object
        return svgDataURL;
    }

    createShapeUsingPoints(points, parent) {
        
        points = this.removeConsecutiveDuplicates(points)
        let shapes = []
        let temp = []
        let cPoint = points[0]
        let fPoint = 0
        for (let i = 0; i < points.length; i++) {
            
            const p = points[i];

            if (compareVectors(cPoint,p) && i!=fPoint){
                
                let t = [...temp]
                shapes.push(t)
                temp.length = 0
                cPoint = points[i+1]
                fPoint = i+1
                continue
            }
            temp.push(p)
            if (i === (points.length-1)){
                shapes.push(temp)
            }
            
        }
        this.createDCShapesGroup(shapes)

    }   

    createDCShapesGroup(shapes){
        for (let s of shapes){
            let check = compareVectorsFloat(s[0],s[s.length-1])
            if (check){
                s.pop()
            } 
            let dcShape = this.dcPolyShape(s)
            this.add(dcShape)
        }
    }


    removeConsecutiveDuplicates(points) {
        return points.filter((point, index, array) => {
            // Check if the current point is not equal to the next point
            const isLastPoint = index === array.length - 1;
            const hasNextPoint = !isLastPoint;
            const isDuplicate = hasNextPoint && (
                point.x.toFixed(2) === array[index + 1].x.toFixed(2) &&
                point.y.toFixed(2) === array[index + 1].y.toFixed(2)
            );
    
            return !isDuplicate;
        });
    }
    getPointsOfModel(inTextModel) {
        let scope = this
        let allPoints = [];
        // Get an array of model values from the object
        let modelsList = Object.values(inTextModel.models);
    
        modelsList.forEach(inModel => {
            let modelCenter = inModel.origin;
            let oneModelPoints = []
    
            // Use walk
            makerjs.model.walk(inModel, {
                onPath: function (e) {
                    makerjs.path.moveRelative(e.pathContext, modelCenter);
                    let points = scope.getPointsOfPath(e.pathContext);
                    points.forEach((p)=>{
                        
                        let threePoint = new THREE.Vector2(p[0],p[1])
                        oneModelPoints.push(threePoint);
                    })
                }
            });
            allPoints.push(oneModelPoints)
        });
        return allPoints;
    }
    getPointsOfPath(inPath) {
        return makerjs.path.toPoints(inPath, 5)
    }
    dcPolyShape(verticesArray) {
        let polyShape = new DCPolylineWithArc({sketchType : "cutout",referenceCircleSize:0.1});
        polyShape.entityData.engrave = true
        polyShape.isClosed = true
        polyShape.generateFromPoints(verticesArray)
        let DCFaceObj = this.threeViewer.scene.getObjectByProperty("sequenceFaceName", this.sequenceFaceName)
        polyShape.setParentFae(DCFaceObj)
        return polyShape
    }
    loadSVGAndExtractPoints(svgPath) {
        let scope = this
        // Load SVG content using Fetch API or any other method
        fetch(svgPath)
            .then(response => response.text())
            .then(svgContent => {
                let paths = scope.getPaths(svgContent)
                paths.forEach((p)=>{
                    const model = makerjs.importer.fromSVGPathData(p);
                    let points = scope.getPointsOfModel(model)
                    points.forEach((modelPoint)=>{
                        scope.createShapeUsingPoints(modelPoint,scope.threeViewer.scene)
                    })
                })
            })
            .catch(error => console.error('Error loading SVG:', error));
    }
    getPaths(svgContent) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(svgContent, "image/svg+xml");
    
        // Get all path elements
        const pathElements = doc.querySelectorAll("path");
        let paths = []
        // Iterate through each path element and extract path data and styles
        pathElements.forEach((pathElement, index) => {
            const pathData = pathElement.getAttribute("d");
            // const style = pathElement.getAttribute("style");
            paths.push(pathData)
        });
        return paths
    }
    loadSVGAndExtractPoints2(svgPath) {
        // Load SVG content using Fetch API or any other method
        let scope = this
        fetch(svgPath)
            .then(response => response.text())
            .then(svgContent => {
    
                let paths = scope.getPaths(svgContent)
                paths.forEach((singlePath)=>{
                    let pointsShapeArray = scope.svgToPath(singlePath) // path points
                    pointsShapeArray.forEach((shapePoint)=>{
                        if (!shapePoint.closed) return
                        let temp = []
                        shapePoint.forEach((p)=>{
                            let v = new THREE.Vector2(p[0],p[1])
                            temp.push(v)
                        })
                        scope.createShapeUsingPoints(temp,threeViewer.scene)
                    })
                })
                // Parse the SVG content using Maker.js SVG importer
            })
            .catch(error => console.error('Error loading SVG:', error));
    }  
    svgToPath(pathData) {
        // let pathData = 'M5,15 c5.5,0 10-4.5 10,-10 h10';
        let points = pathDataToPolys(pathData, {tolerance:1, decimals:1});
        return points
    }
    convertModelToSvgPath(inModel) {
        let svgPath = makerjs.exporter.toSVGPathData(inModel);
        return svgPath;
    }
}   
window.CreateEmbossText = CreateEmbossText

function compareVectors(v1,v2){
    if ((v1.x == v2.x) && (v1.y == v2.y) && (v1.z == v2.z)){
        return true
    }else return false
}
function compareVectorsFloat(v1,v2){
    let v1z = v1.z
    if (v1.z) v1z = v1.z.toFixed(2)
    let v2z = v2.z
    if (v2.z) v2z = v2.z.toFixed(2)
    if ((v1.x.toFixed(2) == v2.x.toFixed(2)) && (v1.y == v2.y.toFixed(2)) && (v1z == v2z)){
        return true
    }else return false
}










