export function initiateDragAndDropStepFile(params) {
    let dropArea = document.getElementById("stepFileInputDiv")
    let events = ['dragenter', 'dragover', 'dragleave', 'drop']
    events.forEach(eventName => {
        dropArea.addEventListener(eventName, preventDefaults, false);
    });
    let events2 = ['dragenter', 'dragover']
    events2.forEach(eventName => {
        dropArea.addEventListener(eventName, highlight, false);
    });

    let events3 = ['dragleave', 'drop']
    events3.forEach(eventName => {
        dropArea.addEventListener(eventName, unHighlight, false);
    });

    dropArea.addEventListener('drop', handleDrop, false);

    function preventDefaults(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    function highlight(e) {
        document.body.classList.add("blurDiv")
        if (dropArea.classList)
        dropArea.classList.add('menuHoverEffect');
    }
    
    function unHighlight(e) {
        document.body.classList.remove("blurDiv")
        if (dropArea.classList)
        dropArea.classList.remove('menuHoverEffect');
    }

    async function handleDrop(e) {
        let dt = e.dataTransfer;
        let files = dt.files;
        params.threeViewer.file = files[0]
        await params.handleUploadedFile(params.threeViewer, params.openModel)

    }
}