import * as THREE from 'three';
// import lod from 'lodash'
import { DCEntityData } from './DCEntityCommon/DCEntityData';
import { cloneDeep } from '../../utils/customUtils';
class DCEntityGroup extends THREE.Group {
    constructor() {
        super();
        this.customType = "DCEntityGroup";
        this.name = "DCEntityGroup";
        this.entities = [];
        this.layers.set(3); // Entity Layer
        this.entityData = DCEntityData()

    }
    addEntity(inEntity) {
        this.entities.push(inEntity);
        this.add(inEntity);
    }
    getEntities() {
        return this.entities;
    }
    customClone(){
        let clonedEntity = this.cloneIndividual()
        clonedEntity.entityData = cloneDeep(this.entityData) 
        clonedEntity.position.copy(this.position.clone())
        clonedEntity.rotation.copy(this.rotation.clone())
        return clonedEntity
    }
}

export class DCPattern extends DCEntityGroup {
    constructor(params) {
        super(params);
        this.customType = "DCPattern";
        this.name = "DCPattern";
        this.patternType = "none";
    }
    setPatternType(inPatternType) {
        this.patternType = inPatternType;
    }
    getPatternType() {
        return this.patternType;
    }
}

export class DCRectangularPattern extends DCPattern {
    /**
     * This class will create rectangular pattern of the given entity
     * @param {DCEntity} inDCEntity
     * @param {Number} inXCount
     * @param {Number} inXSpacing
     * @param {Number} inAngle
     * @param {Number} inYCount
     * @param {Number} inYSpacing
     * @returns {DCRectangularPattern}
     */
    constructor(inDCEntity, inXCount, inXSpacing, inAngle = 0, inYCount = 1, inYSpacing = 0, sketchType) {
        super();
        this.setPatternType("rectangular");
        this.entity = inDCEntity;
        this.xCount = inXCount;
        this.xSpacing = inXSpacing;
        this.angle = inAngle;
        this.yCount = inYCount;
        this.ySpacing = inYSpacing;
        this.sketchType = sketchType
    }
    generatePattern() {
        let scope = this;
        let normal = new THREE.Vector3(0, 0, 1);

        for (let i = 0; i < scope.xCount; i++) {

            for(let j = 0; j < scope.yCount; j++) {
                    if (scope.entity.name === "cutOutLibrary"){
                        scope.entity.children.forEach(childEntity => {
                            generateOneEntity(childEntity, i, j);
                        });
                    }else{
                        generateOneEntity(scope.entity, i, j);
                    }
            }
        }
        this.entityData = scope.entity.entityData

        function generateOneEntity(childEntity, xSpacingMultiplier, ySpacingMultiplier) {
            let newEntity = childEntity.customClone(scope.sketchType);
            let vector = new THREE.Vector3(scope.xSpacing * xSpacingMultiplier, scope.ySpacing * ySpacingMultiplier, 0);
            vector.applyAxisAngle(normal, scope.angle);
            newEntity.position.add(vector);
            scope.add(newEntity);
        }
    }
    cloneIndividual(){
        let clone = new this.constructor(this.entity, this.xCount, this.xSpacing, this.angle, this.yCount , this.ySpacing, this.sketchType );
        clone.generatePattern()
        return clone;
    }
}

export class DCCircularPattern extends DCPattern {
    /**
     * This class will create circular pattern of the given entity
     * @param {DCEntity} inDCEntity
     * @param {Number} inRadius
     * @param {Number} inCount
     * @param {Number} inNoOFRings
     * @returns {DCCircularPattern}
     */
    constructor(inDCEntity, inRadius, inCount, inNoOFRings = 1, sketchType) {
        super();
        this.setPatternType("circular");
        this.entity = inDCEntity;
        this.radius = inRadius;
        this.count = inCount;
        this.noOFRings = inNoOFRings;
        this.sketchType = sketchType

    }
    generatePattern() {
        let scope = this;
        let normal = new THREE.Vector3(0, 0, 1);
        let thetaAngle = 2 * Math.PI / scope.count;

        if (scope.entity.name === "cutOutLibrary"){
            scope.entity.children.forEach(childEntity => {
                let newEntity = childEntity.customClone();
                scope.add(newEntity);
            });
        }else{
            let newEntity = scope.entity.customClone();
            scope.add(newEntity);
        }

        for (let i = 1; i <= scope.noOFRings; i++) {

            for(let j = 0; j < scope.count; j++) {
                if (scope.entity.name === "cutOutLibrary"){
                    scope.entity.children.forEach(childEntity => {
                        generateOneEntity(childEntity, i, j);
                    });
                }else{
                    generateOneEntity(scope.entity, i, j);
                }
            }
        }

        function generateOneEntity(entity,radiusMultiplier, angleMultiplier) {
            let newEntity = entity.customClone();
            let vector = new THREE.Vector3(scope.radius * radiusMultiplier, 0, 0);
            vector.applyAxisAngle(normal, thetaAngle * angleMultiplier);
            newEntity.position.add(vector);
            scope.add(newEntity);
        }
    }
    cloneIndividual(){
        let clone = new this.constructor(this.entity, this.radius, this.count, this.noOFRings);
        clone.generatePattern()
        return clone;
    }

}