import { Entity2DDrive } from "./utils/Entity2DDrive";
import { calculateCircleCenter } from "./utils/customUtils";
import { replaceOpenCascadeShape } from "./utils/ocUtils";
let extrudeThickness = 5
export function cylinderBody(oc, radius, strtX, strtY, strtZ, dirX, dirY, dirZ, depth = extrudeThickness) {
    if (depth == 0) depth = extrudeThickness

    // Making a cylinder

    // create a circle with a radius
    const circle = new oc.BRepBuilderAPI_MakeEdge_8(new oc.gp_Circ_2(new oc.gp_Ax2_3(new oc.gp_Pnt_3(strtX, strtY, strtZ), new oc.gp_Dir_4(dirX, dirY, dirZ)), radius))

    const aWire = new oc.BRepBuilderAPI_MakeWire_2(circle.Edge());
    const myWireProfile = aWire.Wire();

    // Extrusion Body : Prism the Profile
    const myFaceProfile = new oc.BRepBuilderAPI_MakeFace_15(myWireProfile, false);
    const aPrismVec = new oc.gp_Vec_4(depth * dirX, depth * dirY, depth * dirZ);
    let myBody = new oc.BRepPrimAPI_MakePrism_1(myFaceProfile.Face(), aPrismVec, false, true);
    return myBody;
}
export function rectangleBody(oc, arrayPoints, length, dirX, dirY, dirZ) { //Neglate This Function
    const point1 = new oc.gp_Pnt_3(0, 0, 0)
    const point2 = new oc.gp_Pnt_3(0, 70, 0)
    const point3 = new oc.gp_Pnt_3(70, 70, 0)
    const point4 = new oc.gp_Pnt_3(70, 0, 0)
    const edge1 = new oc.BRepBuilderAPI_MakeEdge_3(point1, point2)
    const edge2 = new oc.BRepBuilderAPI_MakeEdge_3(point2, point3)
    const edge3 = new oc.BRepBuilderAPI_MakeEdge_3(point3, point4)
    const edge4 = new oc.BRepBuilderAPI_MakeEdge_3(point4, point1)

    const aWire = new oc.BRepBuilderAPI_MakeWire_5(edge1.Edge(), edge2.Edge(), edge3.Edge(), edge4.Edge())

    const myWireProfile = aWire.Wire();

    // Extrusion Body : Prism the Profile
    const myFaceProfile = new oc.BRepBuilderAPI_MakeFace_15(myWireProfile, false);
    const aPrismVec = new oc.gp_Vec_4(length * dirX, length * dirY, length * dirZ);
    let myBody = new oc.BRepPrimAPI_MakePrism_1(myFaceProfile.Face(), aPrismVec, false, true);
    return myBody;
}
export function rectangleBody2(oc, arrayPoints, dirX, dirY, dirZ, depth = extrudeThickness) {
    if (depth == 0) depth = extrudeThickness
    const mkWire = new oc.BRepBuilderAPI_MakeWire_1();

    for (let i = 0; i < arrayPoints.length; i++) {
        let p1 = arrayPoints[i]
        let p2 = arrayPoints[(i + 1) % arrayPoints.length]
        const point1 = new oc.gp_Pnt_3(p1.x, p1.y, p1.z)
        const point2 = new oc.gp_Pnt_3(p2.x, p2.y, p2.z)
        const edge1 = new oc.BRepBuilderAPI_MakeEdge_3(point1, point2)

        const wire1 = new oc.BRepBuilderAPI_MakeWire_2(edge1.Edge())
        mkWire.Add_2(wire1.Wire());



    }
    // return null
    const myWireProfile = mkWire.Wire();
    // Extrusion Body : Prism the Profile
    const myFaceProfile = new oc.BRepBuilderAPI_MakeFace_15(myWireProfile, false);
    const aPrismVec = new oc.gp_Vec_4(depth * dirX, depth * dirY, depth * dirZ);
    let myBody = new oc.BRepPrimAPI_MakePrism_1(myFaceProfile.Face(), aPrismVec, false, true);
    return myBody;
}

// below code take the file and converts it into step file

// Takes a TopoDS_Shape, creates a STEP file from it and returns a ObjectURL
export default function exportStep(oc, shapes) {
    var writer = new oc.STEPControl_Writer_1();
    for (let i = 0; i < shapes.length; i++) {
        const shape = shapes[i];
        if (shape) {
            // Transfer all the shapes into Writer
            var progressRange = new oc.Message_ProgressRange_1();
            writer.Transfer(shape, oc.STEPControl_StepModelType.STEPControl_AsIs, true, progressRange);
        }
    }
    writer.Write("file.step")

    // Read the STEP file from the virtual file system
    const stepFile = oc.FS.readFile("./file.step", { encoding: "binary" });

    // Create a new blob with the step file data
    const stepBlob = new Blob([stepFile.buffer], { type: "application/step" });
    
    oc.FS.unlink(`./file.step`);
    return stepBlob

};

export function exportIges(oc, shapes) {
    // Create an IGES writer
    var writer = new oc.IGESControl_Writer_1();

    for (let i = 0; i < shapes.length; i++) {
        const e = shapes[i];
        writer.AddShape(e,new oc.Message_ProgressRange_1());
    }

    // Set options for the IGES export (optional)
    // var wok = writer.WS();
    // wok.SetNameMode(true); // Enable to include names in the IGES file

    // Write the IGES file
    writer.ComputeModel();
    writer.Write_2("file.iges",0);

    // Read the IGES file from the virtual file system
    const igesFile = oc.FS.readFile("./file.iges", { encoding: "binary" });

    // Create a new blob with the IGES file data
    const igesBlob = new Blob([igesFile.buffer], { type: "application/iges" });

    // Delete the temporary IGES file
    oc.FS.unlink("./file.iges");

    return igesBlob;
}


export function makeCut(oc, shape, shape1,threeViewer) {
    const tf1 = new oc.gp_Trsf_1();
    // tf1.SetTranslation_1(new oc.gp_Vec_4(cutX, cutY, 0));
    const loc1 = new oc.TopLoc_Location_2(tf1)
    const cut = new oc.BRepAlgoAPI_Cut_3(shape, shape1, new oc.Message_ProgressRange_1());
    cut.Build(new oc.Message_ProgressRange_1());
    // replaceOpenCascadeShape(threeViewer.ocTools.shapeTool, shape, cut.Shape(), oc);
    let cuttShape = cut.Shape();
    return cuttShape
};
export function makeCutLibraryShape(oc, shape, shape1) {

    const tf1 = new oc.gp_Trsf_1();
    // tf1.SetTranslation_1(new oc.gp_Vec_4(cutX, cutY, 0));
    const loc1 = new oc.TopLoc_Location_2(tf1)
    const cut = new oc.BRepAlgoAPI_Cut_3(shape, shape1.Moved(loc1, false), new oc.Message_ProgressRange_1());
    cut.Build(new oc.Message_ProgressRange_1());

    return cut.Shape();
};

export function ellipdseBody(oc,vector1,vector2, normal,majorX,minorY) {
    let x = normal.x * -1
    let y = normal.y * -1
    let z = normal.z * -1
    const ellipse = new oc.BRepBuilderAPI_MakeEdge_12(new oc.gp_Elips_2(new oc.gp_Az2_3(new oc.gp_Pnt_3(vector1.x, vector1.y, vector1.z) , new oc.gp_Dir_4(0, 0, -normal.z)), Math.abs(vector2.x - vector1.x) , Math.abs(vector2.y - vector1.y)))

    const aWireep = new oc.BRepBuilderAPI_MakeWire_2(ellipse.Edge());
    const myWireProfileep = aWireep.Wire();
    const myFaceProfileep = new oc.BRepBuilderAPI_MakeFace_15(myWireProfileep, false);
    const aPrismVec = new oc.gp_Vec_4(0, 0, extrudeThickness);
    let myBodyep = new oc.BRepPrimAPI_MakePrism_1(myFaceProfileep.Face(), aPrismVec, false, true);
    return myBodyep
}
  export function ellipseBody(oc, vector1, radiusX, radiusY, normal, rotation, depth = extrudeThickness) {
    if (depth == 0) depth = extrudeThickness

    let majorRadius, minorRadius
    if (radiusX > radiusY) {
        majorRadius = radiusX;
        minorRadius = radiusY;
    } else {
        majorRadius = radiusY;
        minorRadius = radiusX;
    }
    let ellipse = new oc.gp_Elips_2(new oc.gp_Ax2_3(new oc.gp_Pnt_3(vector1.x, vector1.y, vector1.z), new oc.gp_Dir_4(-normal.x, -normal.y, -normal.z)), majorRadius, minorRadius)
    if (radiusY > radiusX) {
        ellipse.Rotate(new oc.gp_Ax1_2(new oc.gp_Pnt_3(vector1.x, vector1.y, vector1.z), new oc.gp_Dir_4(-normal.x, -normal.y, -normal.z)), Math.PI / 2)
    }
    rotation = Number(rotation)
    if (rotation &&  rotation!= 0) ellipse.Rotate(new oc.gp_Ax1_2(new oc.gp_Pnt_3(vector1.x, vector1.y, vector1.z), new oc.gp_Dir_4(-normal.x, -normal.y, -normal.z)), Math.PI - rotation)
    const edgeEllipse = new oc.BRepBuilderAPI_MakeEdge_12(ellipse)
    const aWireep = new oc.BRepBuilderAPI_MakeWire_2(edgeEllipse.Edge());
    const myWireProfileep = aWireep.Wire();
    const myFaceProfileep = new oc.BRepBuilderAPI_MakeFace_15(myWireProfileep, false);
    const aPrismVec = new oc.gp_Vec_4(normal.x * (-depth), normal.y * (-depth), normal.z * (-depth));

    let myBodyep = new oc.BRepPrimAPI_MakePrism_1(myFaceProfileep.Face(), aPrismVec, false, true);
    return myBodyep
}
export function ellipseBody2(oc,vector1,vector2, normal) {
    const ellipse = new oc.BRepBuilderAPI_MakeEdge_14(new oc.gp_Elips_2(new oc.gp_Ax2_3(new oc.gp_Pnt_3(vector1.x, vector1.y, vector1.z), new oc.gp_Dir_4(-normal.x, -normal.y, -normal.z)), Math.abs(vector2.x - vector1.x), Math.abs(vector2.y - vector1.y)
    ,new oc.gp_Pnt_3(vector1.x, vector1.y, vector1.z),
    new oc.gp_Pnt_3(vector2.x, vector2.y, vector2.z)))
    const aWireep = new oc.BRepBuilderAPI_MakeWire_2(ellipse.Edge());
    const myWireProfileep = aWireep.Wire();
    const myFaceProfileep = new oc.BRepBuilderAPI_MakeFace_15(myWireProfileep, false);
    const aPrismVec = new oc.gp_Vec_4(0, 0, -extrudeThickness);

    let myBodyep = new oc.BRepPrimAPI_MakePrism_1(myFaceProfileep.Face(), aPrismVec, false, true);
    return myBodyep
  }
export function customRectangle(oc, inPointsArray ) {
    let arrEntity2DDrive = [];
    for (let i = 0; i < inPointsArray.length; i++) {
        let p1 = inPointsArray[i]
        let p2 = inPointsArray[(i + 1) % inPointsArray.length]

        const point1 = new oc.gp_Pnt_3(p1.x, p1.y, 0)
        const point2 = new oc.gp_Pnt_3(p2.x, p2.y, 0)

        let edgeLine = new oc.BRepBuilderAPI_MakeEdge_3(point1, point2)
        let topoEdge = edgeLine.Edge()
        let adpCurve = new oc.BRepAdaptor_Curve_2(topoEdge)
        let t1 = adpCurve.FirstParameter();
        let t2 = adpCurve.LastParameter();
        let hGeomCurve = oc.BRep_Tool.Curve_2(topoEdge, t1, t2)
        let geom2dLine = oc.GeomAPI.To2d(hGeomCurve, new oc.gp_Pln_3(new oc.gp_Pnt_3(0, 0, 0), new oc.gp_Dir_4(0, 0, 1)))
        arrEntity2DDrive.push(new Entity2DDrive(geom2dLine, t1, t2, hGeomCurve));
    }
    return arrEntity2DDrive
}


export function customCircle(oc, center, normal, radius) { 
    let arrEntity2DDrive = [];
    let circleCenter = new oc.gp_Pnt_3(center.x, center.y, center.z)
    let circleNormal =  new oc.gp_Dir_4(normal.x, normal.y, normal.z)
    let circleEdge = new oc.BRepBuilderAPI_MakeEdge_8(new oc.gp_Circ_2(new oc.gp_Ax2_3(circleCenter, circleNormal), radius))
    let edgeCircle = circleEdge.Edge()
    let adpCurveCircle = new oc.BRepAdaptor_Curve_2(edgeCircle)
    let t1Circle = adpCurveCircle.FirstParameter();
    let t2Circle = adpCurveCircle.LastParameter();
    let hGeomCurve = oc.BRep_Tool.Curve_2(edgeCircle, t1Circle, t2Circle)
    let geom2dCircle = oc.GeomAPI.To2d(hGeomCurve, new oc.gp_Pln_3(new oc.gp_Pnt_3(0, 0, 0), new oc.gp_Dir_4(0, 0, 1)))
    arrEntity2DDrive.push(new Entity2DDrive(geom2dCircle, t1Circle, t2Circle, hGeomCurve));
    return arrEntity2DDrive
}
export function customEllipseBody(oc, center, radiusX, radiusY, normal) {
    let arrEntity2DDrive = [];
    let majorRadius, minorRadius
    if (radiusX > radiusY) {
        majorRadius = radiusX;
        minorRadius = radiusY;
    } else {
        majorRadius = radiusY;
        minorRadius = radiusX;
    }
    let ellipseCenter = new oc.gp_Pnt_3(center.x, center.y, center.z)
    let ellipseNormal = new oc.gp_Dir_4(normal.x, normal.y, normal.z)
    let ellipse = new oc.gp_Elips_2(new oc.gp_Ax2_3(ellipseCenter, ellipseNormal), majorRadius, minorRadius)
    let edgeEllipse = new oc.BRepBuilderAPI_MakeEdge_12(ellipse)
    let topoEdgeEllipse = edgeEllipse.Edge()
    let adpCurveEllipse = new oc.BRepAdaptor_Curve_2(topoEdgeEllipse)
    let t1Ellipse = adpCurveEllipse.FirstParameter();
    let t2Ellipse = adpCurveEllipse.LastParameter();
    let hGeomCurve = oc.BRep_Tool.Curve_2(topoEdgeEllipse, t1Ellipse, t2Ellipse)
    let geom2dCircle = oc.GeomAPI.To2d(hGeomCurve, new oc.gp_Pln_3(new oc.gp_Pnt_3(0, 0, 0), new oc.gp_Dir_4(0, 0, 1)))
    arrEntity2DDrive.push(new Entity2DDrive(geom2dCircle, t1Ellipse, t2Ellipse, hGeomCurve));
    return arrEntity2DDrive
}

function getProjectParameterOFEntity(oc, inEntity, inProjectionData) {
    let entityT
    // To know the curve instance (Line) and get parameter t
    if (inEntity.get() instanceof oc.Geom2d_Line) {
        entityT = inProjectionData.Parameter_1(1)
    } else if (inEntity.get() instanceof oc.Geom2d_Circle) {
        entityT = inProjectionData.Parameter_1(2)
    } else {
        entityT = inProjectionData.Parameter_1(2)
    }
    return entityT
}
let tol = 0.0001
function isBetweenRange(x, min, max) {
    return x >= (min - tol) && x <= (max  + tol);
}

export function getIntersectionData(oc, entity1, entity2) {

    const intersector = new oc.Geom2dAPI_InterCurveCurve_2(entity1.mGeom2DCurve, entity2.mGeom2DCurve, 0);
    // To check and get intersection points
    let noOFIntersection = intersector.NbPoints()
    if (noOFIntersection == 0) {
        console.log("no Intersection")
    }
    for (let index = 1; index <= noOFIntersection; index++) {
        let intersectingPoint = (intersector.Point(index))
        // to Project Points on entities
        let projectOnE1 = new oc.Geom2dAPI_ProjectPointOnCurve_2(intersectingPoint, entity1.mGeom2DCurve)
        let projectOnE2 = new oc.Geom2dAPI_ProjectPointOnCurve_2(intersectingPoint, entity2.mGeom2DCurve)
        let entity1T = getProjectParameterOFEntity(oc, entity1.mGeom2DCurve, projectOnE1)
        let entity2T = getProjectParameterOFEntity(oc, entity2.mGeom2DCurve, projectOnE2)
        if (!isBetweenRange(entity1T, entity1.mT1, entity1.mT2)) {
            continue
        }

        if (!isBetweenRange(entity2T, entity2.mT1, entity2.mT2)) {
            continue
        }
        entity1.mSplitParam.push(entity1T);
        entity2.mSplitParam.push(entity2T);

    }
}

export function splitEntity2DDrive(oc, inEntity2DDrive) {
    // get TrimmedSplit Curve

    for (let i = 0; i < inEntity2DDrive.length; i++) {
        inEntity2DDrive[i].splitEntities(oc)
    }
    return inEntity2DDrive;
}
export function allEntitiesLoop(oc, entitiesArray) {
    for (let i = 0; i < entitiesArray.length; i++) {
        const entityI = entitiesArray[i];
        for (let j = i + 1; j < entitiesArray.length; j++) {
            const entityJ = entitiesArray[j];
            let data = getIntersectionData(oc, entityI, entityJ)
        }
    }

    let splitcurve = splitEntity2DDrive(oc, entitiesArray)
    return entitiesArray
}
export function makeCustomArcEntity(inP1, inP2, radius,side,center, openCascade) {
    let customArcEntities = {
        points : []
    }
    let circleCenter = new openCascade.gp_Pnt_3(center.x,center.y,0)
    let circleNormal =  new openCascade.gp_Dir_4(0, 0, 1)
    let circle = new openCascade.gp_Circ_2(new openCascade.gp_Ax2_3(circleCenter, circleNormal), radius)
    if (side === "inWord"){
        let temp = inP1
        inP1 = inP2,
        inP2 = temp
    }
    let point1 = new openCascade.gp_Pnt_3(inP1.x, inP1.y, 0)
    let point2 = new openCascade.gp_Pnt_3(inP2.x, inP2.y, 0)
    let anArcOfCircle = new openCascade.GC_MakeArcOfCircle_3(circle,point1, point2,  false)
    const mEdge = new openCascade.BRepBuilderAPI_MakeEdge_24(new openCascade.Handle_Geom_Curve_2(anArcOfCircle.Value().get())).Edge();
    let adpCurve = new openCascade.BRepAdaptor_Curve_2(mEdge)

    let t1 = adpCurve.FirstParameter();
    let t2 = adpCurve.LastParameter();
    let dt = (t2-t1)/25.0
    let hGeomCurve = openCascade.BRep_Tool.Curve_2(mEdge,t1,t2)

    for (let t = t1; t <= t2; t+=dt) {
        let point = hGeomCurve.get().Value(t);
        customArcEntities.points.push( new THREE.Vector3( point.X(), point.Y(), 0  ) );
      }        
      let point = hGeomCurve.get().Value(t2);
      customArcEntities.points.push( new THREE.Vector3( point.X(), point.Y(), 0  ) );
    return customArcEntities
}

export function makeCustomProfile(profileData,oc) {
    if (!profileData || profileData.edges.length == 0 || !profileData.normal ) return
    const mkWire = new oc.BRepBuilderAPI_MakeWire_1();
    let normal = profileData.normal
    profileData.edges.forEach(edge => {
        let type = edge.type
        if (type === "line") { //Line
            let startPoint = edge.startPoint
            let endPoint = edge.endPoint
            let point1 = new oc.gp_Pnt_3(startPoint.x, startPoint.y, startPoint.z)
            let point2 = new oc.gp_Pnt_3(endPoint.x, endPoint.y, endPoint.z)
            const mEdge = new oc.BRepBuilderAPI_MakeEdge_3(point1, point2)
            const wire = new oc.BRepBuilderAPI_MakeWire_2(mEdge.Edge())
            mkWire.Add_2(wire.Wire());
        }
        else if (type === "arch") { //Arc
            let p1  = edge.startPoint
            let p2  = edge.endPoint
            let radius = edge.radius
            // let center = calculateCircleCenter(p1, p2, radius)
            let center = edge.center
            let circleCenter = new oc.gp_Pnt_3(center.x, center.y, center.z)
            let circleNormal = new oc.gp_Dir_4(normal.x, normal.y, normal.z)
            let circle = new oc.gp_Circ_2(new oc.gp_Ax2_3(circleCenter, circleNormal), radius)
            let point1 = new oc.gp_Pnt_3(p1.x, p1.y, p1.z)
            let point2 = new oc.gp_Pnt_3(p2.x, p2.y, p2.z)
            let anArcOfCircle = new oc.GC_MakeArcOfCircle_3(circle, point1, point2, false)
            const mEdge = new oc.BRepBuilderAPI_MakeEdge_24(new oc.Handle_Geom_Curve_2(anArcOfCircle.Value().get())).Edge();
            let wire = new oc.BRepBuilderAPI_MakeWire_2(mEdge);
            mkWire.Add_2(wire.Wire());
        }
    });


    const myWireProfile = mkWire.Wire();

    const myFaceProfile = new oc.BRepBuilderAPI_MakeFace_15(myWireProfile, false);
    const aPrismVec     = new oc.gp_Vec_4(-extrudeThickness * normal.x, -extrudeThickness * normal.y, -extrudeThickness * normal.z);
    let myBody          = new oc.BRepPrimAPI_MakePrism_1(myFaceProfile.Face(), aPrismVec, false, true);
    return myBody
}
//Volume
export function findVolumeFromShape(oc ,inShape) {
    let props = new oc.GProp_GProps_1()
    let volumeProps = new oc.BRepGProp.VolumeProperties_1(inShape, props, true, true, true)
    let volume = props.Mass()
    return volume
}
//Surface Area
export function findSurfaceAreaFromShape(oc ,inShape) {
    let props = new oc.GProp_GProps_1()
    let surfaceProps = new oc.BRepGProp.SurfaceProperties_1(inShape, props, true, true)
    let area = props.Mass()
    return area
}