import { removeObjWithChildren } from "../utils/customUtils"
import { addClassNameUsingClass, removeClassNameUsingClass } from "../utils/utils"

export class HandleObjSequence{
    constructor(params){
        this.entities = []
    }
    addEntity(obj){
        this.entities.push(obj)
        removeClassNameUsingClass("goPreviousObj","menuDisable")
    }
    goPreviousState(){
        let lastEntity = this.entities[this.entities.length -1]
        if (lastEntity){
            if (lastEntity.parent){
                removeObjWithChildren(lastEntity)
                this.entities.pop()
            }else{
                this.entities.pop()
                this.goPreviousState()
            }
        }
    }
    reset(){
        this.entities = []
        addClassNameUsingClass("goPreviousObj","menuDisable")
    }
}