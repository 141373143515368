import { PartTreeForAddParts } from "../threeCustom/partTree/partTree.js"
import { nodeApi, odooApi } from "../utils/apiRequest/request.js"
import { calculateMassProperties, countOccurrencesInArray, getShapeStepFileBlob } from "../utils/commonFunction.js"
import { blobToFile, getDimensionOfObject, roundDigit } from "../utils/customUtils.js"
import { getDataFromTag, getInnerHtmlFromTag, getSubmitFormData } from "../utils/submitData.js"
import axios from 'axios';
import { environmentVariable } from "../variables.js"
import { removeLoader, showLoader } from "../utils/loaders/loader.js"
import { TorusGeometry } from "three"
import { onWindowResize } from "../setupTHREE.js"
import { DCShape } from "../threeCustom/DCShape.js"
import { BackendApiCall } from "./addToCartBackendApiCall.js"
import { customAlert } from "../utils/utils.js"
import { odooApiV2 } from "../utils/apiRequest/requestV2.js"
import { addToCart } from "./addToCartOnly.js"

let partNameArray = []
let gPrice = 0
let partTreeClass
let backendApiCall = new BackendApiCall()
export default function addToCartEvents(params){
    let threeViewer = params.threeViewer
    let addToCartEle = document.getElementById("addToCart")
    addToCartEle.addEventListener("click",()=>   handleAddToCartBtn({filename:threeViewer.filename,threeViewer}))
    let proceedToProcurementEle = document.getElementById("proceedToProcurement")
    proceedToProcurementEle.addEventListener("click",(event)=> handleAddPartForm({event,threeViewer}))
    let addPartsFormEle = document.getElementById("addPartsForm")
    addPartsFormEle.addEventListener("submit",(event)=> event.preventDefault())
    let cartPrinterFormEle = document.getElementById("cartPrinterForm")
    cartPrinterFormEle.addEventListener("submit",(event)=> handleCartPrinterForm({event,threeViewer}))
    let resetColorEle = document.getElementById("resetColor")
    resetColorEle.addEventListener("click",(event)=> resetForm({event,threeViewer}))
    
    let addToCartCloseEle = document.getElementsByClassName("addToCartClose")
    Array.from(addToCartCloseEle).forEach((ele)=>{
        ele.addEventListener("click",(event)=> handleAddToCartClose({event,threeViewer}))
    })

    // addPartsFormEle.addEventListener("submit",(event)=> addParts({event,filename:threeViewer.filename,threeViewer}))
}
function handleAddToCartClose(params) {
    let ele = document.getElementById("addToCartInputModal")
    ele.classList.add("displayNone")
    let threeViewer = params.threeViewer
    threeViewer.camera.zoom = 3
    let block1Box = document.getElementById("block1Box")
    block1Box.style = "min-height: calc(100vh - 234px);max-height: calc(100vh - 234px);"
    document.getElementById("partTree").classList.remove("displayNone")
    const viewport = document.getElementById("viewport");
    viewport.style = "calc(100% - 310px);;"
    const viewportRect = viewport.getBoundingClientRect();
    onWindowResize(viewportRect.width, viewportRect.height)
    document.getElementById("addToCartInputModal").classList.add("displayNone")
    document.getElementsByClassName("icon-list")[0].style = "left : 50%"

    // Enable edit face(sketchBtn) button
    let editFaceBtn = document.getElementById("sketchBtn")
    editFaceBtn.classList.remove("menuDisable")
}

async function handleAddToCartBtn(params){

    if (threeViewer.addToCart.isAddToCart)
        addToCart(params);
    else
        await openModalForAddParts(params);

}


async function openModalForAddParts(params){
    await showLoader()
    // Disable edit face(sketchBtn) button
    let editFaceBtn = document.getElementById("sketchBtn")
    editFaceBtn.classList.add("menuDisable")
    let modelColorPickerLabel = document.getElementById("modelColorPickerLabel")
    modelColorPickerLabel.classList.add("menuDisable")

    let threeViewer = params.threeViewer
    let shape = threeViewer.scene.getObjectByName("shape")
    if (!shape) {
        customAlert("Please load the model first")
        return false
    }
    let shapeObj = threeViewer.scene.getObjectByName("shape")
    partTreeClass = new PartTreeForAddParts(shapeObj,"partTreeCart",threeViewer) // Make part tree for add parts
    await partTreeClass.parseScene(partTreeClass.object)
    threeViewer.camera.zoom = 2.6
    let block1BoxEle = document.getElementById("block1Box").style = "min-width:50%;max-width:50%;"
    let partTreeEle = document.getElementById("partTree").classList.add("displayNone")
    const viewport = document.getElementById("viewport");
    viewport.style = "width : calc(100% - 52%);"
    const viewportRect = viewport.getBoundingClientRect();
    onWindowResize(viewportRect.width, viewportRect.height)
    document.getElementById("addToCartInputModal").classList.remove("displayNone")
    document.getElementsByClassName("icon-list")[0].style = "left : 76.5%"
    updateCartPrice()

    removeLoader()

}

async function createOrder(params) {
    let partCounter = 0
    let threeViewer = params.threeViewer
    
    // START LOADER
    await showLoader()

    // GET FILENAME
    const currentTimestamp = new Date().getTime();
    let mainAssemblyEle = document.getElementsByClassName("mainAssembly")[0]
    let originalName = mainAssemblyEle.getAttribute("objOriginalName")
    let originalNameTimeStamp = originalName + "_" + currentTimestamp

    let orderData = await createSalesOrderApi()
    if (orderData){
        document.getElementById("partTreeCart").innerHTML = ""
        loader.classList.add("displayNone")

        let id = orderData.salesOrderData.message.split(":")[1];
        customAlert("Order placed successfully. Your sales order number is " + id);
        closeAddToCart();

    }

    removeLoader()
    partNameArray.length = 0

    // FINISH 

    async function createSalesOrderApi() {

        let mainAssembly = document.getElementsByClassName("mainAssembly")
        let assemblyParts = document.getElementsByClassName("partShape")
        let apiMakerData = apiMakerDataFunction(assemblyParts, currentTimestamp)
        let assemblyApiMakerData = apiMakerDataFunction(mainAssembly, currentTimestamp)

        let assemblyPartsCount = 1;
        assemblyPartsCount = Array.from(assemblyParts).length


        let originalName = mainAssembly[0].getAttribute("objOriginalName")
        let sequenceName = mainAssembly[0].getAttribute("objSequenceName")
        let originalNameTimeStamp
        if (originalName)
            originalNameTimeStamp = originalName
        else console.warn("originalName is not defined in bom api")

        let assemblyData,partQuantities,res1,partQuantitiesValue
        if (assemblyPartsCount === 1 ){
            console.log(assemblyApiMakerData,"assemblyApiMakerData")
            assemblyData = assemblyApiMakerData[sequenceName]
            partQuantities=  document.getElementById("partQuantities")
            partQuantitiesValue = Number(partQuantities.value)
            res1 = await addPartNumberAndProductOneShape(apiMakerData,"partShape",assemblyPartsCount)
            if (!res1){
                customAlert("Something went wrong, please try again later")
                return false
            }
        }else{
            assemblyApiMakerData = apiMakerDataFunction(mainAssembly, currentTimestamp)
            console.log(assemblyApiMakerData,"assemblyApiMakerData")
            assemblyData = assemblyApiMakerData[sequenceName]
            partQuantities=  document.getElementById("partQuantities")
            partQuantitiesValue = Number(partQuantities.value)
            res1 = await addPartNumberAndProduct(assemblyApiMakerData,"mainAssembly",assemblyPartsCount)
            if (!res1){
                customAlert("Something went wrong, please try again later")
                return false
            }
        }
        let mainAssemblyFilePath = res1.filePaths[0]
        let mainAssemblyId = res1.data.mainAssemblyId
        let mainAssemblyEngineeringCode = res1.data.engineeringCode
        

        if (assemblyPartsCount > 1){ // for part shape only
            console.log(apiMakerData,"partData")
            let res2 = await addPartNumberAndProduct(apiMakerData,"partShape",mainAssemblyId,assemblyPartsCount)
            if (!res2){
                customAlert("Something went wrong, please try again later")
                return false
            }
            console.log(apiMakerData,"apiMakerData for shape")

            // Create bom that include main shape and object of part shape
            let bomData = await backendApiCall.createBom(apiMakerData,mainAssemblyEngineeringCode)
            if (!bomData) {
                customAlert("Something went wrong, please try again later")
                return false
            }

        }

        let count,productName,salesOrderUrl

        count = assemblyPartsCount == 1 ? "_2": ""
        productName = mainAssemblyEngineeringCode
        // productName = originalNameTimeStamp + count
        salesOrderUrl = "create_sales_order"
            
        let partnerName = threeViewer.user.name
        let salesOrderBody = {
            "partner_name": partnerName,
            "file_path": mainAssemblyFilePath,
            "order_lines": [
                {
                    "product_name": productName,
                    "quantity": partQuantitiesValue,
                    "unit_price": gPrice
                }
            ]
            // Add any other fields you want to populate in the sale.order record
        }

        console.log(salesOrderBody,"salesOrderBody")
        let salesOrderData = await odooApi.post(salesOrderUrl,
            salesOrderBody
        )
        console.log(salesOrderData, "salesOrderData")
        if (!salesOrderData) {
            customAlert("Something went wrong, please try again later")
            return false
        }
        if (salesOrderData.error) {
            customAlert(salesOrderData.error)
            return false
        }else{
            return {salesOrderData}
        }
    }

    
    async function addPartNumberAndProduct(apiMakerData,type,mainAssemblyId,assemblyPartsCount) {
        let filePaths = []
        let returnData = {}
        let estimatedDays = 0
        for (const [key, partData] of Object.entries(apiMakerData)) {
            let context              = {}
            context["type"]          = type
            context["name"]          = partData.originalNameTimeStamp
            context["abbreviation"]  = partData.abbreviation
            context["printquality"]  = partData.printquality
            context["leadtime"]      = partData.leadtime
            context["weight"]        = partData.weight
            context["materials"]     = partData.materials
            context["infill"]        = partData.infill
            context["color"]         = partData.color
            context["volume"]        = partData.volume
            context["leadtimeValue"]        = partData.leadtimeValue
            // Add dimensions
            let obj  = threeViewer.scene.getObjectByProperty("sequenceName",partData.sequenceName)
            let dimensions = getDimensionOfObject(obj)
            if (dimensions){
                context["length"] = dimensions.x
                context["width"] = dimensions.y
                context["height"] = dimensions.z
            }

            let filePath = "https://www.w3schools.com/images/img_certification_up_generic_html_300.png",engineeringCode
            // try {
                // UPLOAD STEP FILE USING NAME
                let stepFileData = await uploadStepFile(partData.originalNameTimeStamp,partData.sequenceName)
                if (!stepFileData) {
                    return false
                }
                if (!environmentVariable.development){
                    filePath = environmentVariable.REACT_APP_DatabaseServer + `add-to-cart/download-model?model_name=${stepFileData.file_name}`
                }
                filePaths.push(filePath)
                
                
                // GET PART ID FROM BACKEND
                if (type === "mainAssembly") {

                    // set estimated days as for main assembly 
                    estimatedDays = partTreeClass.estimatedTime.totalHours
                    let assemblyBody = {
                        "fileName"    : filePath,
                        "assemblyName": partData.originalNameTimeStamp,
                        "userName"    : threeViewer.user.name
                    }
                    let backendEngineeringCode =  await backendApiCall.getEngineeringCodeAssembly(assemblyBody)
                    engineeringCode = backendEngineeringCode.engineeringCode
                    returnData.mainAssemblyId = backendEngineeringCode.mainAssemblyId
                    returnData.engineeringCode = engineeringCode

                }
                else if (type === "partShape"){
                    partCounter += 1
                    let mainAssemblyId2 = mainAssemblyId ? mainAssemblyId : ""
                    let partCode = "CSF00000" + mainAssemblyId2
                    engineeringCode = partCode + "-" + partCounter
                    
                    console.log(engineeringCode,"engineeringCode")
                }

                // Save Engineering code in apiMakerData for future use (in Bom api)
                partData.engineeringCode = engineeringCode;
                let partBody = {
                    "name": partData.originalNameTimeStamp,
                    "code": engineeringCode,
                    "description": "Created from API",
                    "is_assigned": 0,
                }
                console.log(partBody,"partBody")
                let partNumber = await odooApi.post('insert_part_number', partBody);
                console.log(partNumber,"partNumber")
                if (!checkMessage(partNumber)) return false
                context["engineering_code1"] = engineeringCode,
                context["days"]          = estimatedDays,
                context["file_path"]     = environmentVariable.development ? "https://www.w3schools.com/images/img_certification_up_generic_html_300.png" : filePath

                debugger

                if (type === "mainAssembly" ) {

                    context["abbreviation"]  = ""
                    context["printquality"]  = ""
                    context["leadtime"]      = ""
                    context["weight"]        = partData.weight
                    context["materials"]     = ""
                    context["infill"]        = ""
                    context["color"]         = ""
                    context["volume"]        = partData.volume
                    context["leadtimeValue"] = partData.leadtimeValue
                    context["price"] = gPrice

                    let createProduct = await nodeApi.post('admin/create-order-main',context);

                    console.log(createProduct,"productCreatein Server");
                    if (createProduct.status === 500){
                        console.warn(createProduct,"e productCreatein Server")
                        return false
                    }

                }else{

                    let createProduct = await nodeApi.post('admin/create-order',context);
                    console.log(createProduct,"productCreatein Server");

                }
   
                
               

            // } catch (error) {
            //     console.error(error, "error at this part\n", partData.originalNameTimeStamp)
            //     return false
            // }
        }
        return {filePaths, data : returnData}
    }
    async function addPartNumberAndProductOneShape(apiMakerData) {
        let filePaths = []
        let returnData = {}
        let estimatedDays = 0
        for (const [key, partData] of Object.entries(apiMakerData)) {
            let context              = {}
            context["type"]          = "mainShape"
            context["name"]          = partData.originalNameTimeStamp
            context["abbreviation"]  = partData.abbreviation
            context["printquality"]  = partData.printquality
            context["leadtime"]      = partData.leadtime
            context["weight"]        = partData.weight
            context["materials"]     = partData.materials
            context["infill"]        = partData.infill
            context["color"]         = partData.color
            context["volume"]        = partData.volume
            context["leadtimeValue"]        = partData.leadtimeValue
            // Add dimensions
            let obj  = threeViewer.scene.getObjectByProperty("sequenceName",partData.sequenceName)
            let dimensions = getDimensionOfObject(obj)
            if (dimensions){
                context["length"] = dimensions.x
                context["width"] = dimensions.y
                context["height"] = dimensions.z
            }

            let filePath = "https://www.w3schools.com/images/img_certification_up_generic_html_300.png",engineeringCode
            try {
                // UPLOAD STEP FILE USING NAME
                let stepFileData = await uploadStepFile(partData.originalNameTimeStamp,partData.sequenceName)
                if (!stepFileData) {
                    return false
                }
                if (!environmentVariable.development){
                    filePath = environmentVariable.REACT_APP_DatabaseServer + `add-to-cart/download-model?model_name=${stepFileData.file_name}`
                }
                filePaths.push(filePath)
                
                
                // GET PART ID FROM BACKEND


                    // set estimated days as for main assembly 
                    estimatedDays = partTreeClass.estimatedTime.totalHours
                    let assemblyBody = {
                        "fileName"    : filePath,
                        "assemblyName": partData.originalNameTimeStamp,
                        "userName"    : threeViewer.user.name
                    }
                    let backendEngineeringCode =  await backendApiCall.getEngineeringCodeAssembly(assemblyBody)
                    engineeringCode = backendEngineeringCode.engineeringCode
                    returnData.mainAssemblyId = backendEngineeringCode.mainAssemblyId
                    returnData.engineeringCode = engineeringCode

                // Save Engineering code in apiMakerData for future use (in Bom api)
                partData.engineeringCode = engineeringCode;
                let partBody = {
                    "name": partData.originalNameTimeStamp,
                    "code": engineeringCode,
                    "description": "Created from API",
                    "is_assigned": 0,
                }
                console.log(partBody,"partBody")
                let partNumber = await odooApi.post('insert_part_number', partBody);
                console.log(partNumber,"partNumber")
                if (!checkMessage(partNumber)) return false

                context["engineering_code1"] = engineeringCode,
                context["days"]          = estimatedDays,
                context["file_path"]     = environmentVariable.development ? "https://www.w3schools.com/images/img_certification_up_generic_html_300.png" : filePath;

                let createProduct = await nodeApi.post('admin/create-order',context);
                console.log(createProduct,"productCreatein Server");

            } catch (error) {

                console.error(error, "error at this part\n", partData.originalNameTimeStamp)
                return false

            }
        }
        return {filePaths, data : returnData}
    }
}

function apiMakerDataFunction2(propertiesEles, currentTimestamp) {
    let apiMakerData = {}
    for (const ele of Array.from(propertiesEles)) {
        let propertyValues = getDataFromTag(ele)

        // If don't print is checked then continue
        if (propertyValues.checkPrint) continue

        // GET THREE.JS OBJECT INFO
        let sequenceName = ele.getAttribute("objSequenceName")
        let originalName = ele.getAttribute("objOriginalName")

        // push object name to count occurrence of name
        partNameArray.push(originalName)

        // Add count of part how many time that shape come
        let needCount = ""
        let count = countOccurrencesInArray(partNameArray,originalName)
        if (count > 1) needCount = "_" + (count - 1)
        let originalNameTimeStamp = originalName + needCount

        // GET PROPERTIES INFO
        let {cartAbbreviation,cartInfill,cartMaterial,cartPrintQuality,pLayerDeposition,pCartMaterial} = getInnerHtmlFromTag(ele)

        let cartMLTimeEle = document.getElementById("cartLeadTime")
        const selectedOption = cartMLTimeEle.options[cartMLTimeEle.selectedIndex];
        const cartMLTime = selectedOption.textContent;
        
        let PostPrintQuality = cartMaterial ,
            postMaterial = cartPrintQuality
        let cartAbbreviationEle = ele.getElementsByClassName("cartAbbreviation")
        let cartAbbreviationValue
        if (cartAbbreviationEle.length > 0) cartAbbreviationValue = cartAbbreviationEle[0].value
        else return false
        let partColorPickerEle = ele.getElementsByClassName("partColorPicker")
        let partColorPicker
        if (partColorPickerEle.length > 0) partColorPicker = partColorPickerEle[0].value
        else return false
        // let partColorPicker = ele.getElementsByClassName("partColorPicker")[0].value

        if (cartAbbreviationValue == "projectionPrinting") {
            PostPrintQuality = pLayerDeposition
            postMaterial     = pCartMaterial
            cartInfill       = ""
        }
        let quantityEle = document.getElementById("partQuantities")
        let val= quantityEle.value
        let quantity = Number(val)
        if (quantity > 0) {
            let partPrice = getPriceForShape({
                element : ele
            })
            // PUSH THE DATA
            apiMakerData[sequenceName] = {
                counts: quantity,
                originalName,
                sequenceName,
                originalNameTimeStamp,
                name        : originalNameTimeStamp,
                abbreviation : cartAbbreviation,
                printquality : PostPrintQuality,
                leadtime     : cartMLTime,
                materials    : postMaterial,
                infill       : cartInfill,
                price        : partPrice.price,
                weight       : partPrice.weight,
                color        : partColorPicker,
                volume       : roundDigit(partPrice.volume),
                engineeringCode : "",
            }
        }else{
            console.warn("Quantity is 0")
        }
    }
    return apiMakerData
}
function apiMakerDataFunction(propertiesEles, currentTimestamp) {
    let apiMakerData = {}

    let quantityEle = document.getElementById("partQuantities")
    let val         = quantityEle.value
    let quantity    = Number(val)
    if (quantity < 1) return apiMakerData // Quantity should not 0 or in negative
    else console.warn("Quantity is 0")

    let cartMLTimeEle = document.getElementById("cartLeadTime")
    const selectedOption = cartMLTimeEle.options[cartMLTimeEle.selectedIndex];
    const cartMLTime = selectedOption.textContent;
    const cartMLTimeVal = cartMLTimeEle.value;
    for (const ele of Array.from(propertiesEles)) {

        let context = {}
        context["counts"] = quantity
        context["leadtime"]  = cartMLTime
        context["leadtimeValue"]  = cartMLTimeVal
        
        let propertyValues = getDataFromTag(ele)
        // debugger
        console.log("html",context)
        // If don't print is checked then continue
        if (propertyValues.checkPrint) continue

        context["abbreviation"] = propertyValues.cartAbbreviation
        context["color"] = propertyValues.partColor

        if (context["abbreviation"] == "fdm") {
            context["printquality"] = propertyValues.cartPrintQuality
            context["materials"]    = propertyValues.cartMaterial
            context["infill"]       = propertyValues.cartInfill
        }
        if (context["abbreviation"] == "projectionPrinting") {
            context["printquality"] = propertyValues.pLayerDeposition
            context["materials"]    = propertyValues.pCartMaterial
            context["infill"]       = ""
        }

        // ADD THREE.JS OBJECT INFO
        let sequenceName = ele.getAttribute("objSequenceName")
        let originalName = ele.getAttribute("objOriginalName")
        context["sequenceName"] = sequenceName
        context["originalName"] = originalName

        
        partNameArray.push(originalName) // push object name to count occurrence of name
        // Add count of part how many time that shape come
        let needCount = ""
        let count = countOccurrencesInArray(partNameArray,originalName)
        if (count > 1) needCount = "_" + (count - 1)
        let originalNameTimeStamp = originalName + needCount
        context["originalNameTimeStamp"] = originalNameTimeStamp
        context["name"] = originalNameTimeStamp

        // add Volume
        let partVolume = getVolume({
            element : ele
        })
        context["volume"]  = partVolume.volume

        // PUSH THE DATA
        apiMakerData[sequenceName] = context
    }
    return apiMakerData
}

export class CreateSalesOrderForPDF{
    constructor(params){
        this.file = params.file
        this.filePath = ""
        this.name = this.file.name
        this.init()
    }
    async init(){
        let scope = this
        showLoader()
        try {  
            let filePath = await this.uploadAndGetFilePath()
            this.engineeringCode = await this.generateEngineeringCode()
            let partNumber = await this.insertPartNumber()
            if (!partNumber) {removeLoader();scope.somethingWentWrongAlert();return}
            let product = await this.createProduct()
            if (!product) {removeLoader();scope.somethingWentWrongAlert();return}
            // let bomData = await this.createBom()
            // if (!bomData) {removeLoader();scope.somethingWentWrongAlert();return}
            let salesOrderData = await this.createSalesOrder()
            if (!salesOrderData) {removeLoader();scope.somethingWentWrongAlert();return}
            
            customAlert("The request for an estimate has been sent successfully.")
        } catch (error) {
            removeLoader()
            scope.somethingWentWrongAlert()
        } finally {
            removeLoader()
        }
    }
    somethingWentWrongAlert(){
        customAlert("Something went wrong while processing request.")
    }
    uploadPdf() {
        let scope = this
        return new Promise((resolve, reject) => {
                const data = new FormData();
                data.append('pdfFile', scope.file);
                axios.post(`${environmentVariable.REACT_APP_DatabaseServer}add-to-cart/upload-pdf`, data)
                    .then((res) => {
                        if (res.data.error) {
                        console.error("While uploading pdf file using axios went wrong",res.data.error)
                            reject("something went wrong at add-to-cart/upload-pdf")
                            return false
                        }else{
                            let response = res.data.data
                            if (response)
                            resolve(response)
                        else reject("response is not defined")
                        }
                    })
                    .catch((error) => {
                        console.error("An error occurred:", error);
                        reject("While uploading step file using axios went wrong")
                        return false
                    });
    
        });
    }
    async uploadAndGetFilePath(){
        let scope = this
        let pdfData = await this.uploadPdf()
        if (!pdfData) {
            return false
        }
        if (!environmentVariable.development){
            scope.filePath = environmentVariable.REACT_APP_DatabaseServer + `add-to-cart/download-pdf?pdf_name=${pdfData.file_name}`
        }else{
            scope.filePath = "https://www.w3schools.com/images/img_certification_up_generic_html_300.png"
        }
    }
    async generateEngineeringCode(){
        let scope = this
        let assemblyBody = {
            "fileName"    : this.filePath,
            "assemblyName": scope.name,
            "userName"    : threeViewer.user.name
        }
        let backendEngineeringCode = await backendApiCall.getEngineeringCodeAssembly(assemblyBody)
        let engineeringCode = backendEngineeringCode.engineeringCode
        scope.mainAssemblyId = backendEngineeringCode.mainAssemblyId
        return engineeringCode
    }
    async insertPartNumber(){
        let scope = this
        let partBody = {
            "name": scope.name,
            "code": scope.engineeringCode,
            "description": "Created from API",
            "is_assigned": 0,
        }
        console.log(partBody,"partBody")
        let partNumber = await odooApi.post('insert_part_number', partBody);
        console.log(partNumber,"partNumber")
        if (!checkMessage(partNumber)) return false
        return true
    }
    async createProduct(){
        let scope = this
        let apiContext = {
            abbreviation :"FDM",
            color :"White",
            days :1,
            engineering_code1 :scope.engineeringCode,
            file_path : scope.filePath,
            height :104.02867889404297,
            infill :"40 %",
            leadtime :"1-2 days",
            length :322.24500274658203,
            materials :"0.15 mm",
            name : scope.name,
            price :3457.731,
            printquality :"ABS",
            volume :92.047,
            weight :95.73,
            width :79.912109375,
        }
        let createProduct = await odooApiV2.post("create_product", apiContext);
        console.log(createProduct,"productCreatein Server");
        if (!checkMessageV2(createProduct)){
            return
        }else return true
    }
    async createBom(){
        let scope = this
        let dummyBomLine = []
        let dummyLineData = {
            "partnumber": "CSF00000" + scope.mainAssemblyId + "-1",
            "product_qty": 1
        }
        dummyBomLine.push(dummyLineData)
        let bomData = await backendApiCall.apiCreateBom(scope.name,dummyBomLine)
        if (!bomData) {
            customAlert("Something went wrong, please try again later")
            return false
        }
        return true
    }
    async createSalesOrder(){
        let scope = this
        let partnerName = threeViewer.user.name
        let salesOrderBody = {
            "partner_name": partnerName,
            "file_path": scope.filePath,
            "order_lines": [
                {
                    "product_name": scope.name,
                    "quantity": 1,
                    "unit_price": 1
                }
            ]
        }
        console.log(salesOrderBody,"salesOrderBody")
        let salesOrderData = await odooApi.post('create_sales_order',
            salesOrderBody
        )
        console.log(salesOrderData, "salesOrderData")
        if (!salesOrderData) {
            customAlert("Something went wrong, please try again later")
            return false
        }
        if (salesOrderData.error) {
            customAlert(salesOrderData.error)
            return false
        }else{
            return true
        }
    }
}
function createSalesOrderForPDF(){
    let filePath = uploadPdf()
    let engineeringCode = generateEngineeringCode()
    let partNumber = insertPartNumber()
    if (!partNumber) return
    let product = createProduct()
    if (!product) return
    let bomData = createBom()
    if (!bomData) return
    let salesOrderData = createSalesOrder()
    if (!salesOrderData) return
}

async function handleAddPartForm(params) {
    let e = params.event
    e.preventDefault()
    // updateCartPrice(params.oc)
    // updateCartPrice()
    // openHtmlModal("cartPrinterModal")
    // if (gPrice == 0) {
    //     customAlert("Please add one part for Proceed to Procurement.")
    //     return
    // }
    // if (!gPrice){
    //     customAlert("something went wrong, please try again later.")
    //     return
    // }
    await createOrder(params)
}

function handleCartPrinterForm(params) {
    let e = params.event
    e.preventDefault()
    // createOrder(params)
}
function getValueForProperties(){
    return {
        getMaterialValue: (material) =>
        {
            const materials = 
            {
                // FDM materials
                PLA: 1.24 * 1.50,
                ABS: 1.04 * 1.40,
                PETG:1.27 * 1.45,
                ASA: 1.07 * 2.00,
                TPU: 1.21 * 4.00,
                TPE: 1.14 * 4.00,

                // projection printing materials
                proBlk10        : 1.07 * 25.50,
                rigidWhite      : 1.09 * 30.00,
                tough60CWhite   : 1.15 * 30.00,
                rigidGrey       : 1.07 * 25.50,
                rubber65ABlk    : 1.03 *25.50,
                rubberBlk10     : 1.11 * 30.00,
                flexBlk20       : 1.11 * 30.00,
                highTemp150CFrBlack  : 1.20 * 32.50,
                toughClear      : 1.20 * 30.00,
                tough65CBlack   : 1.22 * 30.00,
            }
            let val = materials[material]
            if (val) return val
            else console.warn("material is not valid")
        },
        getPrintQuality: (quality) => 
        {
            const qualities = 
            {
                lD50: 2.5,
                lD40: 3.0,
                lD30: 3.5,
                lD20: 4.0,
            }
            let val = qualities[quality]
            if (val) return val
            else console.warn("quality is not valid")
        }
    }
}

function getPriceForShape(params){
    let { element } = params
    let shapeData = 0
    // get form data
    let formData = getDataFromTag(element)
    if (formData.checkPrint) return shapeData
    
    let originalName = element.getAttribute("objOriginalName")
    // get shape
    let sequenceName = element.getAttribute("objSequenceName")
    let shape  = threeViewer.scene.getObjectByProperty("sequenceName",sequenceName)
    if (!shape){
        console.warn("shape is not defined in update price");
        return
    } 
    

    let cartAbbreviation = formData.cartAbbreviation

    if (cartAbbreviation == "fdm"){
        shapeData = getFDMPrice(formData, shape)
    }else if (cartAbbreviation == "projectionPrinting"){
        shapeData = getProjectionPrintingPrice(formData,shape)
    }

    return shapeData
}

function getVolume( params) {

    let { element } = params

    // get shape
    let sequenceName = element.getAttribute("objSequenceName")
    let shape  = threeViewer.scene.getObjectByProperty("sequenceName",sequenceName)

    if (!shape){
        console.warn("shape is not defined in volume");
        return NaN
    } 

    let massProperties = calculateMassProperties(shape)
    let volume = massProperties.totalVolume / 1000 // volume is in mm3, convert in cm3
    return {volume}
}
function getFDMPrice(formData, shape) {
    let price = 0
    let cartMaterialName = formData.cartMaterial
    let cartPrintQuality = Number(formData.cartPrintQuality)
    let cartInfill = Number(formData.cartInfill)
    let cartMLTime = document.getElementById("cartLeadTime").value

    // calculate material value from material name
    let unitCartMaterial = getValueForProperties().getMaterialValue(cartMaterialName)
    let massProperties = calculateMassProperties(shape)

    let weight = calculateWeight(cartMaterialName,massProperties.totalVolume)
    weight = roundDigit(weight,2)
    let volume = massProperties.totalVolume / 1000 // volume is in mm3, convert in cm3
    console.log("volume",volume)
    let cartMaterial = volume * unitCartMaterial

    price = (cartMaterial + (cartMaterial * cartPrintQuality) + (cartMaterial * cartInfill)) * (cartMLTime / 100)
    price = roundDigit(price)
    return {price,weight,volume}
}

function getProjectionPrintingPrice(formData, shape){
    let price

    let pCartMaterialName = formData.pCartMaterial
    // let cartMLTime = Number(formData.cartMLTime)
    let cartMLTime = document.getElementById("cartLeadTime").value
    
    let pLayerDeposition = formData.pLayerDeposition
    let pPrintQuality = getValueForProperties().getPrintQuality(pLayerDeposition)

    // calculate material value from material name
    let unitCartMaterial = getValueForProperties().getMaterialValue(pCartMaterialName)
    let massProperties = calculateMassProperties(shape);
    let weight = calculateWeight(pCartMaterialName,massProperties.totalVolume)
    let volume = massProperties.totalVolume / 1000 // volume is in mm3, convert in cm3
    let cartMaterial = volume * unitCartMaterial
    price = (cartMaterial + (cartMaterial * pPrintQuality)) * (cartMLTime / 100)
    price = roundDigit(price)
    console.log(`volume=${volume} & price=${price}`,)
    return {price,weight,volume}
}

export async function updateCartPrice(){
    gPrice = 0
    let mainQuantityEle = document.getElementById("partQuantities")
    // let mainQuantityEle = mainQuantityEles[0]
    if (!mainQuantityEle){
        console.warn("main assembly quantity not found.")
        updateHtml()
        return false
    }
    let mainQuantity = Number(mainQuantityEle.value) 
    if (!mainQuantity){
        console.warn("Quantity is not valid maybe its 0",mainQuantity)
        updateHtml()
        return false
    }
    let eles = document.getElementsByClassName("partShape")
    let assemblyParts = document.getElementsByClassName("partShape")
    let apiMakerData = apiMakerDataFunction(assemblyParts)
    partNameArray.length = 0
    let price = await getPriceForAssembly(apiMakerData,"partShape")
    if (!price) return false
    gPrice = mainQuantity * price.price

    updateHtml()

    function updateHtml() {
        gPrice = roundDigit(gPrice, 2)
        let cartPriceEle = document.getElementById("cartPrice")
        cartPriceEle.innerHTML = gPrice
    }

}

async function getPriceForAssembly(apiMakerData, type) {
    let returnData = {}
    let price = 0
    for (const [key, partData] of Object.entries(apiMakerData)) {
        let context = {}
        context["type"] = type
        context["abbreviation"] = partData.abbreviation
        context["printquality"] = partData.printquality
        context["leadtime"] = partData.leadtime
        context["materials"] = partData.materials
        context["infill"] = partData.infill
        context["price"] = partData.price
        context["color"] = partData.color
        context["volume"] = partData.volume
        context["leadtimeValue"] = partData.leadtimeValue
        try {
            let priceRes = await nodeApi.post('admin/getPrice', context);
            if (priceRes && priceRes.price){
                price += priceRes.price
            }else{
                return false
            }
        } catch (error) {
            console.error(error, "error at this part\n", partData.originalNameTimeStamp)
            return false
        }
    }

    return {price}
}
export function updateCartPrice2(){
    gPrice = 0
    let mainQuantityEle = document.getElementById("partQuantities")
    // let mainQuantityEle = mainQuantityEles[0]
    if (!mainQuantityEle){
        console.warn("main assembly quantity not found.")
        updateHtml()
        return
    }
    let mainQuantity = Number(mainQuantityEle.value) 
    if (!mainQuantity){
        console.warn("Quantity is not valid maybe its 0",mainQuantity)
        updateHtml()
        return
    }
    let eles = document.getElementsByClassName("partShape")
    let assemblyParts = document.getElementsByClassName("partShape")
    let apiMakerData = apiMakerDataFunction(assemblyParts, currentTimestamp)
    let res2 = addPartNumberAndProduct(apiMakerData,"partShape",mainAssemblyId)

    for (const element of eles) {
        let partPrice = getPriceForShape({
            element
        })
        if (partPrice) gPrice += (mainQuantity * partPrice.price)
        else {
            console.warn(`can not calculate price for part ${element.getAttribute("objOriginalName")}`)
        }
    }
    updateHtml()

    function updateHtml() {
        gPrice = roundDigit(gPrice, 2)
        let cartPriceEle = document.getElementById("cartPrice")
        cartPriceEle.innerHTML = gPrice
    }

}

function addPercentageToPrice(originalPrice, percentageToAdd) {
  const amountToAdd = (percentageToAdd / 100) * originalPrice;
  const totalPrice = originalPrice + amountToAdd;
  return totalPrice;
}
function calculateWeight(material, volume_mm3) {
    const densities = {
        PLA: 1.24,   // g/cm³
        ABS: 1.04,   // g/cm³
        PETG: 1.27,  // g/cm³
        ASA: 1.07,   // g/cm³
        TPU: 1.21,   // g/cm³
        TPE: 1.14,    // g/cm³

         // projection printing materials
         proBlk10        : 1.07,
         rigidWhite      : 1.09,
         tough60CWhite   : 1.15,
         rigidGrey       : 1.07,
         rubber65ABlk    : 1.03,
         rubberBlk10     : 1.11,
         flexBlk20       : 1.11,
         highTemp150CFrBlack  : 1.20,
         toughClear      : 1.20,
         tough65CBlack   : 1.22,
        // You can add more materials and their densities here
    };

    const density_g_cm3 = densities[material];

    if (density_g_cm3 === undefined) {
        console.error(`Density for material '${material}' not found.`);
        return;
    }

    // Convert volume from mm³ to cm³
    const volume_cm3 = volume_mm3 / 1000;

    // Calculate weight in grams
    const weight_grams = volume_cm3 * density_g_cm3;

    return weight_grams;
}

export async function uploadStepFile(fileName,seqName) {
    return new Promise((resolve, reject) => {
            const data = new FormData();
            let stepFileBlob = getShapeStepFileBlob(threeViewer,seqName);
            let stlFile = blobToFile(stepFileBlob, fileName)

            data.append('modelFile', stlFile);
            data.append('assemblyName', fileName);
            axios.post(`${environmentVariable.REACT_APP_DatabaseServer}add-to-cart/upload-step`, data)
                .then((res) => {
                    if (res.data.error) {
                    console.error("While uploading step file using axios went wrong",res.data.error)

                        reject("something went wrong at add-to-cart/upload-step")
                        return false
                    }else{
                        let response = res.data.data
                        if (response)
                        resolve(response)
                    else reject("response is not defined")
                    }
                })
                .catch((error) => {
                    console.error("An error occurred:", error);
                    reject("While uploading step file using axios went wrong")
                    return false
                });

    });

}

export function checkMessage(object){
    if (!object) return false

    let message = object.message
    if (!message) return false

    if (message.includes("created")) return true
    else return false
}
export function checkMessageV2(object){

    let message = object && object.res && object.res.message
    if (!message) return false

    if (message.includes("created")) return true
    else return false
}

function resetForm(params){
    let threeViewer = params.threeViewer
    let shape = threeViewer.scene.getObjectByName("shape")
    shape.traverse((ocShape) => {
        if (ocShape instanceof DCShape) {
            let allFaces = ocShape.getAllFaces()
            for (let i = 0; i < allFaces.length; i++) {
                let face = allFaces[i];
                face.material.color.set(face.material.originalColor)
            }
        }
    })
    let referenceShape = threeViewer.scene.getObjectByName("referenceShape")
    referenceShape.traverse((ocShape) => {
        if (ocShape instanceof DCShape) {
            let allFaces = ocShape.getAllFaces()
            for (let i = 0; i < allFaces.length; i++) {
                let face = allFaces[i];
                face.material.color.set(face.material.originalColor)
            }
        }
    })

    var form = document.getElementById("addPartsForm");

    // Use the reset() method to reset the form
    form.reset();

    // Reset inFill enable if it is disabled
    let cartInfills = document.getElementsByClassName("cartInfill")
    Array.from(cartInfills).forEach((ele)=>{
        ele.classList.remove("menuDisable")
    })

    let fdmEles = document.getElementsByClassName("FDM")
    if (fdmEles){
        Array.from(fdmEles).forEach((ele)=>{
            ele.classList.remove("displayNone")
            let materialsEles = ele.getElementsByClassName("aTCMaterials")
            Array.from(materialsEles).forEach((ele2)=>{
                partTreeClass.changeMaterialColorUseEle(ele2,false)
            });
        })

    } 

    let fdmEles2 = document.getElementsByClassName("projectionPrinting")
    if (fdmEles2){
        Array.from(fdmEles2).forEach((ele)=>{
            ele.classList.add("displayNone")
        })
    } 
    document.getElementById("modelColorPicker").value = "#" + (environmentVariable.originalModelColor).toString(16)
    updateCartPrice()

}

export function closeAddToCart(){
    document.getElementsByClassName("addToCartClose")[0].click()
    // Enable edit face(sketchBtn) button
    let editFaceBtn = document.getElementById("sketchBtn")
    editFaceBtn.classList.remove("menuDisable")
    let modelColorPickerLabel = document.getElementById("modelColorPickerLabel")
    modelColorPickerLabel.classList.remove("menuDisable")
}
