import { openHtmlModal } from "../../utils/utils";
export class EntityNotes{
    constructor(inViewer){
        this.threeViewer = inViewer;
        this.index = -1 // -1 indicates that add new note else edit that index
    }
    submitNoteForm(){
        if (!this.threeViewer.transformControl.object) return
        let noteTextEle = document.getElementById("noteText")
        let noteValue = noteTextEle.value
        let currentObject = this.threeViewer.transformControl.object
        let notes = currentObject.entityData.notes
        if(this.index == -1) notes.values.push(noteValue)
        else{
            notes.values[this.index] = noteValue
        }
        this.updateNoteDom()
    }
    deleteSelectedNote(){
        let currentObject = this.threeViewer.transformControl.object
        if (!currentObject) return
        if (!currentObject.entityData) return
        let notesArray    = currentObject.entityData.notes.values
        notesArray.splice(this.index,1)
        this.index = -1
        this.updateNoteDom()
    }
    updateNoteDom(){
        if (!this.threeViewer.transformControl.object) return

        document.getElementById("newNoteBtn").classList.remove("menuDisable")
        let notesValuesEle = document.getElementById("notesValues")
        let currentObject = this.threeViewer.transformControl.object
        if (!currentObject.entityData) return
        let notesArray    = currentObject.entityData.notes.values
        let html = ''
        for (let i = 0; i < notesArray.length; i++) {
            const noteValue = notesArray[i];
            html += `
            <div>
               <p> ${noteValue} </p>
               <button class="editNoteBtn"   noteId=${i}> Edit   </button>
               <button class="deleteNoteBtn" noteId=${i}> Delete </button>
            </div>
            `
        }
        notesValuesEle.innerHTML = html
        this.addEventListenersToNote()
    }
    addEventListenersToNote(){
        // This function will add events for Edit and Delete

        let scope             = this
        let editNoteBtnEles   = document.getElementsByClassName("editNoteBtn")
        let deleteNoteBtnEles = document.getElementsByClassName("deleteNoteBtn")

        Array.from(editNoteBtnEles).forEach((btn)=>{
            btn.addEventListener("click", (e)=>{
                scope.index = Number(btn.getAttribute("noteId"))
                this.openNotesHtmlModal()
            })
        })
        Array.from(deleteNoteBtnEles).forEach((btn)=>{
            btn.addEventListener("click", (e)=>{
                scope.index = Number(btn.getAttribute("noteId"))
                this.openDeleteConfirmModal()
            })
        })

    }
    openNotesHtmlModal(){
        let transformControl = this.threeViewer.transformControl
        // transform
        let currentObject = transformControl.object
        if (!currentObject) return
        openHtmlModal("noteFormModal")
        let notesArray = currentObject.entityData.notes.values
        let selectedValue
        if (this.index == -1) selectedValue = ""
        else selectedValue = notesArray[this.index]
        let inputEle = document.getElementById("noteText")
        inputEle.value = selectedValue
        inputEle.focus()
    }
    openDeleteConfirmModal(){
        if (!this.threeViewer.transformControl.object) return
        openHtmlModal("openDeleteConfirmModal")
    }
    reset(){
        document.getElementById("notesValues").innerHTML = "" // Make Notes Value Empty
        document.getElementById("newNoteBtn").classList.add("menuDisable")
    }

}