import { SVGLoader } from 'three/addons/loaders/SVGLoader.js';
import * as THREE from "three";
import { getRawDataFromFile } from '../utils/utils';
import { addBoundingBoxToParent, moveObjectToCenter, urlToFile } from '../utils/customUtils';
import { customImageLoader } from './imageLoader/customeImageLoader';

export class HandleImgUpload extends THREE.Object3D {
/**
 * Class constructor
 * @param {object} params - The parameters object
 * @param {boolean} [params.isGraphicImg=true] - Indicates if it's a graphic image (optional, default value is true) 
 *                                             - "no" - Not for Graphic Image - 
 * @param {object} params.threeViewer - The threeViewer object
 * @param {object} params.file - The file object
 * @param {string} params.svgRawData - The raw SVG data
 * @param {number} [params.size=0.05] - The size parameter (optional, default value is 0.05)
 * @param {string} params.sequenceFaceName - The sequence face name
 * @param {string} params.fileUrl - The file URL
 * @param {string} params.sketchType - The sketch type
 */
constructor(params){
    super();
    this.threeViewer = params.threeViewer;
    this.file = params.file;
    this.svgRawData = params.svgRawData;
    this.size = params.size || 0.05;
    this.sequenceFaceName = params.sequenceFaceName;
    this.fileUrl = params.fileUrl;
    this.isGraphicImg = params.isGraphicImg !== "no";
    this.sketchType = params.sketchType;
    this.name = "image";
    this.entityType = "image";

    this.setGraphicSequence()
    // this.svgUrl = this.fileUrl ? params.fileUrl : this.convertFileToUrl();
}
    async init(){
        let scope = this
        if (this.fileUrl){
            let file = await urlToFile(this.fileUrl)
            scope.file = file
        }
        let imageMesh = await scope.svgToModel()
        this.add(imageMesh)

        this.setPositionAsGraphicSequence()
        return this
    }

    setGraphicSequence(){
        if (this.isGraphicImg){
            this.sequenceFaceName = this.sequenceFaceName
            let face = this.threeViewer.scene.getObjectByProperty("sequenceFaceName",this.sequenceFaceName)
            face.totalGraphics = face.totalGraphics + 1
        }
    }
    setPositionAsGraphicSequence(){
        if (this.isGraphicImg){
            let face = this.threeViewer.scene.getObjectByProperty("sequenceFaceName",this.sequenceFaceName)
            let graphicSequence = face.totalGraphics
            this.position.set(0,0,graphicSequence/100)
        }
    }

    info (){
        let scope = this
        swapAllGraphicsToPreviousParent(scope.threeViewer)
        let infoObj =  {
            sequenceFaceName : scope.sequenceFaceName,
            position        : scope.position.clone(),
            rotation        : scope.rotation,
            scale           : scope.scale,
            file            : scope.file,
            entityType      : scope.name,
            fileUrl          : scope.fileUrl,
            sketchType      : scope.sketchType
        }
        swapAllGraphicsToScene(scope.threeViewer)
        return infoObj
    }
    async loadSvg(){
        let scope = this 
        // return new Promise((resolve, reject)=>{
            
            let imgSrc = scope.svgRawData
            const loader = new SVGLoader();
            let imageMesh = await customImageLoader(imgSrc)
            imageMesh.name = "imageMesh"
            imageMesh.layers.set(3)  //     mesh.layers.set(3) 
            imageMesh.isGraphicImg = scope.isGraphicImg
            return imageMesh

    }

    async loadSvgText(){
        let scope = this 
        return new Promise((resolve, reject)=>{
            
            let scgRawData = scope.svgRawData
            const loader = new SVGLoader();
            // load a SVG resource
            return loader.load(
                // resource URL
                scgRawData,
                // called when the resource is loaded
                function (data) {
                    const paths = data.paths;
                    const group = new THREE.Group();
                    group.scale.set(scope.size, scope.size, scope.size)
                    group.rotation.x = -Math.PI
                    //LOOP OVER PATH
                    for (let i = 0; i < paths.length; i++) {
    
                        const path = paths[i];
                        const material = new THREE.MeshBasicMaterial({
                            color: path.color,
                            side: THREE.DoubleSide,
                            // depthWrite: false                
                            polygonOffset: true,
                            polygonOffsetFactor: .01,
                            polygonOffsetUnits: -50,
                        });
    
                        const shapes = SVGLoader.createShapes(path);
    
                        for (let j = 0; j < shapes.length; j++) {
                            const shape = shapes[j];
                            const geometry = new THREE.ShapeGeometry(shape);
                            const mesh = new THREE.Mesh(geometry, material);
                            var positionAttribute = geometry.attributes.position;
                            mesh.name = "imageMesh"
                            mesh.layers.set(3) // Entity layer
                            mesh.isGraphicImg = scope.isGraphicImg
                            // ADD SHAPE TO GROUP
                            group.add(mesh);
                            group.name = "imageModelGroup"
                        }
    
                    }
                    resolve(group)
                    return group
                },
                // called when loading is in progresses
                function (xhr) {
    
                    console.log((xhr.loaded / xhr.total * 100) + '% loaded');
    
                },
                // called when loading has errors
                function (error) {
    
                    console.error('An error happened', error);
                    reject(error)
    
                }
            );
        })

    }

    async svgToModel(){
        let scope = this
        if (scope.file){
            // GET RAW DATA FROM GIVEN FILE
            scope.svgRawData = await getRawDataFromFile(scope.file)
        }
        if (scope.isGraphicImg){
            return await this.loadSvg()
        }else{
            return await this.loadSvgText()
        }

    }

}

export function swapAllGraphicsToScene(threeViewer){
    let imgs = threeViewer.scene.getObjectsByProperty("entityType","image")
    let embossTexts = threeViewer.scene.getObjectsByProperty("entityType","font")

    imgs.forEach(img => {
        if (img.isGraphicImg){
            img.previousParent = img.parent
            threeViewer.scene.attach(img)
        }
    });
    embossTexts.forEach(text => {
        text.previousParent = text.parent
        if (text.sketchType === "graphic"){
            threeViewer.scene.attach(text)
        }
    });
}
export function swapAllGraphicsToPreviousParent(threeViewer){
    let imgs = threeViewer.scene.getObjectsByProperty("entityType","image")
    let embossTexts = threeViewer.scene.getObjectsByProperty("entityType","font")
    imgs.forEach(img => {
        if (img.isGraphicImg){
            let faceObj = threeViewer.scene.getObjectByProperty("sequenceFaceName",img.sequenceFaceName)
            faceObj.threeFace.attach(img)
        }
    });
    embossTexts.forEach(text => {
        if (text.sketchType === "graphic"){
            let faceObj = threeViewer.scene.getObjectByProperty("sequenceFaceName",text.sequenceFaceName)
            faceObj.threeFace.attach(text)
        }
    });
}