import { cutOutTheShapes } from "../setupTHREE";
import { attachTransformControl } from "../utils/commonFunction";
import { checkTwoShapeIntersect } from "../utils/customUtils";
import { customAlertModalWithCancelForError } from "../utils/utils";
import { checkIntersection } from "./handleClipper";

export function checkExclusion(threeViewer){
    let cface = threeViewer.sketcher.currentFace.threeFace
    let exclusionOBjs = cface.getObjectsByProperty("sketchType","exclusion")
    let cutOutObjs = cface.getObjectsByProperty("sketchType","cutout")
    for (let i = 0; i < exclusionOBjs.length; i++) {
        let eObj = exclusionOBjs[i];
        let eObjPoints = getPointsFromDCEntity(eObj)
        for (let j = 0; j < cutOutObjs.length; j++) {
            let cObj = cutOutObjs[j];
            let cObjPoints = getPointsFromDCEntity(cObj)
            let check = checkIntersection([eObjPoints],[cObjPoints])
            // let check = checkTwoShapeIntersect(eObj,cObj)
            if (check.isIntersect) return {intersect:true,cutObj : cObj,exclusionObj : eObj}
        }
    }
}

export function checkAndShowExlusionError(threeViewer){
    let bool = checkExclusion(threeViewer)
    if (bool){
        customAlertModalWithCancelForError("Some objects are intersected with exclusion area, are you still want to proceed ?",cutOutTheShapes);
        attachTransformControl(threeViewer,bool.cutObj)
    }else{
        cutOutTheShapes({needGuide : true})
    }
}

export function getPointsFromDCEntity(dcEntity){
    let points = dcEntity.getThreeFaceData()
    let newP = points.points.map(myFunc)
    function myFunc(item){
        return {X:item.x,Y:item.y}
    }
    return newP
}