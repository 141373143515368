import initOpenCascade from "opencascade.js";
import { loadSTEPorIGES } from './library';
import { setupThreeJSViewport } from './setupTHREE';
import { logMessage, alterShowOriginalShape, triggerStepFileChangeEvent, urlToFile } from "./utils/customUtils";
import {  progressBar } from "./utils/commonFunction";
import { initiateDragAndDropStepFile } from "./threeCustom/elementEvents/dragAndDrop";
import { environmentVariable } from "./variables";
import Cookies from 'universal-cookie';
import { CreateSalesOrderForPDF, closeAddToCart } from "./views/addToCartHandlers";
import { customAlert, customAlertWithCancel, getExtension } from "./utils/utils";
import { CreateDrawnEntitiesUsingJson } from "./views/createDrawnEntitesUsingJson";
import { setCartData } from "./views/addToCartOnly";
const cookies = new Cookies();
export let stepData = []
// Init Open Cascade
let progressBars = new progressBar()
progressBars.setProgress(20)
showLoader()
// checkLogin()
progressBars.setProgress(40)
window.addEventListener("beforeunload", function(e){
  e.preventDefault();
}, false);
initOpenCascade().then(openCascade => {
  const threeViewer = setupThreeJSViewport(openCascade);
  window.ocGlobal = openCascade
  init()

  async function init() {

    const urlParams = new URLSearchParams(window.location.search);

    handleUrlParams(threeViewer)

    if (urlParams.get("model")) {
      (async () => {
        try {
          document.getElementById("inputStepLoader").style = "display:block"
          let modelUrl = urlParams.get("model")
          let downloadUrlFile = `${environmentVariable.REACT_APP_DatabaseServer}fileLoader/urlLoader?fileUrl=${modelUrl}`;
          setTimeout(await openModelUsingUrl(downloadUrlFile), 50);
        } catch (error) {
          console.error('Error reading file:', error);
        } finally {
          removeLoader()
        }
      })();
    }else{
      removeLoader()
    }

    // Allow users to upload STEP Files by either "File Selector" or "Drag and Drop".
    document.getElementById("step-file").addEventListener('change', async (event) => {
      let file = event.srcElement.files[0]
      if (!file) return   
      threeViewer.file = file
      await handleUploadedFile(threeViewer, openModel,openModelUsingUrl);

    });

    await showProgress()
    initiateDragAndDropStepFile({handleUploadedFile,threeViewer,openModel})
  }

  async function showProgress() {
    await progressBars.setProgress(80)
    await progressBars.setProgress(83)
    await progressBars.setProgress(86)
    await progressBars.setProgress(89)
    await progressBars.setProgress(91)
    await progressBars.setProgress(94)
    await progressBars.setProgress(99.99)
    setTimeout(() => {
      progressBars.setProgress(100)
      progressBars.resetProgress()
    }, 50);
  }

  async function openModel(file) {
    threeViewer.file = file
    if (environmentVariable.development) await processModel()
    else {
      try {
        await processModel()
      } catch (error) {
        logMessage(error,"error from openModel")
        customAlert("Something went wrong while processing the model")
      }
    }
    
    resetInputStepLoader()
    document.getElementById("File-tab").click()

    function resetInputStepLoader(){
      document.getElementById("inputStepLoader").style = "display:none"
    }
    
    async function processModel() {
      threeViewer.filename = file.name.replace(/\.[^/.]+$/, "")
      let extension = file.name.substring(file.name.lastIndexOf('.')+1, file.name.length) || file.name;
      extension = extension.toLowerCase();
      if (!["step","stp","igs","iges"].includes(extension)) {
        customAlert(`Please double-check the file type; it should be a step/stp file rather than a ${extension} file.`)
        resetInputStepLoader()
        return
      }
      let stepData = await loadSTEPorIGES(openCascade, file, threeViewer);
      if (stepData.proceed){
        let addToCartEle = document.getElementById("addToCart")
        let block1Box = document.getElementById("block1Box")
        block1Box.style = "min-height: calc(100vh - 234px);max-height: calc(100vh - 234px);"
        addToCartEle.classList.remove("displayNone")
      }
      let ocTools = {
        shapeTool : stepData.shapeTool,
        doc       : stepData.doc
      }

      threeViewer.ocTools = ocTools
      triggerStepFileChangeEvent();

      let frontViewEle = document.getElementById("frontView")
      if (frontViewEle) {
        frontViewEle.click()
      }

    }
  }

  async function openModelUsingUrl(url) {
    let stepFile = await urlToFile(url, "Assembly.step")
    if (stepFile) {
      await openModel(stepFile)
    } else {
      document.getElementById("inputStepLoader").style = "display:none"
      customAlert("URL is invalid for step-type file.")
    }
  }
});

async function handleUploadedFile(threeViewer, openModel,openModelUsingUrl) {
  let extension = getExtension(threeViewer.file.name);
  switch (extension.extension) {
    case "pdf":
    case "zip":
      askForEstimation(threeViewer.file);
      break;
    case "json":
      let jsonData = new CreateDrawnEntitiesUsingJson({
        file: threeViewer.file,
        threeViewer,
        openModelUsingUrl
      })
      await jsonData.init()
      break;
    case "stp":
    case "step":
    case "igs":
    case "iges":
    default:
      document.getElementById("inputStepLoader").style = "display:block";
      closeAddToCart();
      setTimeout(() => openModel(threeViewer.file), 50);
      break;
  }
}

function checkLogin(){
  showLoader()
  let user = cookies.get('user')
  if (!user){
  }
  if (user == "sG6fW8eR2tY1uI7oP9aS5dF3gH4jK8lQ1zX2cV7bN0mJ3kL6qA9wE5xR4vT6yU1iO0pZ2mC8nB7") {
    // Cookie exists, continue with the app
    // removeLoader();
  } else {
    window.location.href= environmentVariable.FRONT_END_URL + "login.html";
  }
}

async function showLoader() {
  const loaderContainer = document.createElement('div');
  loaderContainer.innerHTML = '<div class="loader-container"><div class="loader"></div></div>';
  document.body.appendChild(loaderContainer.firstChild);
}

function removeLoader() {
  const loaderContainer = document.querySelector('.loader-container');
  if (loaderContainer) {
    document.body.removeChild(loaderContainer);
  }
}

function handleUrlParams(threeViewer) {
  const urlParams = new URLSearchParams(window.location.search);
  let name = urlParams.get("name")
  threeViewer.user = {}
  if (name && name !== "Public%20User") {
    threeViewer.user["name"] = name
  } else {
    threeViewer.user["name"] = "Test 106"
  }
  setCartData({urlParams,threeViewer});
}

function askForEstimation (){
  customAlertWithCancel("Would you like to submit a request for an estimate regarding this file?")
}