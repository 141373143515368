import * as THREE from "three";
export function customImageLoader(imgSrc) {
    return new Promise((resolve, reject) => {
        // Create a canvas element
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');

        // Set the size of the canvas (adjust as needed)


        // Load the image onto the canvas
        var img = new Image();
        img.crossOrigin = "Anonymous"; // Enable cross-origin resource sharing if needed
        img.src = imgSrc;
        img.onload = function () {
            canvas.width = img.width;
            canvas.height = img.height;
            context.drawImage(img, 0,0, canvas.width, canvas.height);

            // Create a texture from the canvas
            var tex = new THREE.CanvasTexture(canvas);
            tex.needsUpdate = true;

            // Calculate the aspect ratio of the canvas
            var aspectRatio = canvas.width / canvas.height;

            // Set the width and height of the plane geometry
            var planeWidth = 20;
            var planeHeight = planeWidth / aspectRatio;

            // Create a plane geometry with the correct aspect ratio
            var planeGeom = new THREE.PlaneGeometry(planeWidth, planeHeight);
            var material = new THREE.MeshLambertMaterial(
                {
                    map: tex, 
                    transparent: true, 
                    side: THREE.DoubleSide,
                    polygonOffset: true,
                    polygonOffsetFactor: .01,
                    polygonOffsetUnits: -50, 
                }
            );
            // Create a mesh with the plane geometry and material
            var mesh = new THREE.Mesh(planeGeom, material);
            mesh.layers.set(3)    
            resolve(mesh);
        };

        img.onerror = function (error) {
            reject(error);
        };
    });
}

