import * as THREE from 'three';
import { addRefSphere, createLineSegment } from '../../utils/customUtils2';
import { generate2DCircleCornerPoints, generate2DCirclePoints, generate2DEllipseCornerPoints, generate2DEllipsePoints } from '../../utils/geometryUtils';
import { getWorldPointData, removeObjWithChildren ,generateGeometryBorder, cloneDeep, getShapeCenterPoint} from '../../utils/customUtils';
import { environmentVariable } from '../../variables';
// import lod from 'lodash'

import { DCEntityData } from './DCEntityCommon/DCEntityData';

class DCEntity extends THREE.Object3D {
    constructor(params) {
        super();
        this.customType = 'DCEntity';
        this.points = []
        this.refPoints = []
        this.isClosed = true;
        this.entityData = DCEntityData()
        this.colorEntity = 0xff0000
        this.sketchType = params.sketchType
        this.referenceCircleSize = params.referenceCircleSize ? params.referenceCircleSize : 1

        this.generateConstructionCircle()
        this.generateRefCircle()
    }
    generateConstructionCircle() {
        // Generate circle points
        const circleRadius = 1;

        const circlePoints = [];
        const circlePointsCount = 30;
        for (let i = 0; i <= circlePointsCount; i++) {
            const angle = (i / circlePointsCount) * Math.PI * 2;
            const x = Math.cos(angle) * circleRadius;
            const y = Math.sin(angle) * circleRadius;
            circlePoints.push(new THREE.Vector3(x, y, 0));
        }
        this.circleGeo = new THREE.BufferGeometry().setFromPoints(circlePoints);
        this.lineMat = new THREE.LineBasicMaterial({ color: 0xffff00 })
    }
    generateRefCircle() {
        this.refCircleGeo = new THREE.CircleGeometry(this.referenceCircleSize, 16);
        this.refCircleMat = new THREE.MeshBasicMaterial({
            color: 0xffff00, side: THREE.FrontSide,
            polygonOffset: true,
            polygonOffsetFactor: 0.1,
            polygonOffsetUnits: -200
        });
        this.refCircle = new THREE.Mesh(this.refCircleGeo, this.refCircleMat);
        this.refCircle.name = "refCircle"
        // this.refCircle.visible = false
    }
    addRefPoint(inPoint) {
        let refCircle = this.refCircle.clone()
        refCircle.position.copy(inPoint)
        refCircle.layers.set(4)
        this.add(refCircle)
        this.refPoints.push(refCircle)
    }
    removeRefPoints() {
        this.refPoints.forEach((point) => {
            removeObjWithChildren(point)
        })
        this.refPoints = []
    }

    finalize() {
        let scope = this
        if (!this.displacement){
            // Step 1: Calculate the displacement of the group's position
            let shapeCenter = getShapeCenterPoint(this.threeEntity)
            this.displacement = this.worldToLocal(shapeCenter)
            // Step 2: Update the position of the group
            this.position.copy(shapeCenter)
            // Step 3: Update the positions of the children based on the displacement
            for (const child of this.children) {
                child.position.sub(this.displacement);
            }
            scope.update()
            scope.centerPoint = new THREE.Vector3()
        }
        this.finalizePoints(); // based on threeEntityPoints it will clone point and add yellow sphere
        this.removeConstructionPoints()
    }

    addPoint(inPoint) {
        let sphere = new THREE.Line(this.circleGeo, this.lineMat)
        this.points.push(sphere);
        sphere.position.copy(inPoint)
        this.add(sphere);
        sphere.name = "DCEntityPoint"
        if (this.points.length == 1) {
            this.addPoint(inPoint)
        }
        return sphere
    }
    removeConstructionPoints() {
        this.points.forEach((point) => {
            this.remove(point)
        })
        this.points = []
    }
    updatePoint(inPosition, inIndex) {
        this.points[inIndex].position.copy(inPosition)
        this.update()
    }
    setParentFae(inFace) {
        this.parentFace = inFace
    }
    getParentFace() {
        return this.parentFace
    }
    generateEntity() {
        switch (this.sketchType) {
            case "cutout":
                this.makeCutOutEntity()
                break;
            case "graphic":
                this.colorEntity = "green"
                this.makeGraphicSketch()

                break;
            case "reference":
                this.colorEntity = "blue"
                this.makeReferenceSketch()

                break;
            case "masking":
                this.colorEntity = "blue"
                this.makeMaskingSketch()

                break;
            case "exclusion":
                this.colorEntity = "white"
                this.makeExclusionSketch()

                break;

            default:
                console.warn("Sketch type"+ this.sketchType + " is not valid")
                break;
        }
    }

    createFilledColorEntity(){
        if(this.entityType == "Polyline" && this.isClosed == false){
            this.threeEntity = createLineSegment(this.entityPoints,this.colorEntity)
            this.threeEntity.name = "threeEntity"
            this.threeEntity.layers.set(3) // Entity Layer
            this.add(this.threeEntity)
        }else {
            let shape = new THREE.Shape(this.entityPoints);
            shape.autoClose = true;
            let geometry = new THREE.ShapeGeometry(shape);
            let material = new THREE.MeshBasicMaterial({
                color: this.colorEntity,
                polygonOffset: true,
                polygonOffsetFactor: .1,
                polygonOffsetUnits: -50,
                transparent: true, opacity: 0.6
            });
            this.threeEntity = new THREE.Mesh(geometry, material);
            this.threeEntity.name = "threeEntity"
            this.threeEntity.layers.set(3) // Entity Layer
            let border = generateGeometryBorder(geometry)
            border.name = "DCEntityBorder"
            this.threeEntity.add(border)
            this.add(this.threeEntity);
        }
    }
    createFatBorderOnly(){
        this.threeEntity.name = "threeEntity"
        this.threeEntity.layers.set(3) // Entity Layer
        this.add(this.threeEntity)
    }

    makeCutOutEntity(){
        this.colorEntity = 0xff0000
        this.createFilledColorEntity()
    }
    makeMaskingSketch() {
        this.colorEntity = 0x5ede5e
        this.createFilledColorEntity()
    }

    makeGraphicSketch() {
        this.threeEntity = createLineSegment([this.entityPoints,this.entityPoints[0]].flat(),this.colorEntity)
        this.createFatBorderOnly()
    }
    makeReferenceSketch() {
        this.threeEntity = createLineSegment([this.entityPoints,this.entityPoints[0]].flat(),this.colorEntity,true)
        this.createFatBorderOnly()
    }
    makeExclusionSketch() {
        this.colorEntity = "white"
        this.createFilledColorEntity()
        this.getObjectByName("DCEntityBorder").visible = false
    }

    getPosition(){
        let originPoints = {
            x : this.position.x,
            y : this.position.y,
            z : this.position.z
        }
        return originPoints
    }
    getRotation(){
        let rotation = this.rotation.z
        return rotation
    }
    customClone() {
        let clonedEntity = this.cloneIndividual()
        clonedEntity.entityData = cloneDeep(this.entityData)
        clonedEntity.position.copy(this.position.clone())
        clonedEntity.rotation.copy(this.rotation.clone())
        return clonedEntity
    }
    createNewConstructor(){
        let clone = new this.constructor({sketchType : this.sketchType})
        return clone
    }
    getDCShapePoint(position){
        let DCShape = this.getDCShapeParent()
        if (DCShape){
            let localP = DCShape.worldToLocal(position)
            return localP
        }
    }
    getDCShapeParent(){
        let parent = this
        while (1) {
            if (parent.customType == "DCShape") return parent
            else parent = parent.parent
        }
    }
    getThreeFacePoint(position){
        let DCFace = this.getThreeFaceParent()
        if (DCFace){
            let localP = DCFace.worldToLocal(position)
            return localP
        }
    }
    getThreeFaceParent(){
        let parent = this
        while (1) {
            if (parent.name == "threeFace") return parent
            else parent = parent.parent
        }
    }
    getThreeFaceEntityPoints() {
        let scope = this;
        let points = [];
        this.entityPoints.forEach((point) => {
            let point3d = new THREE.Vector3(point.x, point.y, 0.1);
            let position = scope.localToWorld(point3d);
            let DCShapePnt = this.getThreeFacePoint(position);
            if (DCShapePnt) points.push(DCShapePnt);
        });
        return points;
    }

    getFace(){
        let parent = this.parent
        if (!parent){console.warn("parent not found",this);return}
        while(parent){
            if (parent.parent.customType === "DCFace"){
                this.sequenceFaceName = parent.parent.sequenceFaceName
                return parent.parent
            }else{
                parent = parent.parent
            }
        }
    }
    getSequenceFaceName(){
        this.getFace()
        return this.sequenceFaceName
    }


}

export class DCRect extends DCEntity {
    constructor(params) {
        super(params);
        this.entityType = 'Rect';
    }

    update() {
        if (this.points.length == 2) {
            this.generateRect();
        }
    }
    finalizePoints() {
        let refPoints = [...this.entityPoints]

        // Center point of the rect
        let centerPoint = new THREE.Vector2()
        centerPoint.x = (refPoints[0].x + refPoints[2].x) / 2
        centerPoint.y = (refPoints[0].y + refPoints[2].y) / 2
        refPoints.push(centerPoint)

        // Get all line center points and add to refPoints
        refPoints.push(getMidPoint(refPoints[0], refPoints[1]))
        refPoints.push(getMidPoint(refPoints[1], refPoints[2]))
        refPoints.push(getMidPoint(refPoints[2], refPoints[3]))
        refPoints.push(getMidPoint(refPoints[3], refPoints[0]))


        refPoints.forEach((point) => {
            let point3d = new THREE.Vector3(point.x, point.y, 0);
            this.addRefPoint(point3d);
        });
    }
    generateRect() {
        if (this.threeEntity) {
            this.remove(this.threeEntity);
        }
        this.entityPoints = this.create2DRectFromCornerPoints(this.points[0], this.points[1]);

        this.generateEntity()
    }
    create2DRectFromCornerPoints(inPoint1, inPoint2) {
        let points = [];
        points.push(new THREE.Vector2(inPoint1.position.x, inPoint1.position.y));
        points.push(new THREE.Vector2(inPoint2.position.x, inPoint1.position.y));
        points.push(new THREE.Vector2(inPoint2.position.x, inPoint2.position.y));
        points.push(new THREE.Vector2(inPoint1.position.x, inPoint2.position.y));

        return points;
    }
    getWidth() {
        return this.entityPoints[0].distanceTo(this.entityPoints[1])
    }
    getHeight() {
        return this.entityPoints[1].distanceTo(this.entityPoints[2])
    }

    getCuttingData() {
        /**
         * This function will return cutting data for cutting face
         * @returns {Array<THREE.Vector3>} points
         * We are adding z value as 0.1 for proper cutting
         */
        let scope = this;
        let points = [];
        scope.entityPoints.forEach((point) => {
            let point3d = new THREE.Vector3(point.x, point.y, 0.1);
            let position = scope.localToWorld(point3d);
            let DCShapePnt = this.getDCShapePoint(position)
            if (DCShapePnt) points.push(DCShapePnt);
        });
        let data = { 
            points,
            entityData : scope.entityData      
        };
        return data
    }
    info() {
        /**
         * This function will return information of entity
         * @returns {object} data
         */
        let scope = this;
        let sequenceFaceName =  this.getSequenceFaceName()
        let data = { 
            entityType      : this.entityType,
            sketchType      :this.sketchType,
            sequenceFaceName:sequenceFaceName, // It is parentFace, will used for clone
            entityPoints    : scope.entityPoints,
            entityData      : scope.entityData,
            position        : scope.position.clone(),
            cornerPoints    : [this.entityPoints[0], this.entityPoints[2]],
        };
        return data
    }
    getThreeFaceData(){
        let scope = this;
        let points = this.getThreeFaceEntityPoints();
        let data = { 
            points,
        };
        return data
    }


    updateWidth(inWidth) {
        /**
         * This function will update width of the rect - TODO - Need improvement
         * @param {Number} inWidth
         * @returns {void}
         */
        let scope = this;
        let width = scope.getWidth()

        let widthVec = this.entityPoints[1].clone().sub(this.entityPoints[0])
        widthVec.normalize()

        widthVec.multiplyScalar((inWidth - width) / 2)

        let negWidthVec = widthVec.clone().negate()

        this.removeRefPoints()
        if (this.threeEntity) {
            this.remove(this.threeEntity);
        }
        this.entityPoints[0].add(negWidthVec)
        this.entityPoints[1].add(widthVec)
        this.entityPoints[2].add(widthVec)
        this.entityPoints[3].add(negWidthVec)

        this.generateEntity()

        this.finalize()

    }
    updateHeight(inHeight) {
        /**
         * This function will update height of the rect - TODO - Need improvement
         * @param {Number} inHeight
         * @returns {void}
         */
        let scope = this;
        let height = scope.getHeight()

        let heightVec = this.entityPoints[1].clone().sub(this.entityPoints[2])
        heightVec.normalize()
        
        heightVec.multiplyScalar((inHeight - height) / 2)

        let negHeightVec = heightVec.clone().negate()

        this.removeRefPoints()
        if (this.threeEntity) {
            this.remove(this.threeEntity);
        }
        this.entityPoints[0].add(heightVec)
        this.entityPoints[1].add(heightVec)
        this.entityPoints[2].add(negHeightVec)
        this.entityPoints[3].add(negHeightVec)

        this.generateEntity()

        this.finalize()
    }
    cloneIndividual() {
        let clone = this.createNewConstructor()
        // Give top left and bottom right points
        clone.generateFrom2CornerPoints(this.entityPoints[0], this.entityPoints[2])
        clone.setParentFae(this.getParentFace())
        return clone;
    }

    generateFrom2CornerPoints(inPoint1, inPoint2) {
        this.points = []
        this.addPoint(inPoint1)
        
        this.updatePoint(inPoint2, this.points.length - 1)

        this.generateRect()

        this.finalize()
    }
    addTranslation(inTranslation) {  // Do not use this function
        /**
         * This function will add translation to the rect
         * @param {THREE.Vector3} inTranslation
         * @returns {void}
         * TODO: Need to improve
         * 
         */
        let scope = this;
        let translation = inTranslation.clone()
        this.removeRefPoints()
        if (this.threeEntity) {
            this.remove(this.threeEntity);
        }
        this.entityPoints.forEach((point) => {
            point.add(translation)
        })

        this.generateEntity()

        this.finalize()
    }
}

export class DCCircle extends DCEntity {
    constructor(params) {
        super(params);
        this.entityType = 'Circle';
    }

    updateDiameter(inDiameter) {
        /**
         * This function will update diameter of the circle
         * @param {Number} inDiameter
         * @returns {void}
         * TODO: Need to improve
         */
        let scope = this;

        let center = scope.centerPoint.clone()
        this.entityPoints = this.create2DCircleFromCenterAndRadius(center, inDiameter / 2);

        this.removeRefPoints()
        if (this.threeEntity) {
            this.remove(this.threeEntity);
        }

        this.generateEntity()

        this.finalize()

    }
    update() {
        if (this.points.length == 2) {
            this.generateCircle();
        }
    }
    finalizePoints() {
        let refPoints = []

        refPoints.push(this.centerPoint)

        let cornerPoints = generate2DCircleCornerPoints(this.centerPoint, this.radius)

        refPoints.push(...cornerPoints)

        refPoints.forEach((point) => {
            let point3d = new THREE.Vector3(point.x, point.y, 0);
            this.addRefPoint(point3d);
        });
    }
    generateCircle() {
        if (this.threeEntity) {
            this.remove(this.threeEntity);
        }
        this.centerPoint = this.points[0].position
        this.entityPoints = this.create2DCircleFrom2Points(this.points[0], this.points[1]);

        this.generateEntity()

    }
    create2DCircleFrom2Points(inCenter, inPoint) {
        this.radius = inCenter.position.distanceTo(inPoint.position);
        return generate2DCirclePoints(inCenter.position, this.radius, 30)
    }

    create2DCircleFromCenterAndRadius(inCenter, inRadius) {
        this.radius = inRadius;
        return generate2DCirclePoints(inCenter, this.radius, 30)
    }

    getCuttingData() { // TODO: Need to do according to circle
        /**
         * This function will return cutting data for cutting face
         * @returns {Array<THREE.Vector3>} points
         * We are adding z value as 0.1 for proper cutting
         */
        let scope = this;
        let point3d = new THREE.Vector3(scope.centerPoint.x, scope.centerPoint.y, 0.1);
        let position = scope.localToWorld(point3d)
        let DCShapePnt = this.getDCShapePoint(position)
        if (DCShapePnt) position = DCShapePnt
        let data = {
            center: position,
            radius: scope.radius,
            entityData: scope.entityData
        }
        return data
    }
    getThreeFaceData() { 
        let scope = this;
        let point3d = new THREE.Vector3(scope.centerPoint.x, scope.centerPoint.y, 0.1);
        let position = scope.localToWorld(point3d)
        let DCShapePnt = this.getThreeFacePoint(position)
        if (DCShapePnt) position = DCShapePnt
        let points = this.getThreeFaceEntityPoints();
        let data = {
            center: position,
            radius: scope.radius,
            points: points
        }
        return data
    }
    getDiameter() {
        /**
         * This function will return diameter of the circle
         * @returns {Number} diameter
         */
        return this.radius * 2
    }
    cloneIndividual() {
        let clone = this.createNewConstructor()
        clone.generateFromCenterAndRadius(this.centerPoint, this.radius)
        clone.setParentFae(this.getParentFace())
        return clone;
    }

    generateFromCenterAndRadius(inCenter, inRadius) {
        this.points = []
        this.centerPoint = inCenter
        this.radius = inRadius

        this.entityPoints = this.create2DCircleFromCenterAndRadius(this.centerPoint, this.radius);

        this.generate()
    }

    updateCenter(inCenter) {
        /**
         * This function will update center of the circle
         * @param {THREE.Vector3} inCenter
         * @returns {void}
         * TODO: Need to improve
         */
        let scope = this;
        
        this.centerPoint = inCenter.clone()
        
        this.generate()
    }
    updateDiameter(inDiameter) {
        /**
         * This function will update diameter of the circle
         * @param {Number} inDiameter
         * @returns {void}
         * TODO: Need to improve
         */
        let scope = this;

        this.radius = inDiameter / 2

        this.generate()
    }


 generate() {
        let scope = this;
        scope.entityPoints = this.create2DCircleFromCenterAndRadius(scope.centerPoint, scope.radius);

        scope.removeRefPoints()
        if (scope.threeEntity) {
            scope.remove(scope.threeEntity);
        }
        scope.generateEntity()
        scope.finalize()
    }

    info() {
        /**
         * This function will return information of entity
         * @returns {object} data
         */
        let scope = this;
        let sequenceFaceName =  this.getSequenceFaceName()
        let data = { 
            entityType      : this.entityType,
            sketchType      : this.sketchType,
            sequenceFaceName: sequenceFaceName, // It is parentFace, will used for clone
            entityPoints    : scope.entityPoints,
            radius          : scope.radius,
            position        : scope.position.clone(),
            centerPoint     : scope.position.clone()
        };
        return data
    }

}

export class DCEllipse extends DCEntity {
    constructor(params) {
        super(params);
        this.entityType = 'Ellipse';
    }

    update() {
        if (this.points.length == 2) {
            this.generateEllipse();
        }
    }
    finalizePoints() {
        let refPoints = []

        refPoints.push(this.centerPoint)

        let cornerPoints = generate2DEllipseCornerPoints(this.centerPoint, this.xRadius, this.yRadius)

        refPoints.push(...cornerPoints)

        refPoints.forEach((point) => {
            let point3d = new THREE.Vector3(point.x, point.y, 0);
            this.addRefPoint(point3d);
        });
    }
    generateEllipse() {
        if (this.threeEntity) {
            this.remove(this.threeEntity);
        }
        this.centerPoint = this.points[0].position
        this.entityPoints = this.createEllipseFrom2Points(this.points[0], this.points[1]);

        this.generateEntity()

    }
    createEllipseFrom2Points(inCenter, inPoint) {
        this.xRadius = Math.abs(inCenter.position.x - inPoint.position.x)
        this.yRadius = Math.abs(inCenter.position.y - inPoint.position.y)


        return generate2DEllipsePoints(inCenter.position, this.xRadius, this.yRadius, 30)
    }

    getCuttingData() { // TODO: Need to do according to ellipse
        /**
         * This function will return cutting data for cutting face
         * @returns {Array<THREE.Vector3>} points
         * We are adding z value as 0.1 for proper cutting
         */
        let scope = this;

        let point3d = new THREE.Vector3(scope.centerPoint.x, scope.centerPoint.y, 0.1);
        let position = scope.localToWorld(point3d)
        let DCShapePnt = this.getDCShapePoint(position)
        if (DCShapePnt) position = DCShapePnt

        let rotation = this.rotation.z
        let data = {
            center: position,
            xRadius: scope.xRadius,
            yRadius: scope.yRadius,
            entityData: scope.entityData,
            rotation,
        }
        return data;
    }
    getThreeFaceData() { // TODO: Need to do according to ellipse
        /**
         * This function will return cutting data for cutting face
         * @returns {Array<THREE.Vector3>} points
         * We are adding z value as 0.1 for proper cutting
         */
        let scope = this;

        let point3d = new THREE.Vector3(scope.centerPoint.x, scope.centerPoint.y, 0.1);
        let position = scope.localToWorld(point3d)
        let DCShapePnt = this.getThreeFacePoint(position)
        if (DCShapePnt) position = DCShapePnt

        let rotation = this.rotation.z
        let points = this.getThreeFaceEntityPoints();
        let data = {
            center: position,
            xRadius: scope.xRadius,
            yRadius: scope.yRadius,
            points: points,
            rotation,
        }
        return data;
    }

    getXRadius() {
        /**
         * This function will return x radius of the ellipse
         * @returns {Number} xRadius
         */
        return this.xRadius
    }
    getYRadius() {
        /**
         * This function will return y radius of the ellipse
         * @returns {Number} yRadius
         */
        return this.yRadius
    }

    cloneIndividual() {
        let clone = this.createNewConstructor()
        clone.generateFromCenterAndRadius(this.centerPoint, this.xRadius, this.yRadius)
        clone.setParentFae(this.getParentFace())
        return clone;
    }

    generateFromCenterAndRadius(inCenter, inXRadius, inYRadius) {
        this.points = []
        this.centerPoint = inCenter
        this.xRadius = inXRadius
        this.yRadius = inYRadius

        this.entityPoints = this.create2DEllipseFromCenterAndRadius(this.centerPoint, this.xRadius, this.yRadius);

        this.generateEntity()

        this.finalize()
    }

    create2DEllipseFromCenterAndRadius(inCenter, inXRadius, inYRadius) {
        this.xRadius = inXRadius;
        this.yRadius = inYRadius;
        return generate2DEllipsePoints(inCenter, this.xRadius, this.yRadius, 30)
    }

updateCenter(inCenter) {
        /**
         * This function will update center of the ellipse
         * @param {THREE.Vector3}
         * @returns {void}
         * TODO: Need to improve
         */
        let scope = this;
        let center = inCenter.clone()
        scope.centerPoint = center

        this.generate()
    }

    updateXRadius(inXRadius) {
        /**
         * This function will update x radius of the ellipse
         * @param {Number} inXRadius
         * @returns {void}
         */
        let scope = this;

        scope.xRadius = inXRadius

        this.generate()
    }

    updateYRadius(inYRadius) {
        /**
         * This function will update y radius of the ellipse
         * @param {Number} inYRadius
         * @returns {void}
         */
        let scope = this;

        scope.yRadius = inYRadius

        this.generate()

    }

    generate() {
        let scope = this
        this.entityPoints = this.create2DEllipseFromCenterAndRadius(scope.centerPoint, scope.xRadius, scope.yRadius);

        this.removeRefPoints()
        if (this.threeEntity) {
            this.remove(this.threeEntity);
        }

        this.generateEntity()

        this.finalize()
    }

    info() {
        /**
         * This function will return information of entity
         * @returns {object} data
         */
        let scope = this;
        let sequenceFaceName =  this.getSequenceFaceName()
        let data = { 
            entityType      : this.entityType,
            sketchType      :this.sketchType,
            sequenceFaceName:sequenceFaceName, // It is parentFace, will used for clone
            entityPoints    : scope.entityPoints,
            xRadius         : scope.xRadius,
            yRadius         : scope.yRadius,
            position        : scope.position.clone(),
            centerPoint     : scope.position.clone()
        };
        return data
    }
    
}

export class DCPolyline extends DCEntity {
    constructor(params) {
        super(params);
        this.entityType = 'Polyline';
        this.isClosed = false;
    }

    update() {

        if (this.points.length > 1) {
            this.generatePolyLine();
        }
    }
    finalizePoints() {
        let refPoints = []

        refPoints.push(...this.entityPoints)

        // let cornerPoints = generate2DEllipseCornerPoints(this.centerPoint, this.xRadius, this.yRadius)

        // refPoints.push(...cornerPoints)

        refPoints.forEach((point) => {
            let point3d = new THREE.Vector3(point.x, point.y, 0);
            this.addRefPoint(point3d);
        });
    }
    generatePolyLine() {
        if (this.threeEntity) {
            this.remove(this.threeEntity);
        }
        this.entityPoints =this.points.map((point) => {
            return point.position
        })

        if(this.isClosed) {
            this.entityPoints.pop()
        }

        this.generateEntity()
    }
    getStartPoint() {
        return this.points[0]
    }
    getCuttingData() { // TODO: Need to do according to Polyline
                /**
         * This function will return cutting data for cutting face
         * @returns {Array<THREE.Vector3>} points
         * We are adding z value as 0.1 for proper cutting
         */
        let scope = this;
        let points = [];
        scope.entityPoints.forEach((point) => {
            let point3d = new THREE.Vector3(point.x, point.y, 0.1);
            let position = scope.localToWorld(point3d);
            let DCShapePnt = this.getDCShapePoint(position)
            if (DCShapePnt) points.push(DCShapePnt);
        });
        let data = {
            points,
            entityData: scope.entityData
        }
        return data;
    }
    getThreeFaceData() { // TODO: Need to do according to Polyline
                /**
         * This function will return cutting data for cutting face
         * @returns {Array<THREE.Vector3>} points
         * We are adding z value as 0.1 for proper cutting
         */
        let points= this.getThreeFaceEntityPoints();
        let data = {
            points,
        }
        return data;
    }
    
    cloneIndividual() {
        let clone = this.createNewConstructor()
        clone.isClosed = this.isClosed
        clone.generateFromPoints(this.entityPoints)
        clone.setParentFae(this.getParentFace())
        return clone;
    }

    generateFromPoints(inPoints) {
        this.points = []
        this.entityPoints = inPoints.map((point) => {
            return point
        })

        this.generateEntity()

        this.finalize()
    }

    info() {
        /**
         * This function will return information of entity
         * @returns {object} data
         */
        let scope = this;
        let sequenceFaceName =  this.getSequenceFaceName()
        let data = { 
            entityType      : this.entityType,
            sketchType      :this.sketchType,
            sequenceFaceName:sequenceFaceName, // It is parentFace, will used for clone
            entityPoints    : scope.entityPoints,
            position        : scope.position.clone(),
            referenceCircleSize : scope.referenceCircleSize,
        };

        return data
    }

}
export class DCPolylineWithArc extends DCPolyline {
    constructor(params) {
        super(params);
        this.entityType = 'DCPolylineWithArc';
        this.isClosed = false;
    }
    finalizePoints() {
        let refPoints = [...this.entityPoints]
        refPoints.forEach((point) => {
            let point3d = new THREE.Vector3(point.x, point.y, 0);
            this.addRefPoint(point3d);
        });
    }
    generateFromPoints(inPointsArray) {
        this.points = []
        this.cutoutRefPoints = []
        this.entityPoints = []
        this.inPointsArray = inPointsArray
        inPointsArray.forEach(points => {
            if (points[0]){
                this.cutoutRefPoints.push(points[0])
                points.forEach((p)=>{
                    this.entityPoints.push(p)
                    this.addPoint(p)
                })
            }
            else {
                this.cutoutRefPoints.push(points)
                this.entityPoints.push(points)
                this.addPoint(points)
            }
        });
        this.addPoint(inPointsArray[0])
        // Delete 2nd points from array which is duplicated of first point from first time addPoint function
        this.points.splice(1,1)
        this.generateEntity()

        this.finalize()
    }
    cloneIndividual() {
        let clone = this.createNewConstructor()
        clone.isClosed = this.isClosed
        clone.generateFromPoints(this.inPointsArray)
        clone.setParentFae(this.getParentFace())
        return clone;    }
}

export class GenerateDCRect extends DCRect{
    constructor(params) {
        let po1 = params.po1
        let po2 = params.po2
        let sequenceFaceName = params.sequenceFaceName
        let position = params.position
        super(params);
        // Give top left and bottom right points
        this.generateFrom2CornerPoints(po1, po2)
        this.position.set(position.x, position.y, position.z)
        let parentFaceObj = window.threeViewer.scene.getObjectByProperty('sequenceFaceName', sequenceFaceName)
        this.setParentFae(parentFaceObj)
    }
}
export class GenerateDCCircle extends DCCircle{
    constructor(params) {
        let centerPoint = params.centerPoint
        let radius = params.radius
        let sequenceFaceName = params.sequenceFaceName
        let position = params.position
        super(params);

        // Generate DCCircle 
        this.generateFromCenterAndRadius(centerPoint,radius)
        this.position.set(position.x, position.y, position.z)
        let parentFaceObj = window.threeViewer.scene.getObjectByProperty('sequenceFaceName', sequenceFaceName)
        this.setParentFae(parentFaceObj)
    }
}
export class GenerateDCEllipse extends DCEllipse{
    constructor(params) {
        super(params);

        let centerPoint     = params.centerPoint
        let radius          = params.radius
        let sequenceFaceName= params.sequenceFaceName
        let position        = params.position

        // Generate DCCircle 
        this.generateFromCenterAndRadius(centerPoint,params.xRadius,params.yRadius)

        // Set position
        this.position.set(position.x, position.y, position.z)

        // Set parent face to the object
        let parentFaceObj = window.threeViewer.scene.getObjectByProperty('sequenceFaceName', sequenceFaceName)
        this.setParentFae(parentFaceObj)
    }
}
export class GenerateDCPolyline extends DCPolyline{
    constructor(params) {
        super(params);

        let sequenceFaceName= params.sequenceFaceName
        let position        = params.position
        let entityPoints    = params.entityPoints

        // Generate DCCircle 
        this.isClosed = true
        this.generateFromPoints(entityPoints)
        // this.generateFromCenterAndRadius(centerPoint,params.xRadius,params.yRadius)

        // Set position
        this.position.set(position.x, position.y, position.z)

        // Set parent face to the object
        let parentFaceObj = window.threeViewer.scene.getObjectByProperty('sequenceFaceName', sequenceFaceName)
        if (!parentFaceObj) {
            console.warn('Parent face not found')
        }
        this.setParentFae(parentFaceObj)


    }
}




function getMidPoint(inPo1, inPo2) {
    return new THREE.Vector2((inPo1.x + inPo2.x) / 2, (inPo1.y + inPo2.y) / 2)
}



