import { makeCustomArcEntity } from "../../../openCascadeShapes"
import { attachTransformControl } from "../../../utils/commonFunction"
import { calculateCircleCenter, distanceBetweenTwoPoints, getAllVertices, getMidPoint } from "../../../utils/customUtils"
import { DCCircle, DCEllipse, DCPolylineWithArc } from "../../cuttingEntities/DCEntity"
import * as THREE from "three";
export class CutoutShape extends THREE.Object3D {
    constructor(params) {
        super();
        let inViewer = params.threeViewer
        let jsonData = params.customJsonData
        let oc = params.openCascade
        let sequenceFaceName = params.sequenceFaceName

        this.entityType = 'cutOutLibrary'
        this.libraryName = params.name

        this.threeViewer = inViewer
        this.jsonData = jsonData
        this.oc = oc
        this.sequenceFaceName = sequenceFaceName || this.threeViewer.sketcher.currentFace.sequenceFaceName
        this.sequenceFaceObj = this.threeViewer.scene.getObjectByProperty('sequenceFaceName', this.sequenceFaceName)

    }

    info() {
        /**
         * This function will return information of entity
         * @returns {object} data
         */
        let scope = this;
        let sequenceFaceName =  this.sequenceFaceName
        let data = { 
            entityType      : this.entityType,
            sequenceFaceName:sequenceFaceName, // It is parentFace, will used for clone
            position        : scope.position.clone(),
            rotation        : scope.rotation,
            customJsonData  : scope.jsonData,
            name            : scope.libraryName
        };
        return data
    }

    setParentFae(inFace) {
        this.parentFace = inFace
    }
    getParentFace() {
        return this.parentFace
    }

    init() {
        let scope =this
        let parentObj = this
        // let parentObj = new THREE.Group()
        parentObj.name = "cutOutLibrary"

        scope.cutOutShapeGroup = parentObj
        this.jsonData.forEach(shape => {

            let obj
            switch (shape.type) {
                case "circle":
                    obj = this.makeCircle(shape)
                    obj.setParentFae(this.sequenceFaceObj)
                    break;

                case "ellipse":
                    obj = this.makeEllipse(shape)
                    obj.setParentFae(this.sequenceFaceObj)
                    break;

                case "polyShape":
                    obj = this.makePolyShape(shape)
                    break;

                default:
                    break;
            }
            if (obj) {
                parentObj.add(obj)
            }
            
        });

        if (parentObj.children.length){
            parentObj["entityData"] = parentObj.children[0].entityData

            // Add it to threeJS
            this.sequenceFaceObj.threeFace.add(parentObj)
        } 
        return parentObj
    }


    makeCircle(shape) {
        let obj = new DCCircle({sketchType : "cutout"})
        obj.generateFromCenterAndRadius(shape.center, shape.radius)
        return obj
    }

    makePolyShape(shape) {
        let scope = this
        let polyLines = shape.points
        // let parentObj = new THREE.Group()
        // parentObj.length = polyLines.length
        // parentObj.name = "jsonPolyShape"
        let verticesArray = []
        for (let i = 0; i < polyLines.length; i++) {
            let p1 = polyLines[i].start
            let p2 = polyLines[i].end

            if (p1 && p2) {

                if (polyLines[i].type === "line") {
                    let vectorP1 = new THREE.Vector3(p1.x, p1.y, 0.2)
                    verticesArray.push(vectorP1)
                }
                
                else if (polyLines[i].type === "arch") {

                    let d = distanceBetweenTwoPoints(p1, p2)
                    let minimumRadius = d / 2
                    let vectorP1 = new THREE.Vector3(p1.x, p1.y, 0.2)
                    let vectorP2 = new THREE.Vector3(p2.x, p2.y, 0.2)

                    let jsonRadius = polyLines[i].radius
                    // if (jsonRadius < minimumRadius) jsonRadius = minimumRadius

                    let centerLocal = calculateCircleCenter(vectorP1.clone(), vectorP2.clone(), jsonRadius)
                    
                    // Get points of arch using open cascade
                    let points = makeCustomArcEntity(p1, p2, jsonRadius, "upWord", centerLocal, this.oc)

                    points.points.shift();

                    verticesArray.push(points.points.reverse())
                }

            }
        }

        let polyShape = this.dcPolyShape(verticesArray)
        return polyShape

    }
    attachTransformControlToObj(){
        attachTransformControl(this.threeViewer,this.cutOutShapeGroup)
    }
    dcPolyShape(verticesArray) {
        let polyShape = new DCPolylineWithArc({sketchType : "cutout"});
        polyShape.isClosed = true
        polyShape.generateFromPoints(verticesArray)
        polyShape.setParentFae(this.sequenceFaceObj)
        return polyShape
    }

    makeEllipse(jsonData) {
        // This function make DC ellipse and return it
        let ellipse = new DCEllipse({sketchType : "cutout"})
        ellipse.generateFromCenterAndRadius(jsonData.center, jsonData.radiusX, jsonData.radiusY)
        return ellipse
    }
}